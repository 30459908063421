import { gql } from '@apollo/client';

const AddItemsToBoardMutation = gql`
	mutation AddItemsToBoardMutation($input: AddItemsToBoardInput!) {
		addItemsToBoard(input: $input) {
			auth {
				user {
					_id
					itemsCount
				}
				settings {
					_id
					needsUpgradeReason
				}
			}
			board {
				_id
				thumbnails {
					image {
						thumbnail
					}
				}
				itemsCount
				lastItemAddedOrder
			}
			assets {
				_id
				isSaved
				savedCount
				ownBoards {
					_id
				}
			}
		}
	}
`;

export default AddItemsToBoardMutation;
