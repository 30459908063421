import type SVLazyImage from '@pkgs/shared-client/components/SVLazyImage';
import { unit } from '@pkgs/shared-client/styles/mixins';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

// TODO: Finish this later

type Props = React.PropsWithChildren<
	{
		Component?: typeof SVLazyImage | 'img';
		placeholder?: JSX.Element;
		src: string;
		color?: string;
		className?: string;
		cover?: boolean;
		rounded?: boolean;
		isLoading?: boolean;
	} & (
		| {
				ratio: number;
		  }
		| {
				width: number;
				height: number;
		  }
		| EmptyObject
	)
>;

const _InnerImage = ({
	Component = 'img',
	isLoading,
	src,
	color,
	cover,
	rounded,
	...props
}: Pick<Props, 'isLoading' | 'src' | 'color' | 'cover' | 'Component' | 'children' | 'rounded'>) => {
	if (isLoading || !src) {
		return (
			<div
				className={clsx(
					'absolute inset-0 h-full w-full',
					isLoading ? 'animate-shimer' : 'bg-image-empty',
					rounded && 'rounded-[4px]',
				)}
			/>
		);
	}

	return (
		<Component
			className={clsx(
				'absolute inset-0 h-full w-full bg-image-loading',
				cover && 'object-cover',
			)}
			style={color ? { background: color } : {}}
			src={src}
			decoding="async"
			{...props}
		/>
	);
};

const SVImage = React.memo(
	({ placeholder, src, color, Component, className, cover, isLoading, rounded, ...props }: Props) => {
		if (isLoading && placeholder) {
			return placeholder;
		}

		return (
			<div
				className={twMerge('relative w-full', className)}
				style={
					'ratio' in props && props.ratio > 0
						? {
								paddingBottom: unit(props.ratio * 100, '%'),
						  }
						: 'width' in props
						? {
								paddingBottom: unit((props.height / props.width) * 100, '%'),
								maxWidth: unit(props.width),
								maxHeight: unit(props.height),
						  }
						: undefined
				}
			>
				<_InnerImage src={src} color={color} Component={Component} cover={cover} rounded={rounded}>
					{props.children}
				</_InnerImage>
			</div>
		);
	},
);

export default SVImage;
