import {
	type Board,
	type Item,
	type ItemFragmentFragment,
} from '@apps/www/src/__generated__/graphql';
import SVModal from '@pkgs/shared-client/components/SVModal';
import { changeItems } from '@pkgs/shared-client/helpers/array';
import {
	ALLOWED_IMAGE_EXTENSIONS,
	ALLOWED_VIDEO_EXTENSIONS,
	ASSET_IMAGE_SIZES,
} from '@pkgs/shared/constants';
import { type Dispatch } from 'redux';
import { gridUpload } from './actionTypes';
import { closeOverlays } from './ui';

export const UPLOAD_QUEUED = 0;
export const UPLOAD_UPLOADING = 1;
export const UPLOAD_DONE = 3;
export const UPLOAD_ERROR = 4;

let sharedUploadID = 0;

export type UploadingItem = ItemFragmentFragment & {
	upload: {
		status:
			| typeof UPLOAD_QUEUED
			| typeof UPLOAD_UPLOADING
			| typeof UPLOAD_DONE
			| typeof UPLOAD_ERROR;
		progress: number;
		error: Error | null;
		file: File & { _done?: boolean };
		date: Date;
		boardID?: Board['_id'];
	};
};

type State = {
	items: UploadingItem[];
	isDropping: boolean;
};

const getInitialState = (): State => {
	return {
		items: [],
		isDropping: false,
	};
};

type Action =
	| ReturnType<typeof addItems>
	| ReturnType<typeof updateItemThumbnail>
	| ReturnType<typeof removeItem>
	| ReturnType<typeof toggleIsDropping>
	| ReturnType<typeof clearItems>
	| ReturnType<typeof itemUploadStart>
	| ReturnType<typeof itemUploadProgress>
	| ReturnType<typeof itemUploadComplete>
	| ReturnType<typeof _itemUploadError>;

export default function gridUploadReducer(state: State = getInitialState(), action: Action): State {
	switch (action.type) {
		case gridUpload.UPDATE_ITEM_THUMBNAIL:
			return {
				...state,
				items: changeItems(
					state.items,
					(item) => item._id == action.payload._id,
					(item) => {
						// Do not update thumbnail if upload is finished
						if (
							item.upload.status == UPLOAD_DONE ||
							item.upload.status == UPLOAD_ERROR
						) {
							return item;
						}

						return {
							...item,
							asset: {
								...item.asset,
								image: {
									...item.asset.image,
									...action.payload.imageInfo,
								},
							},
						};
					},
				),
			};
		case gridUpload.ITEM_UPLOAD_START:
			return {
				...state,
				items: changeItems(
					state.items,
					(item) => item._id == action.payload._id,
					(item) => {
						return {
							...item,
							upload: {
								...item.upload,
								status: UPLOAD_UPLOADING,
							},
						};
					},
				),
			};
		case gridUpload.ITEM_UPLOAD_PROGRESS:
			return {
				...state,
				items: changeItems(
					state.items,
					(item) => item._id == action.payload._id,
					(item) => {
						return {
							...item,
							upload: {
								...item.upload,
								progress: action.payload.progress,
							},
						};
					},
				),
			};
		case gridUpload.ITEM_UPLOAD_COMPLETE:
			return {
				...state,
				items: changeItems(
					state.items,
					(item) => item._id == action.payload._id,
					(item) => {
						(item.upload.file as any)._done = true;

						return {
							...item,
							upload: {
								...item.upload,
								status: UPLOAD_DONE,
							},
						};
					},
				),
			};
		case gridUpload.ITEM_UPLOAD_ERROR:
			return {
				...state,
				items: changeItems(
					state.items,
					(item) => item._id == action.payload._id,
					(item) => {
						item.upload.file._done = true;

						return {
							...item,
							upload: {
								...item.upload,
								status: UPLOAD_ERROR,
								error: action.payload.error,
							},
						};
					},
				),
			};
		case gridUpload.CLEAR_ITEMS:
			// Only keep error ones until they're cleared out
			state.items.forEach((item) => {
				if (item.upload.status != UPLOAD_ERROR) {
					item.upload.status = UPLOAD_DONE;
				}

				item.upload.file._done = true;
			});

			const newItems = state.items.filter((item) => item.upload.status == UPLOAD_ERROR);

			if (state.items.length === newItems.length) {
				return state;
			}

			return {
				...state,
				items: newItems,
			};
		case gridUpload.REMOVE_ITEM:
			return {
				...state,
				items: state.items.filter((item) => item._id != action.payload._id),
			};
		case gridUpload.TOGGLE_IS_DROPPING:
			return {
				...state,
				isDropping: action.payload.isDropping,
			};
		case gridUpload.ADD_ITEMS:
			return {
				...state,
				items: action.payload.items.concat(state.items),
			};
		default:
			return state;
	}
}

function addItems(items: UploadingItem[]) {
	return {
		type: gridUpload.ADD_ITEMS,
		payload: {
			items,
		},
	};
}

function updateItemThumbnail(
	itemID: Item['_id'],
	imageInfo: {
		thumbnail: string;
		width: number;
		height: number;
		ratio: number;
	},
) {
	return {
		type: gridUpload.UPDATE_ITEM_THUMBNAIL,
		payload: {
			_id: itemID,
			imageInfo,
		},
	};
}

function removeItem(item: UploadingItem) {
	return {
		type: gridUpload.REMOVE_ITEM,
		payload: {
			_id: item._id,
		},
	};
}

export function toggleIsDropping(isDropping: boolean) {
	return {
		type: gridUpload.TOGGLE_IS_DROPPING,
		payload: {
			isDropping,
		},
	};
}

export function clearItems() {
	return {
		type: gridUpload.CLEAR_ITEMS,
	};
}

export function itemUploadStart(item: UploadingItem) {
	return {
		type: gridUpload.ITEM_UPLOAD_START,
		payload: {
			_id: item._id,
		},
	};
}

export function itemUploadProgress(item: UploadingItem, progress: number) {
	return {
		type: gridUpload.ITEM_UPLOAD_PROGRESS,
		payload: {
			_id: item._id,
			progress,
		},
	};
}

export function itemUploadComplete(item: UploadingItem) {
	return {
		type: gridUpload.ITEM_UPLOAD_COMPLETE,
		payload: {
			_id: item._id,
		},
	};
}

function _itemUploadError(item: UploadingItem, error: Error) {
	return {
		type: gridUpload.ITEM_UPLOAD_ERROR,
		payload: {
			_id: item._id,
			error,
		},
	};
}

export function itemUploadError(item: UploadingItem, error: Error) {
	return (dispatch: Dispatch<Action>) => {
		dispatch(_itemUploadError(item, error));

		setTimeout(() => {
			dispatch(removeItem(item));
		}, 2000);
	};
}

export function addFiles(files: File[], boardID: Board['_id'] | null = null) {
	return async (dispatch: Dispatch<Action>) => {
		let date = new Date();

		const { default: addMilliseconds } = await import('date-fns/add_milliseconds');

		const allowedMimeTypes = [
			...Object.values(ALLOWED_IMAGE_EXTENSIONS),
			...Object.values(ALLOWED_VIDEO_EXTENSIONS),
		];

		const items = files
			.filter((file) => {
				const isAllowed = allowedMimeTypes.includes(file.type);

				return Boolean(file.size > 0 && isAllowed);
			})
			.map((file): UploadingItem => {
				date = addMilliseconds(date, 1);

				return {
					_id: String(sharedUploadID++),
					shortID: '',
					url: '',
					name: null,
					isPrivate: false,
					isOwner: true,
					asset: {
						_id: '',
						image: {
							thumbnail: '',
							original: '',
							height: 1,
							width: 1,
							ratio: 1,
						},
						isSaved: false,
						colors: [{ color: 'rgba(0, 0, 0, 0)', amount: 1 }],
						type: 'image',
						ownBoards: [],
						viewsCount: 0,
						savedCount: 0,
					},
					user: {
						_id: '',
						name: '',
						url: '',
					},
					upload: {
						status: UPLOAD_QUEUED,
						progress: 0,
						error: null,
						file,
						date,
						...(boardID ? { boardID } : {}),
					},
				};
			});

		if (!items.length) {
			return;
		}

		items.reverse();

		SVModal.closeAll();
		dispatch(closeOverlays());

		dispatch(addItems(items));

		await Promise.all(
			items.map(async (item) => {
				// Only create thumbnail for image assets
				if (!Object.values(ALLOWED_IMAGE_EXTENSIONS).includes(item.upload.file.type)) {
					return;
				}

				if ((item.upload.file as any)._done) {
					return;
				}

				try {
					const { default: thumbnail } = await import(
						'@pkgs/shared-client/helpers/thumbnail'
					);

					const { sizeInfo, image } = await thumbnail(
						item.upload.file,
						Math.round(ASSET_IMAGE_SIZES.thumbnail.maxWidth * 0.5),
					);

					dispatch(
						updateItemThumbnail(item._id, {
							thumbnail: image,
							width: sizeInfo.width,
							height: sizeInfo.height,
							ratio: sizeInfo.ratio,
						}),
					);
					// eslint-disable-next-line no-empty
				} catch (e) {}
			}),
		);
	};
}
