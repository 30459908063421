import { useApolloClient } from '@apollo/client';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import useGridPageRole from '@apps/www/src/www/hooks/useGridPageRole';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useSetUILocked from '@apps/www/src/www/hooks/useSetUILocked';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import BoardItemsQuery from '@apps/www/src/www/queries/BoardItemsQuery';
import UploadItemMutation from '@apps/www/src/www/queries/UploadItemMutation';
import UserItemsQuery from '@apps/www/src/www/queries/UserItemsQuery';
import { type RootState } from '@apps/www/src/www/reducers';
import {
	UPLOAD_DONE,
	UPLOAD_ERROR,
	UPLOAD_QUEUED,
	UploadingItem,
	addFiles,
	clearItems,
	itemUploadComplete,
	itemUploadError,
	itemUploadProgress,
	itemUploadStart,
	toggleIsDropping,
} from '@apps/www/src/www/reducers/gridUpload';
import { closeGridUpload } from '@apps/www/src/www/reducers/ui';
import SVGridUploadProgressBar from '@pkgs/shared-client/components/SVGridUploadProgressBar';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import SVOverlay from '@pkgs/shared-client/components/SVOverlay';
import SVRetractableBar from '@pkgs/shared-client/components/SVRetractableBar';
import SVUploadOverlayContent from '@pkgs/shared-client/components/SVUploadOverlayContent';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import ItemsSortMethod from '@pkgs/shared/enums/ItemsSortMethod';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';
import findLastIndex from 'lodash/findLastIndex';
import { useRouter } from 'next/router';
import normalized from 'normalized-upload';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

const _Overlay = SVOverlay.create(
	({
		isDropping,
		isLogged,
		onSelectFiles,
	}: {
		isDropping: boolean;
		isLogged: boolean;
		onSelectFiles: (files: FileList) => void;
	}) => {
		const close = useModalClose();

		return (
			<SVUploadOverlayContent
				isDropping={isDropping}
				isLogged={isLogged}
				onClose={close}
				onSelectFiles={onSelectFiles}
			/>
		);
	},
);

class _SVGridUploadContainerInner extends React.Component<{
	isOpen: boolean;
	isDropping: boolean;
	count: number;
	completedCount: number;
	isLogged: boolean;
	onClearItems: typeof clearItems;
	onCloseGridUpload: typeof closeGridUpload;
	onToggleIsDropping: typeof toggleIsDropping;
	onAddFiles: (files: FileList) => void;
}> {
	componentDidMount() {
		document.addEventListener('dragover', this.handleDragOver);
		document.addEventListener('dragenter', this.handleDragEnter);
		// document.addEventListener('dragleave', this.handleDragLeave);
		document.addEventListener('drop', this.handleDrop);
		document.addEventListener('mouseout', this.handleMouseOut);
	}

	componentWillUnmount() {
		document.removeEventListener('dragover', this.handleDragOver);
		document.removeEventListener('dragenter', this.handleDragEnter);
		// document.removeEventListener('dragleave', this.handleDragLeave);
		document.removeEventListener('drop', this.handleDrop);
		document.removeEventListener('mouseout', this.handleMouseOut);
	}

	handleCancel = () => {
		this.props.onClearItems();
	};

	handleDragOver = (event: DragEvent) => {
		event.stopPropagation();
		event.preventDefault();

		let effect;
		try {
			effect = event.dataTransfer?.effectAllowed;
		} catch (err) {} // eslint-disable-line no-empty

		if (event.dataTransfer) {
			event.dataTransfer.dropEffect =
				effect === 'move' || effect === 'linkMove' ? 'move' : 'copy';
		}

		return false;
	};

	handleDragEnter = (event: DragEvent) => {
		event.stopPropagation();
		event.preventDefault();

		const dataTransfer = event.dataTransfer;

		if (
			dataTransfer &&
			dataTransfer.types &&
			dataTransfer.types.length &&
			dataTransfer.types.indexOf('Files') > -1
		) {
			this.props.onToggleIsDropping(true);
		}

		return false;
	};

	handleDragLeave = (event: Event) => {
		event.stopPropagation();
		event.preventDefault();

		this.props.onToggleIsDropping(false);

		return false;
	};

	handleDrop = (event: DragEvent) => {
		event.stopPropagation();
		event.preventDefault();

		normalized(event, () => {
			this.props.onToggleIsDropping(false);

			// @ts-expect-error normalized is not typed
			this.props.onAddFiles(event.items);
		});

		return false;
	};

	handleMouseOut = (event: Event) => {
		if (!this.props.isDropping) {
			return;
		}

		// @ts-ignore ...
		event = event ? event : window.event;
		// @ts-ignore ...
		const from = event.relatedTarget || event.toElement;
		if (!from || from.nodeName === 'HTML') {
			this.handleDragLeave(event);
		}
	};

	handleSelectFiles = (files: FileList) => {
		this.props.onAddFiles(files);
	};

	render() {
		const { isOpen, isDropping, count, completedCount, isLogged, onCloseGridUpload } =
			this.props;

		return (
			<>
				{(isOpen || isDropping) && (
					<SVModal.Visible
						Component={_Overlay}
						onClose={onCloseGridUpload}
						isDropping={isDropping}
						isLogged={isLogged}
						onSelectFiles={this.handleSelectFiles}
					/>
				)}
				<SVRetractableBar
					isOpen={count > 0}
					render={() => {
						return (
							<SVGridUploadProgressBar
								completedCount={completedCount}
								count={count}
								onCancel={this.handleCancel}
							/>
						);
					}}
				/>
			</>
		);
	}
}

const SVGridUploadContainer = React.memo(() => {
	const isLoggedIn = useIsLoggedIn();
	const setLocked = useSetUILocked();
	const dispatch = useDispatch();
	const { board: pageBoard } = useUserAndBoardPageQuery();
	const authUser = useAuthUser(['url', 'username']);
	const router = useRouter();
	const client = useApolloClient();
	const isUploadingRef = useRef(false);
	const uploadingItemRef = useRef<UploadingItem | null>(null);
	const { isOpen, isDropping, items } = useSelector((state: RootState) => ({
		isOpen: state.ui.isGridUploadOpen,
		isDropping: state.gridUpload.isDropping,
		items: state.gridUpload.items,
	}));
	const role = useGridPageRole();
	const hasEditorPrivileges = boardUserRoleHasBoardUserRolePrivileges(role, BoardUserRole.EDITOR);

	const handleClearItems = useEventCallback(() => dispatch(clearItems()));
	const handleToggleIsDropping = useEventCallback((isDropping: boolean) =>
		dispatch(toggleIsDropping(isDropping)),
	);
	const handleCloseGridUpload = useEventCallback(() => dispatch(closeGridUpload()));

	const cleanup = useEventCallback(() => {
		if (isUploadingRef.current) {
			setLocked(false);
		}

		isUploadingRef.current = false;
		uploadingItemRef.current = null;

		handleClearItems();
	});

	const handleAddFiles = useEventCallback((files: FileList) => {
		if (!authUser) {
			router.push('/login');

			return;
		}

		const filesArray = Array.from(files);

		let boardID = pageBoard?._id || null;

		if (!hasEditorPrivileges) {
			boardID = null;

			router.push(authUser.url);
		}

		dispatch(addFiles(filesArray, boardID));
	});

	const uploadNextItem = useEventCallback(async () => {
		if (!authUser) {
			router.push('/login');

			return;
		}

		if (uploadingItemRef.current) {
			return;
		}

		const index = findLastIndex(items, (item) => item.upload.status == UPLOAD_QUEUED);

		// No more queued items
		if (index === -1) {
			const hasDoneItem = items.some((item) => item.upload.status == UPLOAD_DONE);

			if (isUploadingRef.current) {
				setLocked(false);
			}

			isUploadingRef.current = false;

			if (hasDoneItem) {
				cleanup();
			}

			return;
		}

		if (!isUploadingRef.current) {
			setLocked(true);
		}

		isUploadingRef.current = true;

		const currentItem = items[index];

		uploadingItemRef.current = currentItem;

		dispatch(itemUploadStart(currentItem));

		try {
			dispatch(itemUploadProgress(currentItem, 0.5));

			// TODO: (graphql-after) LOW: figure out progress and dispatch itemUploadProgress events...
			// 	(progress) => {
			// 		if (progress > 0 && progress < 1) {
			// 			itemUploadProgress(currentItem, progress);
			// 		}
			// 	},

			const boardID = currentItem.upload.boardID;

			const { data } = await client.mutate({
				mutation: UploadItemMutation,
				variables: {
					input: {
						date: currentItem.upload.date,
						file: currentItem.upload.file,
						boardID,
					},
				},
				// context: {
				// 	fetchOptions: {
				// 		onUploadProgress: (progress) => {
				// 			console.info(
				// 				progress.total,
				// 				progress.loaded,
				// 				progress.loaded / (progress.total || 1),
				// 			);
				// 		},
				// 	},
				// },
			});

			dispatch(itemUploadComplete(currentItem));

			// Update current items query and add item to the list
			const uploadedItem = data?.uploadItem.item;

			const updates: AnyObject = [];

			Object.values(ItemsSortMethod).forEach((sortMethod) => {
				updates.push({
					options: {
						query: UserItemsQuery,
						variables: {
							username: authUser.username,
							sortMethod,
						},
					},
					dataKey: 'userByUsername',
				});
			});

			if (boardID) {
				Object.values(ItemsSortMethod).forEach((sortMethod) => {
					updates.push({
						options: {
							query: BoardItemsQuery,
							variables: {
								boardID,
								sortMethod,
							},
						},
						dataKey: 'boardByID',
					});
				});
			}

			updates.forEach(({ options, dataKey }: { options: any; dataKey: string }) => {
				client.cache.updateQuery(options, (data) => {
					if (!data) {
						return null;
					}

					const items = [...data[dataKey].items.items];

					items.unshift(uploadedItem);

					return {
						...data,
						[dataKey]: {
							...data[dataKey],
							items: {
								...data[dataKey].items,
								items,
							},
						},
					};
				});
			});
		} catch (e) {
			const error = e instanceof Error ? e : new Error('Unknown error');
			dispatch(itemUploadError(currentItem, error));
		}

		uploadingItemRef.current = null;
	});

	useEffect(() => {
		uploadNextItem();
	}, [uploadNextItem, items]);

	useUnmount(() => {
		cleanup();
	});

	useEffect(() => {
		if (!hasEditorPrivileges) {
			handleClearItems();
		}
	}, [hasEditorPrivileges, handleClearItems]);

	const count = items.filter((item) => item.upload.status != UPLOAD_ERROR).length;
	const completedCount = items.filter((item) => item.upload.status == UPLOAD_DONE).length + 1;

	return (
		<_SVGridUploadContainerInner
			count={count}
			completedCount={completedCount}
			isLogged={isLoggedIn}
			isOpen={isOpen}
			isDropping={isDropping}
			onAddFiles={handleAddFiles}
			onClearItems={handleClearItems}
			onToggleIsDropping={handleToggleIsDropping}
			onCloseGridUpload={handleCloseGridUpload}
		/>
	);
});

export default SVGridUploadContainer;
