import {
	type Asset,
	type AssetColor,
	type AssetImage,
	type Item,
} from '@apps/www/src/__generated__/graphql';
import {
	UPLOAD_ERROR,
	UPLOAD_QUEUED,
	UPLOAD_UPLOADING,
	type UploadingItem,
} from '@apps/www/src/www/reducers/gridUpload';
import { emptyPreventDefault, isTouch } from '@pkgs/shared-client/helpers/dom';
import IconErrorSVG from '@pkgs/shared-client/img/icon-error-inlined.svg';
import IconMoveSVG from '@pkgs/shared-client/img/icon-move-inlined.svg';
import IconVideoSVG from '@pkgs/shared-client/img/icon-video-inlined.svg';
import { unit } from '@pkgs/shared-client/styles/mixins';
import { type GridColumnsConfigButtonSize } from '@pkgs/shared/constants';
import AssetType from '@pkgs/shared/enums/AssetType';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';
import clsx from 'clsx';
import { type DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';
import { type ParsedUrlQuery } from 'node:querystring';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import type { LikeItem } from './SVGrid';
import SVGrid from './SVGrid';
import SVIconButton from './SVIconButton';
import SVImage from './SVImage';
import SVLazyImage from './SVLazyImage';
import SVLink from './SVLink';

// TODO: Improve image rendering/decoding with web-worker

export const GRID_ITEM_CLASS_NAME = 'grid-item';

export const getShortIDFromElementID = memoize((elementID) =>
	String(elementID).split('grid-item-').join(''),
);

export const createElementIDWithShortID = memoize((shortID) => `grid-item-${shortID}`);

const _NoImageWrapper = ({
	id,
	ratio,
	color,
	className,
	style,
	children,
}: React.PropsWithChildren<{
	id?: string;
	ratio: number;
	color: AssetColor['color'];
	className?: string;
	style?: React.HTMLProps<HTMLDivElement>['style'];
}>) => (
	<div
		id={id}
		className={twMerge('relative', className)}
		style={{
			paddingBottom: unit(ratio * 100, '%'),
			backgroundColor: color,
			...style,
		}}
	>
		{children}
	</div>
);

const _Skeleton = ({
	ratio,
	color,
	isStatic,
}: {
	ratio: number;
	color: AssetColor['color'];
	isStatic: boolean | undefined;
}) => (
	<_NoImageWrapper
		className={!isStatic ? 'animate-pulsing' : ''}
		style={
			!isStatic
				? {
						animationDuration: `${ratio * 1}s`,
				  }
				: undefined
		}
		ratio={ratio}
		color={color}
	/>
);

const _UploadingText = ({
	className,
	children,
}: React.PropsWithChildren<{ className?: string }>) => (
	<div className={twMerge('type-small relative text-center font-semibold', className)}>
		{children}
	</div>
);

const _Item = React.memo(
	({
		shortID,
		color,
		ratio,
		thumbnail,
		original,
		type,
		url,
		isUploading,
		uploadStatus,
		uploadProgress,
		uploadErrorMessage,
		isSelected,

		routerPathname,
		routerQuery,

		renderSaveButton,

		isSorting,

		isOver,
		isLongOver,
		onMouseEnter,
		onMouseLeave,
		onClick,
		onMove,

		// From SVGrid
		// isOwner,
		isStatic,
		isSortLoading,
		autoPlayGIFs,
		buttonSize,
		isSkeleton,
		animation,
		isRelatedItems: isRelatedItems,

		forceThumbnail,
	}: Omit<
		Props,
		'isOwner' | 'role' | 'canSort' | 'onClick' | 'onMove' | 'sourceType' | 'isSaved' | 'itemID'
	> &
		State & {
			isLongOver: boolean;
			onMouseEnter: React.HTMLProps<HTMLDivElement>['onMouseEnter'];
			onMouseLeave: React.HTMLProps<HTMLDivElement>['onMouseLeave'];
			onClick: (event: React.UIEvent) => void;
			onMove: React.MouseEventHandler;
		}) => {
		if (isSorting) {
			return (
				<_NoImageWrapper
					id={createElementIDWithShortID(shortID)}
					className={GRID_ITEM_CLASS_NAME}
					ratio={ratio}
					color={color}
				>
					<div
						className="absolute inset-0"
						style={{ border: `2px dashed rgba(255, 255, 255, 0.5)` }}
					/>
				</_NoImageWrapper>
			);
		}

		if (isUploading) {
			return (
				<_NoImageWrapper
					id={createElementIDWithShortID(shortID)}
					className={GRID_ITEM_CLASS_NAME}
					ratio={ratio}
					color={color}
				>
					{thumbnail && (
						<SVImage
							className="absolute inset-0"
							src={thumbnail}
							ratio={ratio}
							color={color}
							cover={true}
						/>
					)}

					<div
						className={clsx(
							'flex-center absolute inset-0 h-full w-full flex-col overflow-hidden bg-center bg-no-repeat p-[12%] text-white',
							uploadStatus === UPLOAD_ERROR ? 'bg-danger' : 'bg-image-loading',
							thumbnail && 'bg-opacity-80',
						)}
					>
						{(uploadStatus === UPLOAD_UPLOADING || uploadStatus === UPLOAD_ERROR) && (
							<div
								className={clsx(
									'bg-brand absolute inset-0 h-full w-full',
									thumbnail && 'opacity-90',
								)}
								style={{
									transform: `translateX(${unit(
										Math.floor((1 - (uploadProgress || 0)) * 100 * -1) - 1,
										'%',
									)})`,
								}}
							/>
						)}
						{uploadStatus === UPLOAD_QUEUED && <_UploadingText>Waiting</_UploadingText>}
						{uploadStatus === UPLOAD_UPLOADING && (
							<_UploadingText className="[text-shadow:1px_1px_0_rgba(0,0,0,0.03),-1px_1px_0_rgba(0,0,0,0.03),1px_-1px_0_rgba(0,0,0,0.03),-1px_-1px_0_rgba(0,0,0,0.03)]">
								Uploading
							</_UploadingText>
						)}
						{uploadStatus === UPLOAD_ERROR && (
							<>
								<IconErrorSVG className="mb-3" />
								<_UploadingText>{uploadErrorMessage || 'Error'}</_UploadingText>
							</>
						)}
					</div>
				</_NoImageWrapper>
			);
		}

		if (isSkeleton) {
			return <_Skeleton isStatic={!animation} ratio={ratio} color={color} />;
		}

		const isVideo = type === AssetType.VIDEO;
		const imageSrc = isStatic && !forceThumbnail ? original : thumbnail;

		const placeholder = <_NoImageWrapper ratio={ratio} color={color} />;

		if (isStatic) {
			return <SVImage src={imageSrc} placeholder={placeholder} ratio={ratio} color={color} />;
		}

		// For related items, we need a "full" navigation to item
		const linkTo = isRelatedItems
			? url
			: {
					pathname: routerPathname,
					query: { ...routerQuery, itemShortID: shortID },
			  };
		const linkAs = isRelatedItems ? undefined : url;

		return (
			<div
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className={clsx('group relative', GRID_ITEM_CLASS_NAME)}
				id={createElementIDWithShortID(shortID)}
			>
				<SVLink
					to={linkTo}
					as={linkAs}
					onClick={onClick}
					scroll={false}
					prefetch={false}
					shallow={true}
				>
					{isVideo && (isOver || autoPlayGIFs) ? (
						<video
							className="h-auto w-full"
							src={original}
							poster={thumbnail}
							playsInline
							autoPlay
							loop
							muted
						/>
					) : (
						<SVImage
							Component={SVLazyImage}
							src={imageSrc}
							placeholder={placeholder}
							ratio={ratio}
							color={color}
						/>
					)}
					{!isOver && !autoPlayGIFs && isVideo && (
						<IconVideoSVG className="absolute left-3 bottom-3 text-white" />
					)}
					<div
						className={clsx(
							'grid-show-on-editing border-brand group-hover:bg-brand/20 absolute inset-0 h-full w-full transition-all',
							isSelected ? 'bg-brand/40 border-[12px]' : 'bg-brand/0 border-0',
						)}
					/>
					{isOver && (
						<div
							className="grid-hide-on-editing absolute right-3 bottom-3"
							onClick={emptyPreventDefault}
						>
							{renderSaveButton &&
								renderSaveButton({
									buttonSize,
								})}
						</div>
					)}
					{isLongOver && (
						<div
							className="grid-hide-on-editing absolute left-3 bottom-3"
							onClick={emptyPreventDefault}
						>
							<SVIconButton
								className={clsx(
									'duration-over hover:text-primary transition-opacity ease-out hover:opacity-60',
									isSortLoading && 'pointer-events-none opacity-50',
								)}
								iconClassName="h-[37px] w-[37px]"
								src={IconMoveSVG}
								onMouseDown={onMove}
							/>
						</div>
					)}
				</SVLink>
			</div>
		);
	},
);

const TRIGGER_PROPS = [
	'isSelected',
	'isUploading',
	'isSorting',
	'isOver',
	'isStatic',
	'isSortLoading',
	'isOwner',
	'isSkeleton',
	'role',
	'shortID',
	'color',
	'ratio',
	'thumbnail',
	'original',
	'type',
	'url',
	'uploadStatus',
	'uploadProgress',
	'uploadErrorMessage',
	'autoPlayGIFs',
	'buttonSize',
	'onClick',
	'onMove',
	'routerPathname',
	'routerQuery',
	// 'renderSaveButton',
	'isSaved',
	'sourceType',
	'canSort',
	'forceThumbnail',
	'isRelatedItems',
];

export type Props = {
	isSelected?: boolean;
	isUploading: boolean;
	isSorting?: boolean;
	isStatic?: boolean;
	isSortLoading?: boolean;
	isOwner: boolean;
	isSkeleton: boolean | undefined;
	role: ValueOf<typeof BoardUserRole> | null | undefined;
	itemID: Item['_id'];
	shortID: Item['shortID'];
	color: AssetColor['color'];
	ratio: AssetImage['ratio'];
	thumbnail: AssetImage['thumbnail'];
	original: AssetImage['original'];
	type: Asset['type'];
	url: Item['url'];
	uploadStatus: UploadingItem['upload']['status'] | null;
	uploadProgress: UploadingItem['upload']['progress'] | null;
	uploadErrorMessage: string | null | undefined;
	autoPlayGIFs?: boolean;
	buttonSize: GridColumnsConfigButtonSize;
	onClick?: (itemID: Item['_id']) => void;
	onMove?: (itemID: Item['_id']) => void;
	routerPathname?: string;
	routerQuery?: ParsedUrlQuery;
	renderSaveButton?: (props: { buttonSize: GridColumnsConfigButtonSize }) => JSX.Element;
	isSaved: boolean;
	sourceType?: ValueOf<typeof SVGrid.SOURCE_TYPES>;
	canSort?: boolean;
	forceThumbnail?: boolean;
	animation?: boolean;
	isRelatedItems?: boolean;
};

type State = {
	isOver: boolean;
	isLongOver: boolean;
};

class SVGridItem extends React.Component<Props, State> {
	static itemToProps = (item: LikeItem) => ({
		key: item._id,
		itemID: item._id,
		shortID: item.shortID,

		color: item.asset.colors[0].color,
		thumbnail: item.asset.image.thumbnail,
		original: item.asset.image.original,
		ratio: item.asset.image.ratio,
		type: item.asset.type,

		url: item.url,
		isUploading: item.hasOwnProperty('upload') ? true : false,
		uploadStatus: item.hasOwnProperty('upload') ? (item as UploadingItem).upload.status : null,
		uploadProgress: item.hasOwnProperty('upload')
			? (item as UploadingItem).upload.progress
			: null,
		uploadErrorMessage: item.hasOwnProperty('upload')
			? (item as UploadingItem).upload.error?.message
			: null,
		isOwner: item.isOwner,

		isSaved: item.asset.isSaved,
	});

	static _Item = _Item; // for tests only

	state = {
		isOver: false,
		isLongOver: false,
	};

	setLongOverDebounced: DebouncedFunc<() => void>;

	constructor(props: Props) {
		super(props);

		this.setLongOverDebounced = debounce(this.setLongOver, 600);
	}

	componentWillUnmount() {
		this.setLongOverDebounced.cancel();
	}

	_canSave() {
		return !this.props.isOwner;
	}

	_canMove() {
		// Don't allow to move on feed, popular or search grids, doesn't matter if its owner or not
		if (
			this.props.sourceType &&
			(this.props.sourceType === SVGrid.SOURCE_TYPES.FEED ||
				this.props.sourceType === SVGrid.SOURCE_TYPES.TEAM_FEED ||
				this.props.sourceType === SVGrid.SOURCE_TYPES.POPULAR ||
				this.props.sourceType === SVGrid.SOURCE_TYPES.SEARCH)
		) {
			return false;
		}

		// Don't allow to move if sort method is not custom
		if (!this.props.canSort) {
			return false;
		}

		return (
			this.props.isOwner ||
			boardUserRoleHasBoardUserRolePrivileges(this.props.role, BoardUserRole.EDITOR)
		);
	}

	handleMouseEnter = () => {
		if (this.props.isStatic || isTouch()) {
			return;
		}

		if (this._canSave()) {
			this.setState({
				isOver: true,
			});
		}

		if (this._canMove()) {
			this.setLongOverDebounced();
		}
	};

	handleMouseLeave = () => {
		if (this.props.isStatic || isTouch()) {
			return;
		}

		if (this._canSave()) {
			this.setState({
				isOver: false,
			});
		}

		if (this._canMove()) {
			this.setLongOverDebounced.cancel();

			this.setState({
				isLongOver: false,
			});
		}
	};

	setLongOver = () => {
		if (this._canMove()) {
			this.setState({
				isLongOver: true,
			});
		}
	};

	handleClick = (event) => {
		if (this.props.onClick) {
			this.props.onClick(this.props.itemID);

			event.preventDefault();
			return false;
		}
	};

	handleMove = (event) => {
		event.preventDefault();
		event.stopPropagation();

		this.props.onMove && this.props.onMove(this.props.itemID);

		if (this._canMove()) {
			this.setState({
				isLongOver: false,
			});
		}
	};

	// UNSAFE_componentWillReceiveProps (nextProps, nextState) {
	// 	console.log('item', this.props.shortID, this.shouldComponentUpdate(nextProps, nextState));
	// }

	shouldComponentUpdate(nextProps, nextState) {
		const props = this.props;
		const state = this.state;

		if (state.isLongOver != nextState.isLongOver || state.isOver != nextState.isOver) {
			return true;
		}

		return TRIGGER_PROPS.some((prop) => props[prop] != nextProps[prop]);
	}

	render() {
		// eslint-disable-next-line no-unused-vars
		const {
			onMove: _,
			onClick: __,
			isOwner: ___,
			role: ____,
			canSort: _____,
			...props
		} = this.props;

		return (
			<_Item
				{...this.state}
				{...props}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
				onClick={this.handleClick}
				onMove={this.handleMove}
			/>
		);
	}
}

export default SVGridItem;
