import SVWithNoSSR from '@apps/www/src/www/containers/SVWithNoSSR';
import NiceModal, { create, useModal } from '@ebay/nice-modal-react';
import { Dialog } from '@headlessui/react';
import { SVKeyboardContext } from '@pkgs/shared-client/components/SVKeyboardKey';
import SVOverlay from '@pkgs/shared-client/components/SVOverlay';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { usePrevious, useUnmount } from 'react-use';
import wrapDisplayName from 'recompose/wrapDisplayName';
import { twMerge } from 'tailwind-merge';
import SVFlexSpacer from './SVFlexSpacer';
import SVIconButton from './SVIconButton';
import { SVTransition_Child, SVTransition_Root } from './SVTransition';

const ALIGNMENTS = {
	CENTER: 'center',
	TOP: 'top',
} as const;

const LAYOUTS = {
	MODAL: 'modal',
	OVERLAY: 'overlay',
} as const;

const _CategoryTitle = ({
	className,
	children,
}: React.PropsWithChildren<{
	className?: string;
}>) => (
	<span className={twMerge('type-label text-muted tracking-widest', className)}>{children}</span>
);

const _Title = ({
	className,
	children,
}: React.PropsWithChildren<{
	className?: string;
}>) => <h1 className={twMerge('type-small-title mb-2 font-medium', className)}>{children}</h1>;

const _ModalContext = createContext<{ close: () => void }>({ close: () => {} });

export type Props = React.PropsWithChildren<{
	isOpen: boolean;
	onClose: () => void;
	alignment?: ValueOf<typeof ALIGNMENTS>;
	className?: string;
	modalClassName?: string;
	afterOpen?: () => void;
	afterClose?: () => void;
	childrenAfter?: React.ReactNode;
}>;

const SVModal = ({
	isOpen,
	onClose,
	alignment = ALIGNMENTS.CENTER,
	className,
	modalClassName,
	children,
	afterOpen,
	afterClose,
	childrenAfter,
}: Props) => {
	return (
		<SVTransition_Root show={!!isOpen} afterEnter={afterOpen} afterLeave={afterClose}>
			<Dialog
				// static={true}
				onClose={onClose}
				className="z-index-modal fixed inset-0 flex flex-col overflow-y-auto p-4"
			>
				<SVTransition_Child
					as={Dialog.Overlay}
					className="transition-backdrop duration-slide fixed inset-0 ease-in-out"
					enterFrom="backdrop-hide"
					enterTo="backdrop-show"
				/>
				{alignment == ALIGNMENTS.CENTER && <SVFlexSpacer />}
				<SVTransition_Child
					className={twMerge(
						'duration-slide transition-all ease-in-out',
						'theme-light bg-background text-secondary -sm:w-auto -sm:max-w-[100%] -md:p-8 relative mx-auto box-border min-w-[320px] cursor-default rounded-[20px] p-12',
						modalClassName,
					)}
					enterFrom="opacity-0 translate-y-5"
					enterTo="opacity-100 translate-y-0"
				>
					<SVKeyboardContext>
						<SVIconButton
							className="touch:flex absolute top-3 right-3 hidden"
							label="Close"
							src={IconCloseSVG}
							onClick={onClose}
						/>
						<div className={twMerge('flex flex-col items-start space-y-4', className)}>
							{children}
						</div>
					</SVKeyboardContext>
				</SVTransition_Child>
				<SVFlexSpacer className="-sm:hidden" />
				{childrenAfter}
			</Dialog>
		</SVTransition_Root>
	);
};

SVModal.CategoryTitle = _CategoryTitle;
SVModal.Title = _Title;

SVModal.ALIGNMENTS = ALIGNMENTS;
SVModal.LAYOUTS = LAYOUTS;

// eslint-disable-next-line import/no-named-as-default-member
SVModal.Provider = NiceModal.Provider;

SVModal.open = <TModalProps extends object>(
	Component: React.ComponentType<TModalProps>,
	modalProps: TModalProps | undefined = undefined,
) => {
	// @ts-expect-error show is patched in
	// eslint-disable-next-line import/no-named-as-default-member
	return NiceModal.show(Component, modalProps);
};

SVModal.closeAll = () => {
	// @ts-expect-error hideAll is patched in
	// eslint-disable-next-line import/no-named-as-default-member
	return NiceModal.hideAll();
};

SVModal.create = <TModalProps extends object>(
	Component: React.ComponentType<TModalProps>,
	options: {
		className?: string;
		modalClassName?: string;
		alignment?: ValueOf<typeof ALIGNMENTS>;
		layout?: ValueOf<typeof LAYOUTS>;
	} = {
		className: undefined,
		modalClassName: undefined,
		alignment: SVModal.ALIGNMENTS.CENTER,
		layout: SVModal.LAYOUTS.MODAL,
	},
) => {
	const _Content = React.memo<{ Component: React.ComponentType<TModalProps> } & TModalProps>(
		({ Component, ...props }) => <Component {...(props as TModalProps)} />,
	);

	const SVModalWrap = ({
		children,
		afterOpen,
		onRequestClose,
		...modalProps
	}: React.PropsWithChildren<
		{
			afterOpen?: () => void;
			onRequestClose?: () => void;
		} & TModalProps
	>) => {
		const modal = useModal();
		const handleClose = useEventCallback(async () => {
			// Don't wait for this promise as it never resolves
			modal.hide();

			onRequestClose && onRequestClose();
		});
		const context = useMemo(() => ({ close: handleClose }), [handleClose]);

		const LayoutComponent = options.layout == LAYOUTS.OVERLAY ? SVOverlay : SVModal;

		return (
			<LayoutComponent
				isOpen={modal.visible}
				onClose={handleClose}
				afterOpen={afterOpen}
				afterClose={() => modal.remove()}
				alignment={options.alignment}
				className={options.className}
				modalClassName={options.modalClassName}
				childrenAfter={children}
			>
				<_ModalContext.Provider value={context}>
					<_Content Component={Component} {...(modalProps as TModalProps)} />
				</_ModalContext.Provider>
			</LayoutComponent>
		);
	};

	SVModalWrap.displayName = wrapDisplayName(Component, 'SVModalWrap');

	return create(SVModalWrap) as typeof SVModalWrap;
};

// Disable typechecking for this component
const _VisibleInner = SVWithNoSSR(
	({
		Component,
		onClose,
		...modalProps
	}: {
		Component: React.ComponentType<any>;
		onClose?: () => void;
	}) => {
		// @ts-expect-error show is patched in
		const { show, hide, visible } = useModal(Component);

		const wasVisible = usePrevious(visible);

		useUnmount(() => {
			if (visible) {
				hide();
			}
		});

		useEffect(() => {
			show(modalProps);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [show, ...Object.values(modalProps)]);

		useEffect(() => {
			if (onClose && !visible && wasVisible) {
				onClose();
			}
		}, [onClose, visible, wasVisible]);

		return null;
	},
);

const _Visible = <TModalProps extends object>({
	...props
}: {
	Component: React.ComponentType<TModalProps>;
	onClose?: () => void;
} & TModalProps) => <_VisibleInner {...props} />;

SVModal.Visible = _Visible;
SVModal.InternalContext = _ModalContext; // Used by SVOverlay

export const useModalClose = () => {
	const { close } = useContext(_ModalContext);

	return close;
};

export default SVModal;
