import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import { useRouter } from 'next/router';

const useIsTeamFeedPage = () => {
	const router = useRouter();
	const team = useAuthTeam();

	if (team && team.teamFeedAsHomepage) {
		return router.pathname === '/';
	}

	return router.pathname === '/team';
};

export default useIsTeamFeedPage;
