import { useRouter } from 'next/router';

const useCanSeeUpgradeWarning = () => {
	const router = useRouter();

	const routerPath = router.asPath;

	if (
		routerPath.indexOf('/billing/') !== 0 &&
		routerPath.indexOf('/upgrade/') !== 0 &&
		routerPath.indexOf('/profile/') !== 0 &&
		routerPath.indexOf('/team/settings/') !== 0 &&
		routerPath.indexOf('/team/users/') !== 0 &&
		routerPath.indexOf('/contact-sales/') !== 0 &&
		routerPath.indexOf('/terms/') !== 0 &&
		routerPath.indexOf('/privacy/') !== 0 &&
		routerPath.indexOf('/aup/') !== 0 &&
		routerPath.indexOf('/copyright-and-ip-policy/') !== 0
	)
		return true;

	return false;
};

export default useCanSeeUpgradeWarning;
