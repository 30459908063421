import SVPageTitle from '@pkgs/shared-client/components/SVPageTitle';
import adobeLogoSVG from '@pkgs/shared-client/img/upgrade/brands/adobe-logo.svg';
import appleLogoSVG from '@pkgs/shared-client/img/upgrade/brands/apple-logo.svg';
import figmaLogoSVG from '@pkgs/shared-client/img/upgrade/brands/figma-logo.svg';
import googleLogoSVG from '@pkgs/shared-client/img/upgrade/brands/google-logo.svg';
import ikeaLogoSVG from '@pkgs/shared-client/img/upgrade/brands/ikea-logo.svg';
import netflixLogoSVG from '@pkgs/shared-client/img/upgrade/brands/netflix-logo.svg';
import nikeLogoSVG from '@pkgs/shared-client/img/upgrade/brands/nike-logo.svg';
import spotifyLogoSVG from '@pkgs/shared-client/img/upgrade/brands/spotify-logo.svg';
import buildForYouImage4 from '@pkgs/shared-client/img/upgrade/Image-1.png';
import buildForYouImage2 from '@pkgs/shared-client/img/upgrade/Image-2.png';
import buildForYouImage1 from '@pkgs/shared-client/img/upgrade/Image-3.png';
import buildForYouImage6 from '@pkgs/shared-client/img/upgrade/Image-4.png';
import buildForYouImage3 from '@pkgs/shared-client/img/upgrade/Image-5.png';
import { twMerge } from 'tailwind-merge';

const _Brands = ({ className }: { className?: string }) => {
	const _Wrapper = ({ children }: { children: React.ReactNode }) => {
		return (
			<div className="-md:w-1/2 -sm:w-1/2 -lg:w-1/3 flex-center w-1/4 p-12 pb-0">
				{children}
			</div>
		);
	};

	return (
		<div className={twMerge('m-auto flex flex-wrap justify-between', className)}>
			<_Wrapper>
				<img src={appleLogoSVG} alt="apple" />
			</_Wrapper>
			<_Wrapper>
				<img src={googleLogoSVG} alt="google" />
			</_Wrapper>
			<_Wrapper>
				<img src={nikeLogoSVG} alt="nike" />
			</_Wrapper>
			<_Wrapper>
				<img src={netflixLogoSVG} alt="netflix" />
			</_Wrapper>
			<_Wrapper>
				<img src={spotifyLogoSVG} alt="spotify" />
			</_Wrapper>
			<_Wrapper>
				<img src={figmaLogoSVG} alt="figma" />
			</_Wrapper>
			<_Wrapper>
				<img src={adobeLogoSVG} alt="adobe" />
			</_Wrapper>
			<_Wrapper>
				<img src={ikeaLogoSVG} alt="ikea" />
			</_Wrapper>
		</div>
	);
};

const _SVBrands = ({ withSideMenu }: { withSideMenu?: boolean }) => {
	return (
		<div className="relative mt-40 pb-32 text-center">
			<SVPageTitle>
				<span className="type-title text-gray-200">
					Brands <br className="sm:hidden" />
					love it
				</span>
			</SVPageTitle>
			<p className="type-subtitle m-auto mb-8 max-w-3xl font-light text-gray-400">
				Our users are <br className="sm:hidden" />
				tastemakers working at <br className="sm:hidden" />
				the top companies <br className="md:hidden" />
				of the creative&nbsp;industry.
			</p>
			<_Brands className="-lg:max-w-[800px] max-w-[1112px]" />
			{!withSideMenu ? (
				<div className="hidden-lg">
					<img
						src={buildForYouImage1}
						data-speed={0.03}
						data-start="top center"
						loading="lazy"
						alt="still have so much to win - mbappe"
						className="-sm:top-[-37px] -md:top-[-10px] -sm:w-[17vw] absolute top-0 left-[-1.5vw] w-[10vw]"
					/>
					<img
						src={buildForYouImage2}
						data-speed={0.02}
						data-start="top center"
						loading="lazy"
						alt="car at sunset"
						className="-sm:w-[12vw] -md:top-[81px] -sm:top-[51px] -md:left-[6vw] absolute top-[170px] left-[5vw] w-[8vw]"
					/>
					<img
						src={buildForYouImage3}
						data-speed={0.04}
						data-start="top center"
						loading="lazy"
						alt="this is clay"
						className="-sm:top-[125px] -md:top-[180px] -sm:w-[16vw] absolute top-[341px] left-0 w-[10vw]"
					/>
					<img
						src={buildForYouImage4}
						data-speed={0.02}
						data-start="top center"
						loading="lazy"
						alt="bicycle"
						className="-md:top-[-17px] -sm:w-[11vw] absolute top-0 right-[-2.5vw] w-[11vw]"
					/>
					<img
						src={buildForYouImage6}
						data-speed={0.01}
						data-start="top center"
						loading="lazy"
						alt="car"
						className="-sm:top-[53px] -md:top-[125px] -sm:w-[23vw] absolute top-[240px] right-[-3vw] w-[13vw]"
					/>
				</div>
			) : null}
		</div>
	);
};

const SVBrands = () => {
	return <_SVBrands />;
};

SVBrands.Brands = _Brands;

export default SVBrands;
