import {
	SiteFragmentFragment,
	type AuthSettings,
	type TeamFragmentFragment,
	type User,
} from '@apps/www/src/__generated__/graphql';
import type SVNotificationsIconContainer from '@apps/www/src/www/containers/SVNotificationsIconContainer';
import SVSubscriptionWarningContainer from '@apps/www/src/www/containers/SVSubscriptionWarningContainer';
import SVTrackedUpgradeButtonContainer from '@apps/www/src/www/containers/SVTrackedUpgradeButtonContainer';
import SVA from '@pkgs/shared-client/components/SVA';
import SVActions from '@pkgs/shared-client/components/SVActions';
import SVAvatar from '@pkgs/shared-client/components/SVAvatar';
import SVButton, { SVButtonSIZES } from '@pkgs/shared-client/components/SVButton';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVFlexSpacer from '@pkgs/shared-client/components/SVFlexSpacer';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVPageMargins, { WIDTHS } from '@pkgs/shared-client/components/SVPageMargins';
import SVRetractableBar from '@pkgs/shared-client/components/SVRetractableBar';
import config from '@pkgs/shared-client/config';
import { preventDefault } from '@pkgs/shared-client/helpers/dom';
import { formatCount } from '@pkgs/shared-client/helpers/format';
import matchLocation from '@pkgs/shared-client/helpers/matchLocation';
import ArrowRightToTop from '@pkgs/shared-client/img/arrow-right-to-top-inlined.svg';
import IconAddSVG from '@pkgs/shared-client/img/icon-add-navbar-inlined.svg';
import IconAddSmallSVG from '@pkgs/shared-client/img/icon-add-small-inlined.svg';
import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import iconDarkMode from '@pkgs/shared-client/img/icon-dark-mode-inlined.svg';
import IconHappyFaceSVG from '@pkgs/shared-client/img/icon-happy-face-inlined.svg';
import IconLightMode from '@pkgs/shared-client/img/icon-light-mode-inlined.svg';
import IconMenuSVG from '@pkgs/shared-client/img/icon-menu-hamburguer-inlined.svg';
import IconSearchSVG from '@pkgs/shared-client/img/icon-search-inlined.svg';
import IconSelectSVG from '@pkgs/shared-client/img/icon-select-inlined.svg';
import IconSmallSelectSVG from '@pkgs/shared-client/img/icon-select-small-inlined.svg';
import IconSettingsSVG from '@pkgs/shared-client/img/icon-settings-inlined.svg';
import IconSiteMakerSVG from '@pkgs/shared-client/img/icon-site-maker-inlined.svg';
import IconSliderSVG from '@pkgs/shared-client/img/icon-slider-inlined.svg';
import IconSliderSmallSVG from '@pkgs/shared-client/img/icon-slider-small-inlined.svg';
import IconTeamSVG from '@pkgs/shared-client/img/icon-team-inlined.svg';
import IconInstagram from '@pkgs/shared-client/img/instagram-inlined.svg';
import LogoSVG from '@pkgs/shared-client/img/logo-inlined.svg';
import IconTwitter from '@pkgs/shared-client/img/twitter-inlined.svg';
import { unit } from '@pkgs/shared-client/styles/mixins';
import UpgradeButtonSource from '@pkgs/shared/enums/UpgradeButtonSource';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';

const _TopSpacer = React.forwardRef<HTMLDivElement>((props, ref) => (
	<div ref={ref} className="h-nav-height" />
));

const _LinkSection = React.memo(({ children }: OnlyChildrenProps) => (
	<div className="flex flex-col px-5">{children}</div>
));

const _ContentFooter = React.memo(() => {
	const TextFooter = [
		{ to: '/copyright-and-ip-policy/', label: 'Copyrights' },
		{ to: '/terms/', label: 'Terms' },
	];

	const Icons = [
		{ to: 'https://twitter.com/letsavee', Icon: IconTwitter },
		{ to: 'https://www.instagram.com/letsavee/', Icon: IconInstagram },
	];

	return (
		<div className="flex pb-4">
			<div className="flex w-5/6 space-x-3 text-left">
				{TextFooter.map((item) => (
					<SVDropdownContent.Links.ItemLink
						className="text-xs text-gray-500"
						key={item.to}
						to={item.to}
					>
						{item.label}
					</SVDropdownContent.Links.ItemLink>
				))}
			</div>
			<div className="mt-1 flex space-x-2 text-gray-500">
				{Icons.map((item) => (
					<item.Icon
						key={item.to}
						className="hover:text-primary cursor-pointer"
						onClick={() => window.open(item.to, '_blank')}
					/>
				))}
			</div>
		</div>
	);
});

const _NavLogo = React.memo(
	({ isEditing, locationPath }: Pick<Props, 'isEditing' | 'locationPath'>) => (
		<>
			<li>
				<SVIconButton
					src={LogoSVG}
					className="text-primary"
					Component={SVLink}
					to="/"
					title="Home"
					isCurrent={isEditing || matchLocation(locationPath, '/')}
				/>
			</li>
		</>
	),
);

const _NavUpgradeButton = React.memo(
	({
		userHasExpiredSubscription,
		userItemsCount,
	}: Pick<Props, 'userHasExpiredSubscription' | 'userItemsCount'>) => {
		const userItemsCountOrZero = userItemsCount || 0;
		const itemsLimit = config.payment.maxFreeItems;
		const statsText = `You have ${formatCount(
			userItemsCountOrZero,
		)} saves out of your free limit of ${formatCount(itemsLimit)}.`;

		let progressBar: React.ReactNode = null;
		if (userItemsCountOrZero > 0) {
			const ratio = Math.min(1, Math.max(0, userItemsCountOrZero / itemsLimit));

			progressBar = (
				<div className="hidden min-w-[90px] items-center space-y-2 md:flex md:flex-col">
					<div className="type-label text-primary/75 w-full text-[10px]">
						Saved {formatCount(userItemsCountOrZero)} of {formatCount(itemsLimit)} saves
					</div>
					<div className="bg-primary/20 relative h-[2px] w-full flex-grow overflow-hidden rounded">
						<div
							className={clsx(
								'absolute top-0 left-0 h-full',
								ratio >= 0.9 ? 'bg-danger' : 'bg-brand',
							)}
							style={{ width: unit(Math.round(ratio * 100), '%') }}
						/>
					</div>
				</div>
			);
		}

		return (
			<li>
				<div className="relative flex items-center space-x-4">
					<SVTrackedUpgradeButtonContainer
						source={UpgradeButtonSource.NAV_BAR}
						size={SVButtonSIZES.SMALL}
						className="type-label rounded-[10px] py-3 px-4 font-semibold"
						to={userHasExpiredSubscription ? '/upgrade/restart/' : '/upgrade/'}
						title={
							userHasExpiredSubscription
								? `Restart Premium\n\n${statsText}`
								: `Upgrade\n\n${statsText}`
						}
					>
						{userHasExpiredSubscription ? 'Restart Premium' : 'Upgrade'}
					</SVTrackedUpgradeButtonContainer>
					{progressBar}
				</div>
			</li>
		);
	},
);

const _NavItemLink = React.memo(
	({
		to,
		label,
		Icon,
		side,
	}: {
		to: string;
		label: string;
		Icon: React.ComponentType;
		side: boolean;
	}) => (
		<SVDropdownContent.Links.ItemLink to={to}>
			{side ? (
				<>
					{label}
					<SVFlexSpacer />
					<Icon />
				</>
			) : (
				<>
					<div className="mr-2 flex w-[28px] items-center justify-center">
						<Icon />
					</div>
					{label}
				</>
			)}
		</SVDropdownContent.Links.ItemLink>
	),
);

const _NavItemLinkAction = React.memo(
	({
		label,
		Icon,
		onClick,
	}: {
		label: string;
		Icon: React.ComponentType;
		onClick: (event: React.UIEvent<Element, UIEvent>) => void;
	}) => (
		<SVDropdownContent.Links.Item onClick={onClick}>
			<>
				<div className="mr-2 flex w-[28px] items-center justify-center">
					<Icon />
				</div>
				{label}
			</>
		</SVDropdownContent.Links.Item>
	),
);

const _NavToggleDarkMode = React.memo(
	({
		onClick,
		Icon,
		children,
	}: {
		onClick: (event: React.UIEvent<Element, UIEvent>) => void;
		Icon: React.ComponentType;
		children: React.ReactNode;
	}) => (
		<div
			className="text-primary hover:text-muted flex cursor-pointer items-center pt-1 pb-1"
			onClick={onClick}
		>
			<div className="mr-2 flex w-[28px] items-center justify-center">
				<Icon />
			</div>
			{children}
		</div>
	),
);

const _MediumViewportUtilsOptions = React.memo(
	({
		onOpenGridSettings,
		onOpenGridUpload,
		onToggleEditing,
		showGridControls,
		isDarkMode,
		onToggleDarkMode,
	}: Pick<
		Props,
		| 'onOpenGridSettings'
		| 'onOpenGridUpload'
		| 'onToggleEditing'
		| 'showGridControls'
		| 'isDarkMode'
		| 'onToggleDarkMode'
	>) => (
		<>
			<SVDropdownContent.Links.Separator />
			<_LinkSection>
				<_NavToggleDarkMode
					onClick={onToggleDarkMode}
					Icon={isDarkMode ? IconLightMode : iconDarkMode}
				>
					{isDarkMode ? 'Light Mode' : 'Dark Mode'}
				</_NavToggleDarkMode>
				{showGridControls && (
					<_NavItemLinkAction
						onClick={onOpenGridSettings}
						label="Customize Grid"
						Icon={IconSliderSmallSVG}
					/>
				)}

				{showGridControls && (
					<_NavItemLinkAction
						onClick={onToggleEditing}
						label="Multi select saves"
						Icon={IconSmallSelectSVG}
					/>
				)}

				<_NavItemLinkAction
					onClick={onOpenGridUpload}
					label="Upload something"
					Icon={IconAddSVG}
				/>
			</_LinkSection>
		</>
	),
);

const _NavAvatarIcon = React.memo(
	({
		userURL,
		userName,
		userAvatarURL,
		userCanSeeBillingPage,
		userHasExpiredSubscription,
		onToggleDarkMode,
		isDarkMode,
		userEmail,
		team,
		unreadNotificationsCount,
		onToggleEditing,
		showGridControls,
		onOpenGridUpload,
		NotificationsComponent,
		onOpenGridSettings,
		site,
	}: Pick<
		Props,
		| 'userURL'
		| 'userName'
		| 'userAvatarURL'
		| 'userCanSeeBillingPage'
		| 'userHasExpiredSubscription'
		| 'onToggleDarkMode'
		| 'isDarkMode'
		| 'userEmail'
		| 'team'
		| 'unreadNotificationsCount'
		| 'onToggleEditing'
		| 'showGridControls'
		| 'onOpenGridUpload'
		| 'NotificationsComponent'
		| 'onOpenGridSettings'
		| 'site'
	>) => (
		<li>
			<SVDropdown
				renderTrigger={({ isOpen, ...props }) => (
					<>
						<SVA
							className={clsx(
								'-md:flex hidden min-h-[40px] min-w-[70px] items-center rounded-full border border-gray-600 px-1.5',
								{
									'bg-gray-900 bg-opacity-80 backdrop-blur-xl': isOpen,
								},
							)}
							Component={SVLink}
							to={userURL}
							title="View your saves"
							{...props}
						>
							<div className="flex items-center space-x-2">
								<div className="relative">
									<SVAvatar className="h-[28px] w-[28px]" src={userAvatarURL} />
									{unreadNotificationsCount > 0 ? <_BadgeNotifications /> : null}
								</div>
								<div className="text-sm">
									<IconMenuSVG width="13" height="13" />
								</div>
							</div>
						</SVA>
						<SVA Component={SVLink} to={userURL} title="View your saves" {...props}>
							<SVAvatar
								className="-md:hidden flex h-[32px] w-[32px]"
								src={userAvatarURL}
							/>
						</SVA>
					</>
				)}
				renderContent={() => (
					<div className="min-w-[220px] pt-4">
						<_LinkSection>
							<SVDropdownContent.Links.ItemLinkMain to={userURL} label={userEmail}>
								{userName}
							</SVDropdownContent.Links.ItemLinkMain>
							<SVButton
								className="backdrop-hide mb-1 mt-2 w-full rounded-lg bg-gray-100 bg-opacity-10 backdrop-blur-lg transition-all duration-500 ease-in-out"
								Component={SVLink}
								to={userURL}
								title="View Profile"
								size={SVButtonSIZES.EXTRA_SMALL}
							>
								View Profile
							</SVButton>
							{!userCanSeeBillingPage || userHasExpiredSubscription ? (
								<SVButton
									className="backdrop-hide mb-2 w-full rounded-lg bg-gray-100 bg-opacity-10 backdrop-blur-lg transition-all duration-500 ease-in-out"
									Component={SVLink}
									to={userHasExpiredSubscription ? '/billing/' : '/upgrade/'}
									title={
										userHasExpiredSubscription ? 'Restart Premium' : 'Upgrade'
									}
									size={SVButtonSIZES.EXTRA_SMALL}
								>
									{userHasExpiredSubscription ? 'Restart Premium' : 'Upgrade'}
								</SVButton>
							) : null}
						</_LinkSection>
						<SVDropdownContent.Links.Separator />
						<_LinkSection>
							<_NavItemLink
								to="/profile/"
								label="Settings"
								Icon={IconSettingsSVG}
								side={false}
							/>
							<_NavItemLink
								to="/site-maker/edit/"
								label={site ? 'My site' : 'Create your site'}
								Icon={IconSiteMakerSVG}
								side={false}
							/>
							<_NavItemLink
								to={team ? `/team/users/` : '/team/'}
								label={team ? 'Team' : 'Start a team'}
								Icon={IconTeamSVG}
								side={false}
							/>
							<_NavItemLink
								to="/extension/"
								label="Get our extension"
								Icon={IconHappyFaceSVG}
								side={false}
							/>
							<div className="-md:hidden">
								<_NavToggleDarkMode
									onClick={onToggleDarkMode}
									Icon={isDarkMode ? IconLightMode : iconDarkMode}
								>
									{isDarkMode ? 'Light Mode' : 'Dark Mode'}
								</_NavToggleDarkMode>
							</div>
							<div className="-md:block hidden">
								{NotificationsComponent && <NotificationsComponent />}
							</div>
						</_LinkSection>
						<div className="-md:block hidden">
							<_MediumViewportUtilsOptions
								isDarkMode={isDarkMode}
								onToggleEditing={onToggleEditing}
								onOpenGridSettings={onOpenGridSettings}
								onOpenGridUpload={onOpenGridUpload}
								showGridControls={showGridControls}
								onToggleDarkMode={onToggleDarkMode}
							/>
						</div>
						<SVDropdownContent.Links.Separator />
						<_LinkSection>
							<_NavItemLink
								to="https://inspire.savee.it/"
								label="Blog"
								Icon={ArrowRightToTop}
								side
							/>
							<_NavItemLink
								to="https://marketplace.savee.it/"
								label="Marketplace"
								Icon={ArrowRightToTop}
								side
							/>
							<SVDropdownContent.Links.ItemLink to="/contact-us/">
								Contact us
							</SVDropdownContent.Links.ItemLink>
							<SVDropdownContent.Links.ItemLink to="/logout/">
								Log out
							</SVDropdownContent.Links.ItemLink>
						</_LinkSection>
						<SVDropdownContent.Links.Separator />
						<_LinkSection>
							<_ContentFooter />
						</_LinkSection>
					</div>
				)}
			/>
		</li>
	),
);

const _DefaultNavItems = React.memo(
	({ userURL, team, locationPath }: Pick<Props, 'userURL' | 'team' | 'locationPath'>) => {
		type Item = {
			to: string;
			label: string;
			matchUrl?: string;
		};

		const items: Item[] = [
			{
				to: team?.teamFeedAsHomepage ? '/feed/' : '/',
				label: 'home',
			},
			{
				to: `${userURL}`,
				label: 'you',
				matchUrl: `${userURL}**`,
			},
			...(team ? [{ to: team.teamFeedAsHomepage ? '/' : '/team/', label: team.name }] : []),
			{
				to: '/search/',
				label: 'search',
				matchUrl: '/search/**',
			},
		];

		const navgateToTop = (item: Item) => {
			if (
				matchLocation(locationPath, item.to) ||
				matchLocation(locationPath, item?.matchUrl || '')
			) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		};

		const subnavItems = items.map((item) => (
			<SVActions.NavItem key={item.to} to={item.to} matchURL={item.matchUrl}>
				<div
					className="whitespace-no-wrap -md:max-w-[8vw] max-w-[10vw] cursor-pointer truncate font-medium"
					onClick={() => navgateToTop(item)}
				>
					{item.label}
				</div>
			</SVActions.NavItem>
		));

		return (
			<>
				<div className="whitespace-no-wrap absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
					<SVActions className="w-auto" use={SVActions.USES.FEEDNAV}>
						{subnavItems}
					</SVActions>
				</div>
			</>
		);
	},
);

const _NavUploadIcon = React.memo(
	({
		onClick,
		onNewBoardClick,
	}: Pick<Props, 'onNewBoardClick'> & { onClick: (event: React.UIEvent) => void }) => (
		<SVDropdown
			// triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			renderTrigger={({ isOpen: _, ...props }) => (
				<SVIconButton
					src={IconAddSVG}
					onClick={onClick}
					title="Upload"
					{...props}
					iconClassName="w-6 h-6 text-gray-400"
				/>
			)}
			renderContent={() => (
				<SVDropdownContent.Links>
					<SVDropdownContent.Links.Item onClick={onClick}>
						Upload
					</SVDropdownContent.Links.Item>
					<SVDropdownContent.Links.ItemLink to="/import/">
						Import
					</SVDropdownContent.Links.ItemLink>
					<SVDropdownContent.Links.Item onClick={onNewBoardClick}>
						New board
					</SVDropdownContent.Links.Item>
				</SVDropdownContent.Links>
			)}
		/>
	),
);

const _NavToggleGridSettingsIcon = React.memo(
	({ onClick }: { onClick: (event: React.UIEvent) => void }) => (
		<li>
			<SVIconButton
				src={IconSliderSVG}
				onClick={onClick}
				title="Change size / padding"
				iconClassName="w-6 h-6 text-gray-400"
			/>
		</li>
	),
);

const _NavToggleEditingIcon = React.memo(
	({
		isEditing,
		onClick,
	}: Pick<Props, 'isEditing'> & { onClick: (event: React.UIEvent) => void }) => (
		<li>
			<SVIconButton
				src={IconSelectSVG}
				onClick={onClick}
				isPressed={isEditing}
				title="Toggle selection"
				iconClassName="w-6 h-6 text-gray-400"
			/>
		</li>
	),
);

const _Nav = ({
	isDarkMode,
	isEditing,
	isLogged,
	// isNavOpen,
	locationPath,
	// onCloseNav,
	onOpenGridSettings,
	onOpenGridUpload,
	onNewBoardClick,
	// onOpenNav,
	// onOpenOnboard,
	// onSelectFiles,
	onToggleDarkMode,
	onToggleEditing,
	showUpgrade,
	userAvatarURL,
	userCanSeeBillingPage,
	userHasProPrivileges,
	userHasExpiredSubscription,
	NotificationsComponent,
	showGridControls,
	userURL,
	userName,
	userItemsCount,
	userEmail,
	unreadNotificationsCount,
	onHeightChange,
	team,
	site,
}: Omit<Props, 'isSmallNav'>) => {
	const divRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (typeof ResizeObserver === 'undefined') {
			return;
		}

		const handleResize = () => {
			onHeightChange && onHeightChange(divRef.current?.clientHeight || 0);
		};

		const resizeObserver = new ResizeObserver(handleResize);

		if (divRef.current) {
			resizeObserver.observe(divRef.current);
		}
		
		const currentRef = divRef.current; // Store the current ref in a variable

		return () => {
			if (currentRef) {
				resizeObserver.unobserve(currentRef); // Use the stored variable
			}
		};
	}, [onHeightChange]);

	return (
		<div ref={divRef}>
			<SVSubscriptionWarningContainer />
			<div className="h-nav-height text-secondary relative flex items-center">
				<div className="duration-slide bg-background absolute inset-0 h-full w-full transition-colors ease-in-out" />
				<SVPageMargins width={WIDTHS.WIDE} className="flex-grow">
					{isLogged && (
						<_DefaultNavItems
							userURL={userURL}
							team={team}
							locationPath={locationPath}
						/>
					)}
					<ul className="flex items-center space-x-6">
						<_NavLogo isEditing={isEditing} locationPath={locationPath} />
						{isLogged &&
							showUpgrade &&
							(!userHasProPrivileges || userHasExpiredSubscription) && (
								<_NavUpgradeButton
									userHasExpiredSubscription={userHasExpiredSubscription}
									userItemsCount={userItemsCount}
								/>
							)}
						<SVFlexSpacer />
						<li>
							<ul className="flex items-center space-x-4">
								<div className="-md:hidden flex items-center space-x-4 text-gray-400">
									{isLogged && showGridControls && (
										<_NavToggleEditingIcon
											isEditing={isEditing}
											onClick={onToggleEditing}
										/>
									)}
									{showGridControls && (
										<_NavToggleGridSettingsIcon onClick={onOpenGridSettings} />
									)}

									{isLogged && (
										<_NavUploadIcon
											onClick={onOpenGridUpload}
											onNewBoardClick={onNewBoardClick}
										/>
									)}

									{NotificationsComponent && (
										<li>
											<NotificationsComponent showLabel />
										</li>
									)}
								</div>

								{isLogged && (
									<_NavAvatarIcon
										userURL={userURL}
										userName={userName}
										userAvatarURL={userAvatarURL}
										userCanSeeBillingPage={userCanSeeBillingPage}
										userHasExpiredSubscription={userHasExpiredSubscription}
										onToggleDarkMode={onToggleDarkMode}
										isDarkMode={isDarkMode}
										userEmail={userEmail}
										team={team}
										unreadNotificationsCount={unreadNotificationsCount}
										onToggleEditing={onToggleEditing}
										showGridControls={showGridControls}
										onOpenGridUpload={onOpenGridUpload}
										NotificationsComponent={NotificationsComponent}
										onOpenGridSettings={onOpenGridSettings}
										site={site}
									/>
								)}
								{!isLogged && (
									<li className="color-white z-10 ml-3">
										<SVA Component={SVLink} to="/join/">
											Join
										</SVA>{' '}
										/{' '}
										<SVA Component={SVLink} to="/login/">
											Log In
										</SVA>
									</li>
								)}
							</ul>
						</li>
					</ul>
				</SVPageMargins>
			</div>
		</div>
	);
};

const _SmallItem = ({ children }: OnlyChildrenProps) => <li className="relative">{children}</li>;

const _SmallSearchItem = React.memo(({ locationPath }: Pick<Props, 'locationPath'>) => {
	const isCurrent = matchLocation(locationPath, '/search/**');

	return (
		<_SmallItem>
			<SVIconButton
				className="text-primary"
				Component={SVLink}
				src={IconSearchSVG}
				to="/search/"
				isCurrent={isCurrent}
				label="Search"
			/>
		</_SmallItem>
	);
});

const _BadgeNotifications = React.memo(() => (
	<span className="theme-dark flex-center type-label bg-brand text-primary duration-over absolute top-0 right-0 h-3 w-3 rounded-full transition ease-out group-hover:brightness-75"></span>
));

const _SmallAvatarItem = React.memo(
	({
		onOpenNav,
		userURL,
		unreadNotificationsCount,
	}: Pick<Props, 'onOpenNav' | 'userURL' | 'unreadNotificationsCount'>) => {
		return (
			<SVLink
				className="bg-blur-xl text-primary relative z-10  flex h-10 w-10 items-center justify-center rounded-full bg-gray-700 bg-opacity-80 align-middle"
				to={userURL}
				title="Open menu"
				onClick={preventDefault(onOpenNav)}
			>
				<IconMenuSVG />
				{unreadNotificationsCount > 0 ? <_BadgeNotifications /> : ''}
			</SVLink>
		);
	},
);

const _SmallItemLink = React.memo(
	({
		to,
		children,
		locationPath,
	}: {
		to: string;
		children: React.ReactNode;
		locationPath: string;
	}) => {
		const isCurrentPage =
			matchLocation(locationPath, to) ||
			(to !== '/' && matchLocation(locationPath, `${to}/**`));

		return (
			<div className="whitespace-no-wrap flex text-start">
				<SVLink
					to={to}
					className={clsx('relative max-w-[25vw] truncate font-semibold ', {
						'text-primary': isCurrentPage,
						'text-muted': !isCurrentPage,
					})}
				>
					{children}
				</SVLink>
			</div>
		);
	},
);

// TODO: Fix bug when viewportName changes (this doesn't get rendered, for some reason) still a bug?
const _SmallNav = ({
	isDarkMode,
	// isEditing,
	// showGridControls,
	isLogged,
	isNavOpen,
	locationPath,
	onCloseNav,
	// onOpenGridSettings,
	onOpenGridUpload,
	onOpenNav,
	// onOpenOnboard,
	// onSelectFiles,
	onToggleDarkMode,
	NotificationsComponent,
	// onToggleEditing,
	// showUpgrade,
	// userAvatarURL,
	userCanSeeBillingPage,
	userHasExpiredSubscription,
	userURL,
	unreadNotificationsCount,
	team,
	site,
}: Omit<Props, 'isSmallNav'>) => {
	return (
		<>
			<ul className="theme-dark h-18 relative flex w-full items-center pb-[env(safe-area-inset-bottom)]">
				<div className="bottom-gradient pointer-events-none absolute -top-full h-[200%] w-full" />

				<div className="flex space-x-[4vw] pl-4 text-[6vw]">
					<_SmallItemLink
						to={team?.teamFeedAsHomepage ? '/feed/' : '/'}
						locationPath={locationPath}
					>
						home
					</_SmallItemLink>
					{isLogged && (
						<_SmallItemLink to={userURL} locationPath={locationPath}>
							you
						</_SmallItemLink>
					)}
					{team && (
						<_SmallItemLink
							to={team.teamFeedAsHomepage ? '/' : '/team/'}
							locationPath={locationPath}
						>
							{team['name']}
						</_SmallItemLink>
					)}
				</div>

				<SVFlexSpacer />
				<div className="m-6 flex items-center justify-end space-x-4">
					{isLogged && <_SmallSearchItem locationPath={locationPath} />}
					{isLogged && (
						<_SmallAvatarItem
							onOpenNav={onOpenNav}
							userURL={userURL}
							unreadNotificationsCount={unreadNotificationsCount}
						/>
					)}
				</div>
				{!isLogged && (
					<div className="relative mr-6">
						<SVA Component={SVLink} to="/join/">
							Join
						</SVA>{' '}
						/{' '}
						<SVA Component={SVLink} to="/login/">
							Log In
						</SVA>
					</div>
				)}
			</ul>
			<SVRetractableBar
				position={SVRetractableBar.POSITIONS.BOTTOM}
				isOpen={isLogged && isNavOpen}
				onClose={onCloseNav}
				render={() => (
					<_SmallNavBottom
						onCloseNav={onCloseNav}
						userCanSeeBillingPage={userCanSeeBillingPage}
						userHasExpiredSubscription={userHasExpiredSubscription}
						userURL={userURL}
						onToggleDarkMode={onToggleDarkMode}
						isDarkMode={isDarkMode}
						onOpenGridUpload={onOpenGridUpload}
						NotificationsComponent={NotificationsComponent}
						team={team}
						site={site}
					/>
				)}
			/>
		</>
	);
};

const _NavToggleDarkModeSmall = React.memo(
	({
		onClick,
		Icon,
		children,
	}: {
		onClick: (event: React.UIEvent<Element, UIEvent>) => void;
		Icon: React.ComponentType;
		children: React.ReactNode;
	}) => (
		<div className="hover:text-muted  text-primary mb-1 flex" onClick={onClick}>
			<div className="mr-2.5 flex w-[23px] items-center justify-center">
				<Icon />
			</div>
			{children}
		</div>
	),
);

const _SmallFooterContent = React.memo(() => {
	const TextFooter = [
		{ to: '/copyright-and-ip-policy/', label: 'Copyrights' },
		{ to: '/terms/', label: 'Terms' },
	];

	const Icons = [
		{ to: 'https://www.instagram.com/letsavee/', Icon: IconInstagram },
		{ to: 'https://twitter.com/letsavee', Icon: IconTwitter },
	];

	return (
		<div className="flex pb-4">
			<div className="flex w-5/6 space-x-5 text-left">
				{TextFooter.map((item) => (
					<SVDropdownContent.Links.ItemLink
						className="text-sm text-gray-500"
						to={item.to}
						key={item.to}
					>
						{item.label}
					</SVDropdownContent.Links.ItemLink>
				))}
			</div>
			<div className="mt-1 flex w-full justify-end space-x-3 text-gray-500">
				{Icons.map((item) => (
					<item.Icon
						key={item.to}
						className="cursor-pointer"
						onClick={() => window.open(item.to, '_blank')}
					/>
				))}
			</div>
		</div>
	);
});

const _SmallNavItemLink = ({
	children,
	link,
	onClick,
	Icon,
	noIcon,
}: {
	children: React.ReactNode;
	link: string;
	onClick?: (event: React.UIEvent) => void;
	Icon: React.ComponentType<any>;
	noIcon?: boolean;
}) => (
	<SVDropdownContent.Links.ItemLink to={link} onClick={onClick}>
		{!noIcon && <div className="mr-3 text-xl">{Icon && <Icon width="22" height="22" />}</div>}
		{children}
	</SVDropdownContent.Links.ItemLink>
);

const _SmallNavItemExternalLink = ({
	children,
	link,
	useIcon,
	onClick,
	Icon,
}: {
	children: React.ReactNode;
	link: string;
	useIcon: boolean;
	onClick?: (event: React.UIEvent) => void;
	Icon?: React.ComponentType;
}) => (
	<SVDropdownContent.Links.ItemLink to={link} onClick={onClick} target="_blank">
		{children}
		{useIcon && <div className="ml-3">{Icon && <Icon />}</div>}
	</SVDropdownContent.Links.ItemLink>
);

const _SmallNavBottom = React.memo(
	({
		onCloseNav,
		userHasExpiredSubscription,
		userCanSeeBillingPage,
		onToggleDarkMode,
		isDarkMode,
		onOpenGridUpload,
		NotificationsComponent,
		team,
		site,
	}: Pick<
		Props,
		| 'onCloseNav'
		| 'userURL'
		| 'userCanSeeBillingPage'
		| 'userHasExpiredSubscription'
		| 'isDarkMode'
		| 'onToggleDarkMode'
		| 'onOpenGridUpload'
		| 'NotificationsComponent'
		| 'team'
		| 'site'
	>) => (
		<>
			<ul className="box-border flex h-full w-full flex-col justify-center overflow-y-auto rounded-t-3xl bg-gray-900 bg-opacity-80 px-6 pt-12 text-2xl backdrop-blur-xl">
				<SVPageMargins width={WIDTHS.WIDE} className="flex-grow">
					<SVIconButton
						className="absolute top-3 right-3"
						src={IconCloseSVG}
						onClick={onCloseNav}
					/>

					{!userCanSeeBillingPage || userHasExpiredSubscription ? (
						<SVButton
							className="mb-4 h-12 w-full text-xl"
							Component={SVLink}
							to={userHasExpiredSubscription ? '/billing/' : '/upgrade/'}
							title={userHasExpiredSubscription ? 'Restart Premium' : 'Upgrade'}
							size={SVButtonSIZES.EXTRA_SMALL}
						>
							{userHasExpiredSubscription ? 'Restart Premium' : 'Upgrade'}
						</SVButton>
					) : null}

					<_SmallNavItemLink link="/profile/" onClick={onCloseNav} Icon={IconSettingsSVG}>
						Settings
					</_SmallNavItemLink>
					<_SmallNavItemLink
						link="/site-maker/edit/"
						onClick={onCloseNav}
						Icon={IconSiteMakerSVG}
					>
						{site ? 'My site' : 'Create your site'}
					</_SmallNavItemLink>
					<_SmallNavItemLink
						link={team ? `/team/users/` : '/team/'}
						onClick={onCloseNav}
						Icon={IconTeamSVG}
					>
						{team ? 'Team' : 'Start a team'}
					</_SmallNavItemLink>
					{NotificationsComponent && <NotificationsComponent />}

					<SVDropdownContent.Links.Separator />
					<_NavToggleDarkModeSmall
						onClick={onToggleDarkMode}
						Icon={isDarkMode ? IconLightMode : iconDarkMode}
					>
						{isDarkMode ? 'Light Mode' : 'Dark Mode'}
					</_NavToggleDarkModeSmall>

					<_SmallNavItemLink link="/" onClick={onOpenGridUpload} Icon={IconAddSmallSVG}>
						Upload
					</_SmallNavItemLink>
					<SVDropdownContent.Links.Separator />
					<_SmallNavItemExternalLink
						link="https://inspire.savee.it/"
						onClick={onCloseNav}
						Icon={ArrowRightToTop}
						useIcon
					>
						Blog
					</_SmallNavItemExternalLink>

					<_SmallNavItemExternalLink
						link="https://marketplace.savee.it/"
						onClick={onCloseNav}
						Icon={ArrowRightToTop}
						useIcon
					>
						Marketplace
					</_SmallNavItemExternalLink>

					<_SmallNavItemLink
						link="/contact-us/"
						onClick={onCloseNav}
						Icon={ArrowRightToTop}
						noIcon
					>
						Contact us
					</_SmallNavItemLink>

					<_SmallNavItemLink
						link="/logout/"
						onClick={onCloseNav}
						Icon={ArrowRightToTop}
						noIcon
					>
						Log out
					</_SmallNavItemLink>

					<SVDropdownContent.Links.Separator />

					<_SmallFooterContent />
				</SVPageMargins>
			</ul>
		</>
	),
);

type Props = {
	isSmallNav: boolean;
	isDarkMode: AuthSettings['darkMode'];
	isEditing: boolean;
	showGridControls: boolean;
	isLogged: boolean;
	isAtTop: boolean;
	isNavOpen: boolean;
	locationPath: string;
	onCloseNav: (event: React.UIEvent) => void;
	onOpenGridSettings: (event: React.UIEvent) => void;
	onOpenGridUpload: (event: React.UIEvent) => void;
	onOpenNav: (event: React.UIEvent) => void;
	onNewBoardClick: (event: React.UIEvent) => void;
	onOpenOnboard: (event: React.UIEvent) => void;
	onSelectFiles: (files: FileList) => void;
	onToggleDarkMode: (event: React.UIEvent) => void;
	onToggleEditing: (event: React.UIEvent) => void;
	showUpgrade: boolean;
	userAvatarURL: User['avatarURL'] | null;
	userCanSeeBillingPage: boolean;
	userHasProPrivileges: boolean;
	userHasExpiredSubscription: boolean;
	userURL: User['url'];
	userName: User['name'];
	userItemsCount: User['itemsCount'] | null;
	NotificationsComponent?: typeof SVNotificationsIconContainer;
	team?: TeamFragmentFragment | null;
	site: SiteFragmentFragment | null;
	userEmail: AuthSettings['email'];
	unreadNotificationsCount: AuthSettings['unreadNotificationsCount'];
	onHeightChange: (height: number) => void;
};

const SVNav = ({ isSmallNav, ...otherProps }: Props) => {
	if (isSmallNav) {
		return <_SmallNav {...otherProps} />;
	}

	return <_Nav {...otherProps} />;
};

SVNav._SmallNavBottom = _SmallNavBottom; // For tests only

SVNav.TopSpacer = _TopSpacer;

export default SVNav;
