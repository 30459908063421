import { useApolloClient, useQuery } from '@apollo/client';
import { type AuthFragmentFragment, type User } from '@apps/www/src/__generated__/graphql';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useOnboard from '@apps/www/src/www/hooks/useOnboard';
import AuthQuery from '@apps/www/src/www/queries/AuthQuery';
import DismissMegaphoneMutation from '@apps/www/src/www/queries/DismissMegaphoneMutation';
import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVModal from '@pkgs/shared-client/components/SVModal';
import config from '@pkgs/shared-client/config';
import { formatURL } from '@pkgs/shared-client/helpers/format';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import megaphoneCover from '@pkgs/shared-client/img/megaphone-cover2.jpg';
import { useRouter } from 'next/router';
import React from 'react';
import SVWithNoSSR from './SVWithNoSSR';

const _ModalContent = ({
	megaphone,
	userName,
	userUsername,
}: {
	megaphone: ArrayElement<AuthFragmentFragment['settings']['megaphones']>;
	userName: User['name'];
	userUsername: User['username'];
}) => {
	const hasCTA = !!megaphone.ctaLabel;
	const ctaLabel = hasCTA ? megaphone.ctaLabel : null;
	const ctaURL = hasCTA ? formatURL(config.apiURL, `megaphone/${megaphone._id}/cta/`) : null;

	let body = megaphone.body
		.split('%USER_NAME%')
		.join(userName)
		.split('%USER_USERNAME%')
		.join(userUsername);

	if (ctaURL) {
		body = body.split('%CTA_URL%').join(ctaURL);
	}

	return (
		<>
			{/* <SVModal.CategoryTitle>What&#39;s new</SVModal.CategoryTitle> */}
			<img src={megaphoneCover} alt="" className="w-full object-cover" />
			<div className="flex flex-col space-y-4">
				<h2 className="type-small-title font-semibold">{megaphone.title}</h2>
				<div
					className="megaphone-body text-gray-500"
					dangerouslySetInnerHTML={{ __html: body }}
				/>
			</div>
			{hasCTA && ctaURL ? (
				<SVButton Component="a" href={ctaURL} use={SVButtonUSES.PRIMARY}>
					{ctaLabel}
				</SVButton>
			) : null}
		</>
	);
};

const _Modal = SVModal.create(_ModalContent, {
	modalClassName: 'max-w-[450px]',
	className: 'space-y-10',
});

const _SVMegaphoneModalContainer = () => {
	const router = useRouter();
	const client = useApolloClient();
	const [isOnboardingOpen] = useOnboard();

	const isLoggedIn = useIsLoggedIn();
	const { data } = useQuery(AuthQuery);

	const megaphones = data?.auth?.settings.megaphones || [];
	const megaphone = megaphones.length > 0 ? megaphones[0] : null;

	const handleClose = useEventCallback(() => {
		if (!megaphone) {
			return;
		}

		client.mutate({
			mutation: DismissMegaphoneMutation,
			variables: { input: { megaphoneID: megaphone._id } },
		});

		client.cache.updateQuery(
			{
				query: AuthQuery,
			},
			(data) =>
				data &&
				data.auth &&
				data.auth.settings && {
					...data,
					auth: {
						...data.auth,
						settings: {
							...data.auth.settings,
							megaphones: data.auth.settings.megaphones.filter(
								(m) => m._id !== megaphone._id,
							),
						},
					},
				},
		);

		// TODO: Change apollo cache to remove megaphone from auth user?
	});

	// Only logged in and home page
	if (!data?.auth || !isLoggedIn || router.asPath !== '/') {
		return null;
	}

	// Only if has megaphone and onboarding is closed
	if (!megaphone || isOnboardingOpen) {
		return null;
	}

	return (
		<SVModal.Visible
			Component={_Modal}
			onClose={handleClose}
			megaphone={megaphone}
			userName={data.auth.user.name}
			userUsername={data.auth.user.username}
		/>
	);
};

const SVMegaphoneModalContainer = SVWithNoSSR(React.memo(_SVMegaphoneModalContainer));

export default SVMegaphoneModalContainer;
