import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useCanSeeUpgradeWarning from '@apps/www/src/www/hooks/useCanSeeUpgradeWarning';
import useUpgradeWarning from '@apps/www/src/www/hooks/useUpgradeWarning';
import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import SVSubscriptionWarning from '@pkgs/shared-client/components/SVSubscriptionWarning';
import { isWithinNextDays } from '@pkgs/shared-client/helpers/format';
import NeedsUpgradeReason from '@pkgs/shared/enums/NeedsUpgradeReason';

const SubscriptionWarningContainer = ({ hideOnSmallViewport }: { hideOnSmallViewport?: boolean }) => {
	const upgradeWarning = useUpgradeWarning();
	const viewportName = useViewportName();
	const isSmall = viewportName === 'small' || viewportName === 'small-landscape';
	const showWarning: boolean = useCanSeeUpgradeWarning() && (!hideOnSmallViewport || isSmall);
	const authSettings = useAuthSettings(['subscriptions']);
	const hasRenewSubscription = Boolean(
		authSettings?.subscriptions?.find((subscription) => {
			const subscriptionEndDate = subscription.subscriptionEndDate
				? new Date(subscription.subscriptionEndDate)
				: undefined;
			return (
				subscription.status === 'active' &&
				!subscription.autoRenew &&
				subscription.canManage &&
				subscriptionEndDate &&
				isWithinNextDays(subscriptionEndDate, new Date(), 30)
			);
		}),
	);

	if (
		!showWarning ||
		(upgradeWarning?.reason !== NeedsUpgradeReason.RENEW && !hasRenewSubscription)
	) {
		return null;
	}

	return (
		<SVSubscriptionWarning
			use={
				upgradeWarning?.reason === NeedsUpgradeReason.RENEW
					? SVSubscriptionWarning.USES.CANCELLED
					: SVSubscriptionWarning.USES.EXPIRED
			}
			position={
				isSmall
					? SVSubscriptionWarning.POSITIONS.FIXED
					: SVSubscriptionWarning.POSITIONS.DEFAULT
			}
		/>
	);
};

export default SubscriptionWarningContainer;
