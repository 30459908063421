import { useQuery } from '@apollo/client';
import { type User, type UserQueryQuery } from '@apps/www/src/__generated__/graphql';
import AuthUserQuery from '@apps/www/src/www/queries/AuthUserQuery';
import UserQuery from '@apps/www/src/www/queries/UserQuery';
import { INVALID_USERNAME_REGEX } from '@pkgs/shared/constants';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import parseQueryParam from '@pkgs/shared/helpers/parseQueryParam';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useAuthUser from './useAuthUser';

// If `/[username]/` is authUser's username, grabs data from `AuthUserQuery` otherwise runs a regular `UserQuery`
const useUserQuery = (
	isOwner: boolean,
	username: User['username'] | undefined,
): { user: UserQueryQuery['userByUsername'] | null; loading: boolean; error: Error | null } => {
	const {
		data: authUserData,
		loading: authUserLoading,
		error: authUserError,
	} = useQuery(AuthUserQuery, { skip: !isOwner });

	const {
		data: userData,
		loading: userLoading,
		error: userError,
	} = useQuery(UserQuery, {
		variables: { username: username || '' },
		skip: isOwner || !username,
	});

	if (!isOwner && !username) {
		return { user: null, loading: false, error: null };
	}

	return {
		user: (isOwner ? authUserData?.auth?.user : userData?.userByUsername) || null,
		loading: isOwner ? authUserLoading : userLoading,
		error: (isOwner ? authUserError : userError) || null,
	};
};

// Grabs board from these routes:
// /[username]/boards/[boardSlug]/ <- only user boards
// /team/boards/[boardSlug]/ <- only team boards
const useBoardQuery = (
	user: UserQueryQuery['userByUsername'] | null,
): ArrayElement<UserQueryQuery['userByUsername']['boards']> | null => {
	const router = useRouter();
	const username = parseQueryParam(router.query.username);
	const boardOwnershipType = username ? BoardOwnershipType.USER : BoardOwnershipType.TEAM;
	const { data } = useQuery(AuthUserQuery);

	const boardsList = user ? user.boards : data?.auth?.user.boards;

	const boardSlug = (parseQueryParam(router.query.boardSlug) || '').toLowerCase();
	const board =
		(boardSlug &&
			boardsList &&
			boardsList.find(
				(board) => board.ownershipType === boardOwnershipType && board.slug == boardSlug,
			)) ||
		null;

	return board;
};

const useUserAndBoardPageQuery = (): {
	isOwner: boolean;
	user: UserQueryQuery['userByUsername'] | null;
	board: ArrayElement<UserQueryQuery['userByUsername']['boards']> | null;
	loading: boolean;
	error: Error | null;
} => {
	const router = useRouter();
	const username = parseQueryParam(router.query.username);

	useEffect(() => {
		if (router && username) {
			// If username has any invalid characters, clean it up and redirect
			const cleanedUsername = username.toLowerCase().replace(INVALID_USERNAME_REGEX, '');

			if (cleanedUsername.length > 0 && cleanedUsername !== username) {
				router.replace(
					{
						pathname: router.route,
						query: { ...router.query, username: cleanedUsername },
					},
					undefined,
					{ shallow: true },
				);
			}
		}
	}, [router, username]);

	const authUser = useAuthUser(['username']);
	const isOwner = Boolean(authUser && username === authUser.username);

	const { user, loading, error } = useUserQuery(isOwner, username);

	const board = useBoardQuery(user);

	return { isOwner, user, board, loading, error };
};

export default useUserAndBoardPageQuery;
