import colorWheel from '@pkgs/shared-client/img/site-maker/colorwheel.png';
import clsx from 'clsx';
import { forwardRef, LegacyRef, useEffect, useRef, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';

export const COLOR_PICKER_STYLES = {
	DEFAULT: 'default',
	SMALL: 'small',
};

const _DefaultColorPicker = forwardRef(
	({ togglePicker, color, handleColorChange, className }: any, colorBoxRef) => (
		<div
			className={clsx(
				'flex flex-row items-center rounded-xl border-0 py-2.5 px-4 ring-1 ring-gray-600 focus:ring-2 focus:ring-gray-600',
				'type-subtitle type-small font-semibold text-gray-500',
				className,
			)}
		>
			<div
				ref={colorBoxRef as LegacyRef<HTMLDivElement> | null}
				onClick={togglePicker}
				className="h-5 w-5 cursor-pointer border border-gray-500"
				style={{ backgroundColor: color }}
			/>
			<input
				className={clsx(
					'w-full flex-1 border-0 bg-transparent pl-2 placeholder-gray-700 focus:outline-none',
					'text-gray-500',
				)}
				value={color}
				maxLength={7}
				onChange={(event) => {
					handleColorChange(event.target.value);
				}}
			/>
			<div onClick={togglePicker}>
				<img src={colorWheel} alt="color wheel" className="h-8 w-8 cursor-pointer" />
			</div>
		</div>
	),
);

const _SmallColorPicker = forwardRef(({ togglePicker }: any, colorBoxRef) => (
	<div ref={colorBoxRef as LegacyRef<HTMLDivElement> | null} onClick={togglePicker}>
		<img src={colorWheel} alt="color wheel" className="h-6 w-6 max-w-none cursor-pointer" />
	</div>
));

export const SVColorPicker = ({
	value,
	onChange,
	onFocus,
	className,
	style,
}: {
	value: string;
	onChange: (color: string) => void;
	onFocus?: () => void;
	className?: string;
	style?: ValueOf<typeof COLOR_PICKER_STYLES>;
}) => {
	const colorPickRef = useRef<HTMLDivElement>(null);
	const colorBoxRef = useRef<HTMLDivElement>(null);
	const [color, setColor] = useState(value);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			event.stopPropagation();
			if (
				colorPickRef.current &&
				!colorPickRef.current.contains(event.target as Node) &&
				colorBoxRef.current &&
				!colorBoxRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};
		if (onFocus) onFocus();
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [colorPickRef, onFocus]);

	const togglePicker = () => {
		setIsOpen(!isOpen);
	};

	const handleColorChange = (color: string) => {
		setColor(color);
		onChange(color);
	};

	const handlePickerColorChange = (color: ColorResult) => {
		handleColorChange(color.hex);
	};

	return (
		<div className="relative">
			{style === COLOR_PICKER_STYLES.DEFAULT && (
				<_DefaultColorPicker
					color={color}
					togglePicker={togglePicker}
					className={className}
					ref={colorBoxRef}
					handleColorChange={handleColorChange}
				/>
			)}
			{style === COLOR_PICKER_STYLES.SMALL && (
				<_SmallColorPicker
					color={color}
					togglePicker={togglePicker}
					ref={colorBoxRef}
					handleColorChange={handleColorChange}
				/>
			)}
			{isOpen && (
				<div className="absolute top-12 z-10" ref={colorPickRef}>
					<ChromePicker
						color={color}
						onChange={handlePickerColorChange}
						disableAlpha={true}
					/>
				</div>
			)}
		</div>
	);
};
