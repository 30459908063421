import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import SVNewBoardModal from '@apps/www/src/www/modals/SVNewBoardModal';
import { openGridUpload } from '@apps/www/src/www/reducers/ui';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVModal from '@pkgs/shared-client/components/SVModal';
import IconAddSVG from '@pkgs/shared-client/img/icon-add-inlined.svg';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import TeamUserRole from '@pkgs/shared/enums/TeamUserRole';
import teamUserRoleHasTeamUserRolePrivileges from '@pkgs/shared/helpers/teamUserRoleHasTeamUserRolePrivileges';
import { ConnectedProps, connect } from 'react-redux';

const connector = connect(null, {
	openGridUpload,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const _SVGridAddButtonContainer = ({ openGridUpload }: Props) => {
	const team = useAuthTeam();

	const onUploadClick = useAllowedFeatureCheckEvent(AllowedFeature.ADD_ITEM, openGridUpload);

	const onNewBoardClick = useAllowedFeatureCheckEvent(AllowedFeature.ADD_BOARD, () =>
		SVModal.open(SVNewBoardModal, { ownershipType: BoardOwnershipType.USER }),
	);

	const onNewTeamBoardClick = useAllowedFeatureCheckEvent(AllowedFeature.ADD_BOARD, () =>
		SVModal.open(SVNewBoardModal, { ownershipType: BoardOwnershipType.TEAM }),
	);

	const hasTeamAdminPrivileges = teamUserRoleHasTeamUserRolePrivileges(
		team?.role,
		TeamUserRole.ADMIN,
	);

	return (
		<SVDropdown
			triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			renderTrigger={({ isOpen: _, ...props }) => (
				<SVIconButton src={IconAddSVG} title="Upload" {...props} />
			)}
			renderContent={() => (
				<SVDropdownContent.Links>
					<SVDropdownContent.Links.Item onClick={onUploadClick}>
						Upload
					</SVDropdownContent.Links.Item>
					<SVDropdownContent.Links.ItemLink to="/import/">
						Import
					</SVDropdownContent.Links.ItemLink>
					<SVDropdownContent.Links.Item onClick={onNewBoardClick}>
						New board
					</SVDropdownContent.Links.Item>
					{hasTeamAdminPrivileges ? (
						<SVDropdownContent.Links.Item onClick={onNewTeamBoardClick}>
							New team board
						</SVDropdownContent.Links.Item>
					) : null}
				</SVDropdownContent.Links>
			)}
		/>
	);
};

const SVGridAddButtonContainer = connector(_SVGridAddButtonContainer);

export default SVGridAddButtonContainer;
