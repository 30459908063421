import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { ChangeEvent, useState } from 'react';

export const INPUT_SLIDER_STYLES = {
	DEFAULT: 'svslider-default',
	SITE_MAKER: 'svslider-site-maker',
};

type Props = {
	value: number;
	min: number;
	max: number;
	step?: number;
	isInverted?: boolean;
	onChange: (value: number) => void;
	style?: ValueOf<typeof INPUT_SLIDER_STYLES>;
};

const SVInputSlider = ({
	value: valueProp,
	style,
	min,
	max,
	step,
	isInverted,
	onChange,
}: Props) => {
	const [value, setValue] = useState(valueProp);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = parseFloat(event.target.value);
		setValue(value);
		handleChangeDebounced(value);
	};

	const handleChangeDebounced = debounce(onChange, 150);

	return (
		<div
			className={clsx(
				'relative',
				style && style === INPUT_SLIDER_STYLES.SITE_MAKER
					? 'flex w-[80px]'
					: 'min-w-[80px]',
			)}
		>
			<input
				className={clsx(
					'peer',
					style ? style : INPUT_SLIDER_STYLES.DEFAULT,
					isInverted && '[direction:rtl]',
				)}
				type="range"
				min={min}
				max={max}
				step={step}
				value={value}
				onChange={(event) => {
					handleChange(event);
				}}
			/>
			{style === INPUT_SLIDER_STYLES.SITE_MAKER && (
				<div
					className="absolute top-[-25px] z-50 ml-[-24px] hidden w-[48px] rounded bg-white px-2 text-center text-xs text-black shadow-md peer-hover:block"
					style={{ left: `${70 * ((value - min) / (max - min)) + 5}px` }}
				>
					{Number(value || 0).toFixed(0)}
				</div>
			)}
		</div>
	);
};

export default SVInputSlider;
