/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	DateTime: Date;
	JSON: any;
	JSONObject: any;
	Long: number;
	NonEmptyString: string;
	Upload: File;
};

export type AddItemsToBoardInput = {
	boardID: Scalars['ID'];
	itemIDs: Array<Scalars['ID']>;
};

export type AddItemsToBoardResponse = {
	__typename?: 'AddItemsToBoardResponse';
	assets: Array<Asset>;
	auth: Auth;
	board: Board;
};

export type Asset = {
	__typename?: 'Asset';
	_id: Scalars['ID'];
	colors: Array<AssetColor>;
	comments: Array<Comment>;
	hasRelated: Scalars['Boolean'];
	image: AssetImage;
	isSaved: Scalars['Boolean'];
	ogImage?: Maybe<OgImage>;
	ownBoards: Array<Board>;
	savedCount: Scalars['Int'];
	/** @deprecated Not necessary anymore. */
	subtype?: Maybe<Scalars['String']>;
	tags: Array<Tag>;
	type: AssetType;
	users: Array<User>;
	viewsCount: Scalars['Int'];
};

export type AssetColor = {
	__typename?: 'AssetColor';
	amount: Scalars['Float'];
	color: Scalars['String'];
};

export type AssetImage = {
	__typename?: 'AssetImage';
	height: Scalars['Float'];
	original: Scalars['NonEmptyString'];
	ratio: Scalars['Float'];
	thumbnail: Scalars['NonEmptyString'];
	width: Scalars['Float'];
};

export type AssetType = 'image' | 'video';

export type Auth = {
	__typename?: 'Auth';
	_id: Scalars['ID'];
	settings: AuthSettings;
	team?: Maybe<Team>;
	token: Scalars['NonEmptyString'];
	user: User;
};

export type AuthSettings = {
	__typename?: 'AuthSettings';
	_id: Scalars['ID'];
	autoPlayGIFs: Scalars['Boolean'];
	darkMode: Scalars['Boolean'];
	dropboxLinked: Scalars['Boolean'];
	email: Scalars['NonEmptyString'];
	emailNewsletter: Scalars['Boolean'];
	emailNotifications: Scalars['Boolean'];
	fcmPushNotificationToken?: Maybe<Scalars['String']>;
	featureFlags: Array<Scalars['NonEmptyString']>;
	feedBannerExpanded: Scalars['Boolean'];
	fitToScreen: Scalars['Boolean'];
	gridColumnsRatio: Scalars['Float'];
	gridSpacingRatio: Scalars['Float'];
	isBeta: Scalars['Boolean'];
	isModerator: Scalars['Boolean'];
	itemsSortMethod?: Maybe<ItemsSortMethod>;
	maxBoardCollaborators: Scalars['Int'];
	maxTeamUsers: Scalars['Int'];
	megaphones: Array<Megaphone>;
	nativePushNotificationToken?: Maybe<Scalars['String']>;
	needsUpgradeReason?: Maybe<NeedsUpgradeReason>;
	onboard: Scalars['Boolean'];
	pinterestUsername?: Maybe<Scalars['String']>;
	pushNotifications: Scalars['Boolean'];
	subscriptions: Array<UserSubscription>;
	unreadNotificationsCount: Scalars['Int'];
	workEmail?: Maybe<Scalars['String']>;
};

export type BecomeProInput = {
	cardToken: Scalars['NonEmptyString'];
	coupon?: InputMaybe<Scalars['String']>;
	plan: Scalars['NonEmptyString'];
};

export type BillingCycle = 'monthly' | 'yearly';

export type BillingPayment = {
	__typename?: 'BillingPayment';
	amount: Scalars['Float'];
	cardBrandID?: Maybe<CardBrand>;
	cardLast4?: Maybe<Scalars['String']>;
	chargeID: Scalars['String'];
	date: Scalars['DateTime'];
	provider: PaymentProvider;
	refunds: Array<BillingRefund>;
	status: PaymentStatus;
};

export type BillingRefund = {
	__typename?: 'BillingRefund';
	amount: Scalars['Float'];
};

export type Board = {
	__typename?: 'Board';
	_id: Scalars['ID'];
	acceptURL?: Maybe<Scalars['String']>;
	collaborators: Array<BoardCollaborator>;
	invites: Array<Invite>;
	isPrivate: Scalars['Boolean'];
	isTeamEditorAnEditor?: Maybe<Scalars['Boolean']>;
	items: PaginatedResultItems;
	itemsCount: Scalars['Int'];
	lastItemAddedOrder?: Maybe<Scalars['Long']>;
	name: Scalars['String'];
	ogImage: OgImage;
	ownershipType: BoardOwnershipType;
	publicURL?: Maybe<Scalars['String']>;
	rejectURL?: Maybe<Scalars['String']>;
	role?: Maybe<BoardUserRole>;
	slug: Scalars['String'];
	/** @deprecated Use thumbnails. */
	thumbnailURL?: Maybe<Scalars['String']>;
	thumbnails: Array<Asset>;
	url: Scalars['NonEmptyString'];
};

export type BoardItemsArgs = {
	cursor?: InputMaybe<Scalars['String']>;
	limit?: InputMaybe<Scalars['Int']>;
	sortMethod?: InputMaybe<ItemsSortMethod>;
};

export type BoardCollaborator = {
	__typename?: 'BoardCollaborator';
	_id: Scalars['ID'];
	/** @deprecated Use invites. */
	invitationStatus?: Maybe<Scalars['String']>;
	role: BoardUserRole;
	user: User;
};

export type BoardOwnershipType = 'team' | 'user';

export type BoardUserRole = 'admin' | 'editor' | 'owner' | 'viewer';

export type CardBrand = 'amex' | 'discover' | 'maestro' | 'mastercard' | 'other' | 'visa';

export type CheckPaymentResponse = {
	__typename?: 'CheckPaymentResponse';
	amount?: Maybe<Scalars['Float']>;
	confirmed: Scalars['Boolean'];
};

export type CheckResetPasswordResponse = {
	__typename?: 'CheckResetPasswordResponse';
	firstName?: Maybe<Scalars['String']>;
	resetPasswordToken: Scalars['NonEmptyString'];
};

export type Comment = {
	__typename?: 'Comment';
	_id: Scalars['ID'];
	content: Scalars['String'];
	createdAt: Scalars['DateTime'];
	isLiked: Scalars['Boolean'];
	isTeamOnly: Scalars['Boolean'];
	likesCount: Scalars['Int'];
	parent?: Maybe<Comment>;
	position?: Maybe<Position>;
	user: User;
	userMentions?: Maybe<Array<UserMention>>;
};

export type Company = {
	__typename?: 'Company';
	_id: Scalars['ID'];
	name: Scalars['String'];
	url: Scalars['NonEmptyString'];
	users: PaginatedResultUsers;
};

export type CompanyUsersArgs = {
	cursor?: InputMaybe<Scalars['String']>;
};

export type DeleteBoardInput = {
	boardID: Scalars['ID'];
	keepItems: Scalars['Boolean'];
};

export type DeleteBoardResponse = {
	__typename?: 'DeleteBoardResponse';
	assets: Array<Asset>;
	auth: Auth;
	deletedItemIDs: Array<Scalars['ID']>;
};

export type DeleteCommentInput = {
	commentID: Scalars['ID'];
};

export type DeleteCommentResponse = {
	__typename?: 'DeleteCommentResponse';
	success: Scalars['Boolean'];
};

export type DeleteItemsInput = {
	boardID?: InputMaybe<Scalars['ID']>;
	itemIDs: Array<Scalars['ID']>;
};

export type DeleteItemsResponse = {
	__typename?: 'DeleteItemsResponse';
	assets: Array<Asset>;
	auth: Auth;
	deletedItemIDs: Array<Scalars['ID']>;
};

export type DeleteTeamResponse = {
	__typename?: 'DeleteTeamResponse';
	auth: Auth;
};

export type DeleteUserInput = {
	password: Scalars['NonEmptyString'];
};

export type DirectoryUser = {
	__typename?: 'DirectoryUser';
	_id: Scalars['ID'];
	name?: Maybe<Scalars['String']>;
	url: Scalars['NonEmptyString'];
	username: Scalars['NonEmptyString'];
};

export type DismissMegaphoneInput = {
	megaphoneID: Scalars['ID'];
};

export type DomainVerificationStatus = 'checking' | 'initial' | 'verified';

export type ForgotPasswordInput = {
	email: Scalars['NonEmptyString'];
};

export type ImportGroup = {
	__typename?: 'ImportGroup';
	count: Scalars['Int'];
	name: Scalars['String'];
	private: Scalars['Boolean'];
	selected: Scalars['Boolean'];
	sourceID: Scalars['String'];
	thumbnailURL?: Maybe<Scalars['String']>;
};

export type ImportItem = {
	__typename?: 'ImportItem';
	boardID: Scalars['ID'];
	date?: Maybe<Scalars['DateTime']>;
	description?: Maybe<Scalars['String']>;
	groupID: Scalars['String'];
	pageURL?: Maybe<Scalars['String']>;
	private: Scalars['Boolean'];
	sourceID: Scalars['String'];
	title?: Maybe<Scalars['String']>;
	type: Scalars['NonEmptyString'];
	url: Scalars['NonEmptyString'];
};

export type ImportItemInput = {
	boardID: Scalars['ID'];
	date?: InputMaybe<Scalars['DateTime']>;
	description?: InputMaybe<Scalars['String']>;
	groupID: Scalars['String'];
	pageURL?: InputMaybe<Scalars['String']>;
	private: Scalars['Boolean'];
	sourceID: Scalars['String'];
	title?: InputMaybe<Scalars['String']>;
	type: Scalars['NonEmptyString'];
	url: Scalars['NonEmptyString'];
};

export type ImportPinterestCheckUsernameInput = {
	username: Scalars['String'];
};

export type ImportProcessGroupInput = {
	isPrivate: Scalars['Boolean'];
	name: Scalars['NonEmptyString'];
	provider: ImportProvider;
	sourceID: Scalars['String'];
};

export type ImportProcessGroupResponse = {
	__typename?: 'ImportProcessGroupResponse';
	items: Array<ImportItem>;
};

export type ImportProcessItemInput = {
	item: ImportItemInput;
	provider: ImportProvider;
};

export type ImportProvider = 'instagram' | 'pinterest' | 'tumblr';

export type ImportStartResponse = {
	__typename?: 'ImportStartResponse';
	count: Scalars['Int'];
	groups: Array<ImportGroup>;
};

export type InteractWithNotificationInput = {
	notificationID: Scalars['ID'];
};

export type InternalLoginAsInput = {
	id: Scalars['ID'];
	secret: Scalars['NonEmptyString'];
};

export type InternalLoginFromScriptsInput = {
	secret: Scalars['NonEmptyString'];
	username: Scalars['NonEmptyString'];
};

export type InternalLoginFromTestsInput = {
	username: Scalars['NonEmptyString'];
};

export type Invite = {
	__typename?: 'Invite';
	_id: Scalars['ID'];
	email?: Maybe<Scalars['NonEmptyString']>;
	user?: Maybe<User>;
};

export type InviteType = 'board' | 'team';

export type InviteUsersToBoardInput = {
	boardID: Scalars['ID'];
	emails?: InputMaybe<Array<Scalars['NonEmptyString']>>;
	userIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type InviteUsersToTeamInput = {
	emails?: InputMaybe<Array<Scalars['NonEmptyString']>>;
	userIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type Item = {
	__typename?: 'Item';
	_id: Scalars['ID'];
	asset: Asset;
	createdAt?: Maybe<Scalars['DateTime']>;
	isOwner: Scalars['Boolean'];
	isPrivate: Scalars['Boolean'];
	name?: Maybe<Scalars['String']>;
	pageURL?: Maybe<Scalars['String']>;
	shortID: Scalars['NonEmptyString'];
	sourceDisplayURL?: Maybe<Scalars['String']>;
	sourceURL?: Maybe<Scalars['String']>;
	url: Scalars['NonEmptyString'];
	user: User;
};

export type ItemsSortMethod = 'custom' | 'newest_first' | 'oldest_first';

export type JoinInput = {
	email: Scalars['NonEmptyString'];
	name?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	pictureURL?: InputMaybe<Scalars['String']>;
	provider?: InputMaybe<Scalars['String']>;
	providerID?: InputMaybe<Scalars['String']>;
	username: Scalars['NonEmptyString'];
};

export type Landing = {
	__typename?: 'Landing';
	items: Array<Item>;
};

export type List = {
	__typename?: 'List';
	items: PaginatedResultItems;
	url: Scalars['NonEmptyString'];
};

export type ListItemsArgs = {
	cursor?: InputMaybe<Scalars['String']>;
	limit?: InputMaybe<Scalars['Int']>;
};

export type LoginFromTokenInput = {
	token: Scalars['NonEmptyString'];
};

export type LoginInput = {
	password: Scalars['NonEmptyString'];
	usernameOrEmail: Scalars['NonEmptyString'];
};

export type LoginSocialInput = {
	accessToken?: InputMaybe<Scalars['String']>;
	email?: InputMaybe<Scalars['String']>;
	id: Scalars['NonEmptyString'];
	name?: InputMaybe<Scalars['String']>;
	pictureURL?: InputMaybe<Scalars['String']>;
	provider: SocialLoginProvider;
	username?: InputMaybe<Scalars['String']>;
};

export type LoginSocialPartialInput = {
	__typename?: 'LoginSocialPartialInput';
	accessToken?: Maybe<Scalars['String']>;
	email?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	pictureURL?: Maybe<Scalars['String']>;
	provider: SocialLoginProvider;
	username?: Maybe<Scalars['String']>;
};

export type LoginSocialResponse = {
	__typename?: 'LoginSocialResponse';
	auth?: Maybe<Auth>;
	needsCompletion: Scalars['Boolean'];
	partialInput?: Maybe<LoginSocialPartialInput>;
};

export type Megaphone = {
	__typename?: 'Megaphone';
	_id: Scalars['ID'];
	body: Scalars['String'];
	ctaLabel?: Maybe<Scalars['String']>;
	title: Scalars['String'];
};

export type MentionsType = 'public' | 'team';

export type Mutation = {
	__typename?: 'Mutation';
	addItemsToBoard: AddItemsToBoardResponse;
	checkSiteDomain: Site;
	clearUnreadNotificationsCount: Auth;
	deleteBoard: DeleteBoardResponse;
	deleteComment: DeleteCommentResponse;
	deleteItems: DeleteItemsResponse;
	deleteSiteDomain: Site;
	deleteTeam: DeleteTeamResponse;
	deleteUser: Scalars['Boolean'];
	dismissMegaphone: Auth;
	forgotPassword: Scalars['Boolean'];
	importPinterestCheckUsername: Auth;
	importProcessGroup: ImportProcessGroupResponse;
	importProcessItem: Scalars['Boolean'];
	interactWithNotification: Auth;
	internalLoginAs: Auth;
	internalLoginFromScripts: Auth;
	internalLoginFromTests: Auth;
	inviteUsersToBoard: Board;
	inviteUsersToTeam: Team;
	join: Auth;
	login: Auth;
	loginFromToken: Auth;
	loginSocial: LoginSocialResponse;
	logout: Scalars['Boolean'];
	newBoard: NewBoardResponse;
	newComment: NewCommentResponse;
	newList: List;
	newSiteDomain: Site;
	newTeam: NewTeamResponse;
	regenerateBoardPublicURL: Board;
	removeItemsFromBoard: AddItemsToBoardResponse;
	removeUserFromBoard: Board;
	removeUserFromTeam: Team;
	reorderItems: Scalars['Boolean'];
	reportItems: ReportItemsResponse;
	resetPassword: Auth;
	saveItems: SaveItemsResponse;
	selectSiteTemplate: Site;
	startStripeCheckout: StartStripeCheckoutResponse;
	startStripeUpdateBillingMethod: StartStripeCheckoutResponse;
	toggleFollowUser: ToggleFollowUserResponse;
	toggleLikeComment: ToggleLikeCommentResponse;
	trackUpgradeClick: Scalars['Boolean'];
	unlinkDropbox: Auth;
	updateAuthSettings: Auth;
	updateAutoFollowTeamOwners: Team;
	updateAvatar: Auth;
	updateBillingAutoRenew: Auth;
	updateBillingPlan: UpdateBillingPlanResponse;
	updateBoardName: Board;
	updateBoardPrivacy: UpdateBoardPrivacyResponse;
	updateBoardUserRole: Board;
	updateCover: Auth;
	updateItem: Item;
	updateItemsPrivacy: UpdateItemsPrivacyResponse;
	updateNotifyTeamUsersOnComment: Team;
	updateProfile: Auth;
	updatePushNotifications: Auth;
	updatePushNotificationsToken: Auth;
	updateSite: Site;
	updateTeam: Team;
	updateTeamFeedAsHomepage: Team;
	updateTeamFeedBoard: Team;
	updateTeamUserRole: TeamUser;
	updateUsername: UpdateUsernameResponse;
	uploadItem: UploadItemResponse;
	uploadItemFromPageURL: UploadItemFromPageUrlResponse;
	uploadItemFromRemote: UploadItemFromRemoteResponse;
};

export type MutationAddItemsToBoardArgs = {
	input: AddItemsToBoardInput;
};

export type MutationCheckSiteDomainArgs = {
	input: SiteDomainInput;
};

export type MutationDeleteBoardArgs = {
	input: DeleteBoardInput;
};

export type MutationDeleteCommentArgs = {
	input: DeleteCommentInput;
};

export type MutationDeleteItemsArgs = {
	input: DeleteItemsInput;
};

export type MutationDeleteSiteDomainArgs = {
	input: SiteDomainInput;
};

export type MutationDeleteUserArgs = {
	input: DeleteUserInput;
};

export type MutationDismissMegaphoneArgs = {
	input: DismissMegaphoneInput;
};

export type MutationForgotPasswordArgs = {
	input: ForgotPasswordInput;
};

export type MutationImportPinterestCheckUsernameArgs = {
	input: ImportPinterestCheckUsernameInput;
};

export type MutationImportProcessGroupArgs = {
	input: ImportProcessGroupInput;
};

export type MutationImportProcessItemArgs = {
	input: ImportProcessItemInput;
};

export type MutationInteractWithNotificationArgs = {
	input: InteractWithNotificationInput;
};

export type MutationInternalLoginAsArgs = {
	input: InternalLoginAsInput;
};

export type MutationInternalLoginFromScriptsArgs = {
	input: InternalLoginFromScriptsInput;
};

export type MutationInternalLoginFromTestsArgs = {
	input: InternalLoginFromTestsInput;
};

export type MutationInviteUsersToBoardArgs = {
	input: InviteUsersToBoardInput;
};

export type MutationInviteUsersToTeamArgs = {
	input: InviteUsersToTeamInput;
};

export type MutationJoinArgs = {
	input: JoinInput;
};

export type MutationLoginArgs = {
	input: LoginInput;
};

export type MutationLoginFromTokenArgs = {
	input: LoginFromTokenInput;
};

export type MutationLoginSocialArgs = {
	input: LoginSocialInput;
};

export type MutationNewBoardArgs = {
	input: NewBoardInput;
};

export type MutationNewCommentArgs = {
	input: NewCommentInput;
};

export type MutationNewListArgs = {
	input: NewListInput;
};

export type MutationNewSiteDomainArgs = {
	input: SiteDomainInput;
};

export type MutationNewTeamArgs = {
	input: NewTeamInput;
};

export type MutationRegenerateBoardPublicUrlArgs = {
	input: RegenerateBoardPublicUrlInput;
};

export type MutationRemoveItemsFromBoardArgs = {
	input: RemoveItemsFromBoardInput;
};

export type MutationRemoveUserFromBoardArgs = {
	input: RemoveUserFromBoardInput;
};

export type MutationRemoveUserFromTeamArgs = {
	input: RemoveUserFromTeamInput;
};

export type MutationReorderItemsArgs = {
	input: ReorderItemsInput;
};

export type MutationReportItemsArgs = {
	input: ReportItemsInput;
};

export type MutationResetPasswordArgs = {
	input: ResetPasswordInput;
};

export type MutationSaveItemsArgs = {
	input: SaveItemsInput;
};

export type MutationSelectSiteTemplateArgs = {
	input: SelectSiteTemplateInput;
};

export type MutationStartStripeCheckoutArgs = {
	input: StartStripeCheckoutInput;
};

export type MutationStartStripeUpdateBillingMethodArgs = {
	input: StartStripeUpdateBillingMethodInput;
};

export type MutationToggleFollowUserArgs = {
	input: ToggleFollowUserInput;
};

export type MutationToggleLikeCommentArgs = {
	input: ToggleLikeCommentInput;
};

export type MutationTrackUpgradeClickArgs = {
	input: TrackUpgradeClickInput;
};

export type MutationUpdateAuthSettingsArgs = {
	input: UpdateAuthSettingsInput;
};

export type MutationUpdateAutoFollowTeamOwnersArgs = {
	input: Scalars['Boolean'];
};

export type MutationUpdateAvatarArgs = {
	input: UpdateAvatarInput;
};

export type MutationUpdateBillingAutoRenewArgs = {
	input: UpdateBillingAutoRenewInput;
};

export type MutationUpdateBillingPlanArgs = {
	input: UpdateBillingPlanInput;
};

export type MutationUpdateBoardNameArgs = {
	input: UpdateBoardNameInput;
};

export type MutationUpdateBoardPrivacyArgs = {
	input: UpdateBoardPrivacyInput;
};

export type MutationUpdateBoardUserRoleArgs = {
	input: UpdateBoardUserRoleInput;
};

export type MutationUpdateCoverArgs = {
	input: UpdateCoverInput;
};

export type MutationUpdateItemArgs = {
	input: UpdateItemInput;
};

export type MutationUpdateItemsPrivacyArgs = {
	input: UpdateItemsPrivacyInput;
};

export type MutationUpdateNotifyTeamUsersOnCommentArgs = {
	input: Scalars['Boolean'];
};

export type MutationUpdateProfileArgs = {
	input: UpdateProfileInput;
};

export type MutationUpdatePushNotificationsArgs = {
	input: UpdatePushNotificationsInput;
};

export type MutationUpdatePushNotificationsTokenArgs = {
	input: UpdatePushNotificationsTokenInput;
};

export type MutationUpdateSiteArgs = {
	input: UpdateSiteInput;
};

export type MutationUpdateTeamArgs = {
	input: UpdateTeamInput;
};

export type MutationUpdateTeamFeedAsHomepageArgs = {
	input: Scalars['Boolean'];
};

export type MutationUpdateTeamFeedBoardArgs = {
	input: UpdateTeamFeedBoardInput;
};

export type MutationUpdateTeamUserRoleArgs = {
	input: UpdateTeamUserRoleInput;
};

export type MutationUpdateUsernameArgs = {
	input: UpdateUsernameInput;
};

export type MutationUploadItemArgs = {
	input: UploadItemInput;
};

export type MutationUploadItemFromPageUrlArgs = {
	input: UploadItemFromPageUrlInput;
};

export type MutationUploadItemFromRemoteArgs = {
	input: UploadItemFromRemoteInput;
};

export type NeedsUpgradeReason = 'max_free_boards_reached' | 'max_free_items_reached' | 'renew';

export type NewBoardInput = {
	isPrivate: Scalars['Boolean'];
	isTeamEditorAnEditor?: InputMaybe<Scalars['Boolean']>;
	itemIDs?: InputMaybe<Array<Scalars['ID']>>;
	name: Scalars['NonEmptyString'];
	ownershipType: BoardOwnershipType;
};

export type NewBoardResponse = {
	__typename?: 'NewBoardResponse';
	auth: Auth;
	board: Board;
	items?: Maybe<Array<Item>>;
};

export type NewCommentInput = {
	assetID: Scalars['ID'];
	content: Scalars['NonEmptyString'];
	isTeamOnly: Scalars['Boolean'];
	parentID?: InputMaybe<Scalars['ID']>;
	position?: InputMaybe<PositionInput>;
};

export type NewCommentResponse = {
	__typename?: 'NewCommentResponse';
	asset: Asset;
};

export type NewListInput = {
	itemIDs: Array<Scalars['ID']>;
};

export type NewTeamInput = {
	email: Scalars['NonEmptyString'];
	name: Scalars['NonEmptyString'];
};

export type NewTeamResponse = {
	__typename?: 'NewTeamResponse';
	auth: Auth;
	team: Team;
};

export type Notification = {
	__typename?: 'Notification';
	_id: Scalars['ID'];
	createdAt: Scalars['DateTime'];
	relatedBoard?: Maybe<Board>;
	relatedComment?: Maybe<Comment>;
	relatedItem?: Maybe<Item>;
	relatedTeam?: Maybe<Team>;
	relatedUser?: Maybe<User>;
	type: Scalars['NonEmptyString'];
};

export type OgImage = {
	__typename?: 'OGImage';
	height: Scalars['Float'];
	url: Scalars['NonEmptyString'];
	width: Scalars['Float'];
};

export type PaginatedResultCompanies = {
	__typename?: 'PaginatedResultCompanies';
	items: Array<Company>;
	pageInfo: PaginatedResultPageInfo;
};

export type PaginatedResultItems = {
	__typename?: 'PaginatedResultItems';
	items: Array<Item>;
	pageInfo: PaginatedResultPageInfo;
};

export type PaginatedResultPageInfo = {
	__typename?: 'PaginatedResultPageInfo';
	nextCursor?: Maybe<Scalars['String']>;
};

export type PaginatedResultUsers = {
	__typename?: 'PaginatedResultUsers';
	items: Array<User>;
	pageInfo: PaginatedResultPageInfo;
};

export type PaymentProvider = 'paypal' | 'stripe';

export type PaymentStatus = 'failed' | 'pending' | 'succeeded';

export type Position = {
	__typename?: 'Position';
	x: Scalars['Float'];
	y: Scalars['Float'];
};

export type PositionInput = {
	x: Scalars['Float'];
	y: Scalars['Float'];
};

export type Query = {
	__typename?: 'Query';
	auth?: Maybe<Auth>;
	billingPayments: Array<BillingPayment>;
	boardByID: Board;
	boardByPrivateKey: Board;
	checkPayment: CheckPaymentResponse;
	checkResetPasswordToken: CheckResetPasswordResponse;
	companies: PaginatedResultCompanies;
	companyBySlug: Company;
	directoryUsers: Array<DirectoryUser>;
	feedItems: PaginatedResultItems;
	importStart: ImportStartResponse;
	internalErrorTest: Auth;
	itemByShortID: Item;
	landing: Landing;
	listByShortID: List;
	notifications: Array<Notification>;
	popularItems: PaginatedResultItems;
	relatedItems: PaginatedResultItems;
	searchItems: PaginatedResultItems;
	searchUsers: PaginatedResultUsers;
	sideItem?: Maybe<Item>;
	site?: Maybe<Site>;
	suggestedInvitedUsers: Array<User>;
	suggestedMentionedUsers: Array<User>;
	suggestedUsers: Array<User>;
	topSearchQueries: Array<TopSearchQuery>;
	upgradeStats: UpgradeStatsResponse;
	userByUsername: User;
	userCheck: Scalars['Boolean'];
	userFollowers: PaginatedResultUsers;
	userFollowing: PaginatedResultUsers;
};

export type QueryBillingPaymentsArgs = {
	type: SubscriptionType;
};

export type QueryBoardByIdArgs = {
	boardID: Scalars['ID'];
};

export type QueryBoardByPrivateKeyArgs = {
	privateKey: Scalars['NonEmptyString'];
	userID: Scalars['ID'];
};

export type QueryCheckPaymentArgs = {
	type: SubscriptionType;
};

export type QueryCheckResetPasswordTokenArgs = {
	resetPasswordToken: Scalars['NonEmptyString'];
};

export type QueryCompaniesArgs = {
	cursor?: InputMaybe<Scalars['String']>;
};

export type QueryCompanyBySlugArgs = {
	slug: Scalars['String'];
};

export type QueryDirectoryUsersArgs = {
	firstLetter: Scalars['NonEmptyString'];
};

export type QueryFeedItemsArgs = {
	cursor?: InputMaybe<Scalars['String']>;
};

export type QueryImportStartArgs = {
	provider: ImportProvider;
};

export type QueryItemByShortIdArgs = {
	shortID: Scalars['NonEmptyString'];
};

export type QueryListByShortIdArgs = {
	shortID: Scalars['NonEmptyString'];
};

export type QueryNotificationsArgs = {
	limit?: InputMaybe<Scalars['Int']>;
};

export type QueryPopularItemsArgs = {
	cursor?: InputMaybe<Scalars['String']>;
};

export type QueryRelatedItemsArgs = {
	cursor?: InputMaybe<Scalars['String']>;
	shortID: Scalars['NonEmptyString'];
};

export type QuerySearchItemsArgs = {
	cursor?: InputMaybe<Scalars['String']>;
	query: Scalars['NonEmptyString'];
};

export type QuerySearchUsersArgs = {
	cursor?: InputMaybe<Scalars['String']>;
	limit?: InputMaybe<Scalars['Int']>;
	query: Scalars['NonEmptyString'];
};

export type QuerySuggestedInvitedUsersArgs = {
	query?: InputMaybe<Scalars['String']>;
	resourceID?: InputMaybe<Scalars['ID']>;
	type: InviteType;
};

export type QuerySuggestedMentionedUsersArgs = {
	query?: InputMaybe<Scalars['String']>;
	type?: InputMaybe<MentionsType>;
};

export type QueryUserByUsernameArgs = {
	username: Scalars['NonEmptyString'];
};

export type QueryUserCheckArgs = {
	usernameOrEmail: Scalars['NonEmptyString'];
};

export type QueryUserFollowersArgs = {
	cursor?: InputMaybe<Scalars['String']>;
	userID: Scalars['ID'];
};

export type QueryUserFollowingArgs = {
	cursor?: InputMaybe<Scalars['String']>;
	userID: Scalars['ID'];
};

export type RegenerateBoardPublicUrlInput = {
	boardID: Scalars['ID'];
};

export type RemoveItemsFromBoardInput = {
	boardID: Scalars['ID'];
	itemIDs: Array<Scalars['ID']>;
};

export type RemoveUserFromBoardInput = {
	boardID: Scalars['ID'];
	email?: InputMaybe<Scalars['NonEmptyString']>;
	userID?: InputMaybe<Scalars['ID']>;
};

export type RemoveUserFromTeamInput = {
	email?: InputMaybe<Scalars['NonEmptyString']>;
	userID?: InputMaybe<Scalars['ID']>;
};

export type ReorderItemsInput = {
	boardID?: InputMaybe<Scalars['ID']>;
	fromItemID: Scalars['ID'];
	toItemID: Scalars['ID'];
};

export type ReportItemsInput = {
	itemIDs: Array<Scalars['ID']>;
};

export type ReportItemsResponse = {
	__typename?: 'ReportItemsResponse';
	reportedItemIDs: Array<Scalars['ID']>;
};

export type ResetPasswordInput = {
	password: Scalars['NonEmptyString'];
	resetPasswordToken: Scalars['NonEmptyString'];
};

export type SaveItemsInput = {
	boardID?: InputMaybe<Scalars['ID']>;
	itemIDs: Array<Scalars['ID']>;
};

export type SaveItemsResponse = {
	__typename?: 'SaveItemsResponse';
	assets: Array<Asset>;
	auth: Auth;
	board?: Maybe<Board>;
};

export type SelectSiteTemplateInput = {
	template: SiteTemplate;
};

export type Site = {
	__typename?: 'Site';
	_id: Scalars['ID'];
	backgroundColor?: Maybe<Scalars['String']>;
	content1?: Maybe<Scalars['String']>;
	content2?: Maybe<Scalars['String']>;
	domains?: Maybe<Array<SiteDomain>>;
	fontFamily?: Maybe<Scalars['String']>;
	settings?: Maybe<Scalars['JSON']>;
	sourceID?: Maybe<Scalars['ID']>;
	template: SiteTemplate;
	templateData?: Maybe<Scalars['JSON']>;
	textColor?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	url: Scalars['String'];
};

export type SiteDomain = {
	__typename?: 'SiteDomain';
	domain: Scalars['String'];
	status: DomainVerificationStatus;
};

export type SiteDomainInput = {
	domain: Scalars['String'];
};

export type SiteTemplate = 'grid' | 'horizontal_grid' | 'just_text' | 'one_page';

export type SocialLoginProvider = 'apple' | 'facebook' | 'google' | 'twitter';

export type StartStripeCheckoutInput = {
	planID?: InputMaybe<Scalars['String']>;
	type: SubscriptionType;
};

export type StartStripeCheckoutResponse = {
	__typename?: 'StartStripeCheckoutResponse';
	sessionID: Scalars['String'];
	url: Scalars['String'];
};

export type StartStripeUpdateBillingMethodInput = {
	type: SubscriptionType;
};

export type SubscriptionStatus = 'active' | 'expired' | 'initial';

export type SubscriptionType = 'individual' | 'team';

export type Tag = {
	__typename?: 'Tag';
	_id: Scalars['ID'];
	name: Scalars['String'];
};

export type Team = {
	__typename?: 'Team';
	_id: Scalars['ID'];
	acceptURL?: Maybe<Scalars['String']>;
	autoFollowTeamOwners: Scalars['Boolean'];
	email: Scalars['NonEmptyString'];
	feedBoardID?: Maybe<Scalars['ID']>;
	feedItems: PaginatedResultItems;
	invites: Array<Invite>;
	name: Scalars['String'];
	notifyUsersOnComment: Scalars['Boolean'];
	rejectURL?: Maybe<Scalars['String']>;
	role: TeamUserRole;
	teamFeedAsHomepage: Scalars['Boolean'];
	users: Array<TeamUser>;
	usersCount: Scalars['Int'];
};

export type TeamFeedItemsArgs = {
	cursor?: InputMaybe<Scalars['String']>;
};

export type TeamUser = {
	__typename?: 'TeamUser';
	_id: Scalars['ID'];
	email: Scalars['NonEmptyString'];
	lastActivityAt: Scalars['DateTime'];
	role: TeamUserRole;
	user: User;
	workEmail?: Maybe<Scalars['String']>;
};

export type TeamUserRole = 'admin' | 'editor' | 'owner';

export type ToggleFollowUserInput = {
	userID: Scalars['ID'];
};

export type ToggleFollowUserResponse = {
	__typename?: 'ToggleFollowUserResponse';
	auth: Auth;
	user: User;
};

export type ToggleLikeCommentInput = {
	commentID: Scalars['ID'];
};

export type ToggleLikeCommentResponse = {
	__typename?: 'ToggleLikeCommentResponse';
	comment: Comment;
};

export type TopSearchQuery = {
	__typename?: 'TopSearchQuery';
	_id: Scalars['NonEmptyString'];
	asset: Asset;
	query: Scalars['NonEmptyString'];
};

export type TrackUpgradeClickInput = {
	source: UpgradeButtonSource;
};

export type UpdateAuthSettingsInput = {
	autoPlayGIFs?: InputMaybe<Scalars['Boolean']>;
	darkMode?: InputMaybe<Scalars['Boolean']>;
	emailNewsletter?: InputMaybe<Scalars['Boolean']>;
	emailNotifications?: InputMaybe<Scalars['Boolean']>;
	feedBannerExpanded?: InputMaybe<Scalars['Boolean']>;
	fitToScreen?: InputMaybe<Scalars['Boolean']>;
	gridColumnsRatio?: InputMaybe<Scalars['Float']>;
	gridSpacingRatio?: InputMaybe<Scalars['Float']>;
	itemsSortMethod?: InputMaybe<ItemsSortMethod>;
	onboard?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateAvatarInput = {
	file: Scalars['Upload'];
};

export type UpdateBillingAutoRenewInput = {
	enable: Scalars['Boolean'];
	type: SubscriptionType;
};

export type UpdateBillingCardInput = {
	cardToken: Scalars['NonEmptyString'];
};

export type UpdateBillingPlanInput = {
	tierAndBillingCycle: Scalars['NonEmptyString'];
	type: SubscriptionType;
};

export type UpdateBillingPlanResponse = {
	__typename?: 'UpdateBillingPlanResponse';
	auth: Auth;
	redirectURL?: Maybe<Scalars['String']>;
};

export type UpdateBoardNameInput = {
	boardID: Scalars['ID'];
	isTeamEditorAnEditor?: InputMaybe<Scalars['Boolean']>;
	name: Scalars['NonEmptyString'];
};

export type UpdateBoardPrivacyInput = {
	boardID: Scalars['ID'];
	isPrivate: Scalars['Boolean'];
};

export type UpdateBoardPrivacyResponse = {
	__typename?: 'UpdateBoardPrivacyResponse';
	auth: Auth;
	board: Board;
};

export type UpdateBoardUserRoleInput = {
	boardID: Scalars['ID'];
	role: BoardUserRole;
	userID: Scalars['ID'];
};

export type UpdateCoverInput = {
	file: Scalars['Upload'];
};

export type UpdateItemInput = {
	itemID: Scalars['ID'];
	name: Scalars['NonEmptyString'];
	pageURL?: InputMaybe<Scalars['String']>;
};

export type UpdateItemsPrivacyInput = {
	isPrivate: Scalars['Boolean'];
	itemIDs: Array<Scalars['ID']>;
};

export type UpdateItemsPrivacyResponse = {
	__typename?: 'UpdateItemsPrivacyResponse';
	auth: Auth;
	items: Array<Item>;
};

export type UpdateProfileInput = {
	company: Scalars['String'];
	email: Scalars['NonEmptyString'];
	instagram: Scalars['String'];
	name: Scalars['String'];
	password?: InputMaybe<Scalars['String']>;
	role: Scalars['String'];
	twitter: Scalars['String'];
	username: Scalars['NonEmptyString'];
	website: Scalars['String'];
	workEmail: Scalars['String'];
};

export type UpdatePushNotificationsInput = {
	enable: Scalars['Boolean'];
};

export type UpdatePushNotificationsTokenInput = {
	fcmToken?: InputMaybe<Scalars['String']>;
	nativeToken?: InputMaybe<Scalars['String']>;
};

export type UpdateSiteInput = {
	backgroundColor?: InputMaybe<Scalars['String']>;
	content1?: InputMaybe<Scalars['String']>;
	content2?: InputMaybe<Scalars['String']>;
	fontFamily?: InputMaybe<Scalars['String']>;
	settings?: InputMaybe<Scalars['JSON']>;
	sourceID?: InputMaybe<Scalars['ID']>;
	template?: InputMaybe<SiteTemplate>;
	templateData?: InputMaybe<Scalars['JSON']>;
	textColor?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type UpdateTeamFeedBoardInput = {
	boardID?: InputMaybe<Scalars['ID']>;
};

export type UpdateTeamInput = {
	email: Scalars['NonEmptyString'];
	name: Scalars['NonEmptyString'];
};

export type UpdateTeamUserRoleInput = {
	role: TeamUserRole;
	userID: Scalars['ID'];
};

export type UpdateUsernameInput = {
	username: Scalars['NonEmptyString'];
};

export type UpdateUsernameResponse = {
	__typename?: 'UpdateUsernameResponse';
	auth: Auth;
	site: Site;
};

export type UpgradeButtonSource =
	| 'autoplay_gif_upsell'
	| 'dropbox_upsell'
	| 'end_of_grid_upsell'
	| 'low_quality_upsell'
	| 'nav_bar'
	| 'nav_menu'
	| 'reached_limit_modal'
	| 'upgrade_required_modal_board_limit'
	| 'upgrade_required_modal_create_site'
	| 'upgrade_required_modal_item_limit'
	| 'upgrade_required_modal_manage_team'
	| 'upgrade_required_modal_print'
	| 'upgrade_required_modal_private'
	| 'upgrade_required_modal_right_click'
	| 'upgrade_required_modal_shared';

export type UpgradeStatsResponse = {
	__typename?: 'UpgradeStatsResponse';
	imagesCount: Scalars['Int'];
};

export type UploadItemFromPageUrlInput = {
	date: Scalars['DateTime'];
	name?: InputMaybe<Scalars['String']>;
	pageURL: Scalars['NonEmptyString'];
};

export type UploadItemFromPageUrlResponse = {
	__typename?: 'UploadItemFromPageURLResponse';
	auth: Auth;
	item: Item;
};

export type UploadItemFromRemoteInput = {
	imageURL: Scalars['NonEmptyString'];
	isPrivate: Scalars['Boolean'];
	name?: InputMaybe<Scalars['String']>;
	pageURL?: InputMaybe<Scalars['String']>;
};

export type UploadItemFromRemoteResponse = {
	__typename?: 'UploadItemFromRemoteResponse';
	auth: Auth;
	item: Item;
};

export type UploadItemInput = {
	boardID?: InputMaybe<Scalars['ID']>;
	date: Scalars['DateTime'];
	file: Scalars['Upload'];
};

export type UploadItemResponse = {
	__typename?: 'UploadItemResponse';
	auth: Auth;
	board?: Maybe<Board>;
	item: Item;
};

export type User = {
	__typename?: 'User';
	_id: Scalars['ID'];
	avatarURL: Scalars['NonEmptyString'];
	boards: Array<Board>;
	boardsCount: Scalars['Int'];
	canFollow: Scalars['Boolean'];
	company?: Maybe<Company>;
	followersCount: Scalars['Int'];
	followingCount: Scalars['Int'];
	instagram?: Maybe<Scalars['String']>;
	isFollowing: Scalars['Boolean'];
	isFollowingBack: Scalars['Boolean'];
	/** @deprecated Use subscriptions. */
	isPro: Scalars['Boolean'];
	items: PaginatedResultItems;
	itemsCount: Scalars['Int'];
	name: Scalars['String'];
	ogImage: OgImage;
	role?: Maybe<Scalars['String']>;
	twitter?: Maybe<Scalars['String']>;
	url: Scalars['NonEmptyString'];
	username: Scalars['NonEmptyString'];
	website?: Maybe<Scalars['String']>;
};

export type UserItemsArgs = {
	cursor?: InputMaybe<Scalars['String']>;
	limit?: InputMaybe<Scalars['Int']>;
	sortMethod?: InputMaybe<ItemsSortMethod>;
};

export type UserMention = {
	__typename?: 'UserMention';
	indices: Array<Scalars['Int']>;
	user: User;
};

export type UserSubscription = {
	__typename?: 'UserSubscription';
	autoRenew: Scalars['Boolean'];
	canManage: Scalars['Boolean'];
	cardBrandID?: Maybe<CardBrand>;
	cardLast4?: Maybe<Scalars['String']>;
	isCardExpired: Scalars['Boolean'];
	maxSeats: Scalars['Int'];
	needsRenewSince?: Maybe<Scalars['DateTime']>;
	planID?: Maybe<Scalars['String']>;
	provider?: Maybe<PaymentProvider>;
	status: SubscriptionStatus;
	subscriptionEndDate?: Maybe<Scalars['DateTime']>;
	type: SubscriptionType;
};

export type ExtensionAuthUserQueryQueryVariables = Exact<{ [key: string]: never }>;

export type ExtensionAuthUserQueryQuery = {
	__typename?: 'Query';
	auth?: {
		__typename?: 'Auth';
		user: {
			__typename?: 'User';
			_id: string;
			isPro: boolean;
			boards: Array<{
				__typename?: 'Board';
				_id: string;
				name: string;
				slug: string;
				url: string;
				isPrivate: boolean;
				itemsCount: number;
				lastItemAddedOrder?: number | null;
				role?: BoardUserRole | null;
				ownershipType: BoardOwnershipType;
				acceptURL?: string | null;
				rejectURL?: string | null;
				publicURL?: string | null;
				isTeamEditorAnEditor?: boolean | null;
				thumbnails: Array<{
					__typename?: 'Asset';
					_id: string;
					image: { __typename?: 'AssetImage'; thumbnail: string };
				}>;
				ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
				collaborators: Array<{
					__typename?: 'BoardCollaborator';
					_id: string;
					role: BoardUserRole;
					user: {
						__typename?: 'User';
						_id: string;
						username: string;
						name: string;
						avatarURL: string;
						url: string;
						isPro: boolean;
					};
				}>;
				invites: Array<{
					__typename?: 'Invite';
					_id: string;
					email?: string | null;
					user?: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					} | null;
				}>;
			}>;
		};
	} | null;
};

export type UnlinkDropboxMutationMutationVariables = Exact<{ [key: string]: never }>;

export type UnlinkDropboxMutationMutation = {
	__typename?: 'Mutation';
	unlinkDropbox: {
		__typename?: 'Auth';
		_id: string;
		settings: { __typename?: 'AuthSettings'; _id: string; dropboxLinked: boolean };
	};
};

export type AddItemsToBoardMutationMutationVariables = Exact<{
	input: AddItemsToBoardInput;
}>;

export type AddItemsToBoardMutationMutation = {
	__typename?: 'Mutation';
	addItemsToBoard: {
		__typename?: 'AddItemsToBoardResponse';
		auth: {
			__typename?: 'Auth';
			user: { __typename?: 'User'; _id: string; itemsCount: number };
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				needsUpgradeReason?: NeedsUpgradeReason | null;
			};
		};
		board: {
			__typename?: 'Board';
			_id: string;
			itemsCount: number;
			lastItemAddedOrder?: number | null;
			thumbnails: Array<{
				__typename?: 'Asset';
				image: { __typename?: 'AssetImage'; thumbnail: string };
			}>;
		};
		assets: Array<{
			__typename?: 'Asset';
			_id: string;
			isSaved: boolean;
			savedCount: number;
			ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
		}>;
	};
};

export type AuthQueryQueryVariables = Exact<{ [key: string]: never }>;

export type AuthQueryQuery = {
	__typename?: 'Query';
	auth?: {
		__typename?: 'Auth';
		token: string;
		user: {
			__typename?: 'User';
			_id: string;
			username: string;
			name: string;
			role?: string | null;
			website?: string | null;
			instagram?: string | null;
			twitter?: string | null;
			url: string;
			avatarURL: string;
			isPro: boolean;
			itemsCount: number;
			boardsCount: number;
			followingCount: number;
			followersCount: number;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
			boards: Array<{
				__typename?: 'Board';
				_id: string;
				name: string;
				slug: string;
				url: string;
				isPrivate: boolean;
				itemsCount: number;
				lastItemAddedOrder?: number | null;
				role?: BoardUserRole | null;
				ownershipType: BoardOwnershipType;
				acceptURL?: string | null;
				rejectURL?: string | null;
				publicURL?: string | null;
				isTeamEditorAnEditor?: boolean | null;
				thumbnails: Array<{
					__typename?: 'Asset';
					_id: string;
					image: { __typename?: 'AssetImage'; thumbnail: string };
				}>;
				ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
				collaborators: Array<{
					__typename?: 'BoardCollaborator';
					_id: string;
					role: BoardUserRole;
					user: {
						__typename?: 'User';
						_id: string;
						username: string;
						name: string;
						avatarURL: string;
						url: string;
						isPro: boolean;
					};
				}>;
				invites: Array<{
					__typename?: 'Invite';
					_id: string;
					email?: string | null;
					user?: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					} | null;
				}>;
			}>;
		};
		settings: {
			__typename?: 'AuthSettings';
			_id: string;
			email: string;
			workEmail?: string | null;
			isBeta: boolean;
			isModerator: boolean;
			gridSpacingRatio: number;
			gridColumnsRatio: number;
			onboard: boolean;
			dropboxLinked: boolean;
			darkMode: boolean;
			emailNotifications: boolean;
			emailNewsletter: boolean;
			itemsSortMethod?: ItemsSortMethod | null;
			autoPlayGIFs: boolean;
			fitToScreen: boolean;
			pinterestUsername?: string | null;
			needsUpgradeReason?: NeedsUpgradeReason | null;
			unreadNotificationsCount: number;
			featureFlags: Array<string>;
			maxBoardCollaborators: number;
			maxTeamUsers: number;
			feedBannerExpanded: boolean;
			megaphones: Array<{
				__typename?: 'Megaphone';
				_id: string;
				title: string;
				body: string;
				ctaLabel?: string | null;
			}>;
			subscriptions: Array<{
				__typename?: 'UserSubscription';
				type: SubscriptionType;
				status: SubscriptionStatus;
				canManage: boolean;
				provider?: PaymentProvider | null;
				planID?: string | null;
				subscriptionEndDate?: Date | null;
				needsRenewSince?: Date | null;
				autoRenew: boolean;
				cardLast4?: string | null;
				cardBrandID?: CardBrand | null;
				isCardExpired: boolean;
				maxSeats: number;
			}>;
		};
		team?: {
			__typename?: 'Team';
			_id: string;
			role: TeamUserRole;
			name: string;
			email: string;
			usersCount: number;
			feedBoardID?: string | null;
			notifyUsersOnComment: boolean;
			autoFollowTeamOwners: boolean;
			teamFeedAsHomepage: boolean;
		} | null;
	} | null;
};

export type AuthUserQueryQueryVariables = Exact<{ [key: string]: never }>;

export type AuthUserQueryQuery = {
	__typename?: 'Query';
	auth?: {
		__typename?: 'Auth';
		user: {
			__typename?: 'User';
			_id: string;
			username: string;
			name: string;
			role?: string | null;
			website?: string | null;
			instagram?: string | null;
			twitter?: string | null;
			url: string;
			avatarURL: string;
			isPro: boolean;
			itemsCount: number;
			boardsCount: number;
			followingCount: number;
			followersCount: number;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
			boards: Array<{
				__typename?: 'Board';
				_id: string;
				name: string;
				slug: string;
				url: string;
				isPrivate: boolean;
				itemsCount: number;
				lastItemAddedOrder?: number | null;
				role?: BoardUserRole | null;
				ownershipType: BoardOwnershipType;
				acceptURL?: string | null;
				rejectURL?: string | null;
				publicURL?: string | null;
				isTeamEditorAnEditor?: boolean | null;
				thumbnails: Array<{
					__typename?: 'Asset';
					_id: string;
					image: { __typename?: 'AssetImage'; thumbnail: string };
				}>;
				ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
				collaborators: Array<{
					__typename?: 'BoardCollaborator';
					_id: string;
					role: BoardUserRole;
					user: {
						__typename?: 'User';
						_id: string;
						username: string;
						name: string;
						avatarURL: string;
						url: string;
						isPro: boolean;
					};
				}>;
				invites: Array<{
					__typename?: 'Invite';
					_id: string;
					email?: string | null;
					user?: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					} | null;
				}>;
			}>;
		};
	} | null;
};

export type BillingPaymentsQueryQueryVariables = Exact<{
	type: SubscriptionType;
}>;

export type BillingPaymentsQueryQuery = {
	__typename?: 'Query';
	billingPayments: Array<{
		__typename?: 'BillingPayment';
		provider: PaymentProvider;
		chargeID: string;
		date: Date;
		amount: number;
		status: PaymentStatus;
		cardLast4?: string | null;
		refunds: Array<{ __typename?: 'BillingRefund'; amount: number }>;
	}>;
};

export type BoardItemsQueryQueryVariables = Exact<{
	boardID: Scalars['ID'];
	sortMethod?: InputMaybe<ItemsSortMethod>;
	limit?: InputMaybe<Scalars['Int']>;
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type BoardItemsQueryQuery = {
	__typename?: 'Query';
	boardByID: {
		__typename?: 'Board';
		items: {
			__typename?: 'PaginatedResultItems';
			items: Array<{
				__typename?: 'Item';
				_id: string;
				shortID: string;
				url: string;
				createdAt?: Date | null;
				name?: string | null;
				pageURL?: string | null;
				sourceDisplayURL?: string | null;
				sourceURL?: string | null;
				isPrivate: boolean;
				isOwner: boolean;
				user: { __typename?: 'User'; _id: string; name: string; url: string };
				asset: {
					__typename?: 'Asset';
					_id: string;
					isSaved: boolean;
					savedCount: number;
					viewsCount: number;
					type: AssetType;
					image: {
						__typename?: 'AssetImage';
						thumbnail: string;
						original: string;
						ratio: number;
						width: number;
						height: number;
					};
					colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
					ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
				};
			}>;
			pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
		};
	};
};

export type CheckPaymentQueryQueryVariables = Exact<{
	type: SubscriptionType;
}>;

export type CheckPaymentQueryQuery = {
	__typename?: 'Query';
	checkPayment: {
		__typename?: 'CheckPaymentResponse';
		confirmed: boolean;
		amount?: number | null;
	};
};

export type CheckResetPasswordTokenQueryQueryVariables = Exact<{
	resetPasswordToken: Scalars['NonEmptyString'];
}>;

export type CheckResetPasswordTokenQueryQuery = {
	__typename?: 'Query';
	checkResetPasswordToken: {
		__typename?: 'CheckResetPasswordResponse';
		resetPasswordToken: string;
		firstName?: string | null;
	};
};

export type CheckSiteDomainMutationMutationVariables = Exact<{
	input: SiteDomainInput;
}>;

export type CheckSiteDomainMutationMutation = {
	__typename?: 'Mutation';
	checkSiteDomain: {
		__typename?: 'Site';
		_id: string;
		domains?: Array<{
			__typename?: 'SiteDomain';
			domain: string;
			status: DomainVerificationStatus;
		}> | null;
	};
};

export type ClearUnreadNotificationsCountMutationMutationVariables = Exact<{
	[key: string]: never;
}>;

export type ClearUnreadNotificationsCountMutationMutation = {
	__typename?: 'Mutation';
	clearUnreadNotificationsCount: {
		__typename?: 'Auth';
		_id: string;
		settings: { __typename?: 'AuthSettings'; _id: string; unreadNotificationsCount: number };
	};
};

export type CompaniesQueryQueryVariables = Exact<{
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type CompaniesQueryQuery = {
	__typename?: 'Query';
	companies: {
		__typename?: 'PaginatedResultCompanies';
		items: Array<{ __typename?: 'Company'; _id: string; name: string; url: string }>;
		pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
	};
};

export type CompanyQueryQueryVariables = Exact<{
	slug: Scalars['String'];
}>;

export type CompanyQueryQuery = {
	__typename?: 'Query';
	companyBySlug: { __typename?: 'Company'; _id: string; name: string };
};

export type CompanyUsersQueryQueryVariables = Exact<{
	slug: Scalars['String'];
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type CompanyUsersQueryQuery = {
	__typename?: 'Query';
	companyBySlug: {
		__typename?: 'Company';
		_id: string;
		users: {
			__typename?: 'PaginatedResultUsers';
			items: Array<{
				__typename?: 'User';
				_id: string;
				name: string;
				username: string;
				url: string;
				role?: string | null;
				avatarURL: string;
				isPro: boolean;
				canFollow: boolean;
				isFollowing: boolean;
				isFollowingBack: boolean;
				company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
				items: {
					__typename?: 'PaginatedResultItems';
					items: Array<{
						__typename?: 'Item';
						_id: string;
						shortID: string;
						url: string;
						asset: {
							__typename?: 'Asset';
							_id: string;
							image: { __typename?: 'AssetImage'; thumbnail: string };
							colors: Array<{ __typename?: 'AssetColor'; color: string }>;
						};
					}>;
				};
			}>;
			pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
		};
	};
};

export type DeleteBoardMutationMutationVariables = Exact<{
	input: DeleteBoardInput;
}>;

export type DeleteBoardMutationMutation = {
	__typename?: 'Mutation';
	deleteBoard: {
		__typename?: 'DeleteBoardResponse';
		deletedItemIDs: Array<string>;
		auth: {
			__typename?: 'Auth';
			user: {
				__typename?: 'User';
				_id: string;
				boardsCount: number;
				itemsCount: number;
				boards: Array<{
					__typename?: 'Board';
					_id: string;
					name: string;
					slug: string;
					url: string;
					isPrivate: boolean;
					itemsCount: number;
					lastItemAddedOrder?: number | null;
					role?: BoardUserRole | null;
					ownershipType: BoardOwnershipType;
					acceptURL?: string | null;
					rejectURL?: string | null;
					publicURL?: string | null;
					isTeamEditorAnEditor?: boolean | null;
					thumbnails: Array<{
						__typename?: 'Asset';
						_id: string;
						image: { __typename?: 'AssetImage'; thumbnail: string };
					}>;
					ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
					collaborators: Array<{
						__typename?: 'BoardCollaborator';
						_id: string;
						role: BoardUserRole;
						user: {
							__typename?: 'User';
							_id: string;
							username: string;
							name: string;
							avatarURL: string;
							url: string;
							isPro: boolean;
						};
					}>;
					invites: Array<{
						__typename?: 'Invite';
						_id: string;
						email?: string | null;
						user?: {
							__typename?: 'User';
							_id: string;
							name: string;
							username: string;
							url: string;
							avatarURL: string;
						} | null;
					}>;
				}>;
			};
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				needsUpgradeReason?: NeedsUpgradeReason | null;
			};
		};
		assets: Array<{
			__typename?: 'Asset';
			_id: string;
			ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
		}>;
	};
};

export type DeleteCommentMutationMutationVariables = Exact<{
	input: DeleteCommentInput;
}>;

export type DeleteCommentMutationMutation = {
	__typename?: 'Mutation';
	deleteComment: { __typename?: 'DeleteCommentResponse'; success: boolean };
};

export type DeleteItemsMutationMutationVariables = Exact<{
	input: DeleteItemsInput;
}>;

export type DeleteItemsMutationMutation = {
	__typename?: 'Mutation';
	deleteItems: {
		__typename?: 'DeleteItemsResponse';
		deletedItemIDs: Array<string>;
		auth: {
			__typename?: 'Auth';
			user: {
				__typename?: 'User';
				_id: string;
				itemsCount: number;
				boards: Array<{
					__typename?: 'Board';
					_id: string;
					name: string;
					slug: string;
					url: string;
					isPrivate: boolean;
					itemsCount: number;
					lastItemAddedOrder?: number | null;
					role?: BoardUserRole | null;
					ownershipType: BoardOwnershipType;
					acceptURL?: string | null;
					rejectURL?: string | null;
					publicURL?: string | null;
					isTeamEditorAnEditor?: boolean | null;
					thumbnails: Array<{
						__typename?: 'Asset';
						_id: string;
						image: { __typename?: 'AssetImage'; thumbnail: string };
					}>;
					ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
					collaborators: Array<{
						__typename?: 'BoardCollaborator';
						_id: string;
						role: BoardUserRole;
						user: {
							__typename?: 'User';
							_id: string;
							username: string;
							name: string;
							avatarURL: string;
							url: string;
							isPro: boolean;
						};
					}>;
					invites: Array<{
						__typename?: 'Invite';
						_id: string;
						email?: string | null;
						user?: {
							__typename?: 'User';
							_id: string;
							name: string;
							username: string;
							url: string;
							avatarURL: string;
						} | null;
					}>;
				}>;
			};
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				needsUpgradeReason?: NeedsUpgradeReason | null;
			};
		};
		assets: Array<{
			__typename?: 'Asset';
			_id: string;
			isSaved: boolean;
			savedCount: number;
			ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
		}>;
	};
};

export type DeleteSiteDomainMutationMutationVariables = Exact<{
	input: SiteDomainInput;
}>;

export type DeleteSiteDomainMutationMutation = {
	__typename?: 'Mutation';
	deleteSiteDomain: {
		__typename?: 'Site';
		_id: string;
		url: string;
		template: SiteTemplate;
		sourceID?: string | null;
		title?: string | null;
		fontFamily?: string | null;
		content1?: string | null;
		content2?: string | null;
		backgroundColor?: string | null;
		textColor?: string | null;
		settings?: any | null;
		templateData?: any | null;
		domains?: Array<{
			__typename?: 'SiteDomain';
			domain: string;
			status: DomainVerificationStatus;
		}> | null;
	};
};

export type DeleteTeamMutationMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteTeamMutationMutation = {
	__typename?: 'Mutation';
	deleteTeam: {
		__typename?: 'DeleteTeamResponse';
		auth: {
			__typename?: 'Auth';
			team?: {
				__typename?: 'Team';
				_id: string;
				role: TeamUserRole;
				name: string;
				email: string;
				usersCount: number;
				feedBoardID?: string | null;
				notifyUsersOnComment: boolean;
				autoFollowTeamOwners: boolean;
				teamFeedAsHomepage: boolean;
			} | null;
		};
	};
};

export type DeleteUserMutationMutationVariables = Exact<{
	input: DeleteUserInput;
}>;

export type DeleteUserMutationMutation = { __typename?: 'Mutation'; deleteUser: boolean };

export type DirectoryUsersQueryQueryVariables = Exact<{
	firstLetter: Scalars['NonEmptyString'];
}>;

export type DirectoryUsersQueryQuery = {
	__typename?: 'Query';
	directoryUsers: Array<{
		__typename?: 'DirectoryUser';
		_id: string;
		name?: string | null;
		url: string;
		username: string;
	}>;
};

export type DismissMegaphoneMutationMutationVariables = Exact<{
	input: DismissMegaphoneInput;
}>;

export type DismissMegaphoneMutationMutation = {
	__typename?: 'Mutation';
	dismissMegaphone: { __typename?: 'Auth'; _id: string };
};

export type FeedItemsQueryQueryVariables = Exact<{
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type FeedItemsQueryQuery = {
	__typename?: 'Query';
	feedItems: {
		__typename?: 'PaginatedResultItems';
		items: Array<{
			__typename?: 'Item';
			_id: string;
			shortID: string;
			url: string;
			createdAt?: Date | null;
			name?: string | null;
			pageURL?: string | null;
			sourceDisplayURL?: string | null;
			sourceURL?: string | null;
			isPrivate: boolean;
			isOwner: boolean;
			user: { __typename?: 'User'; _id: string; name: string; url: string };
			asset: {
				__typename?: 'Asset';
				_id: string;
				isSaved: boolean;
				savedCount: number;
				viewsCount: number;
				type: AssetType;
				image: {
					__typename?: 'AssetImage';
					thumbnail: string;
					original: string;
					ratio: number;
					width: number;
					height: number;
				};
				colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
				ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
			};
		}>;
		pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
	};
};

export type ForgotPasswordMutationMutationVariables = Exact<{
	input: ForgotPasswordInput;
}>;

export type ForgotPasswordMutationMutation = { __typename?: 'Mutation'; forgotPassword: boolean };

export type ImportPinterestCheckUsernameMutationMutationVariables = Exact<{
	input: ImportPinterestCheckUsernameInput;
}>;

export type ImportPinterestCheckUsernameMutationMutation = {
	__typename?: 'Mutation';
	importPinterestCheckUsername: {
		__typename?: 'Auth';
		_id: string;
		settings: { __typename?: 'AuthSettings'; _id: string; pinterestUsername?: string | null };
	};
};

export type ImportProcessGroupMutationMutationVariables = Exact<{
	input: ImportProcessGroupInput;
}>;

export type ImportProcessGroupMutationMutation = {
	__typename?: 'Mutation';
	importProcessGroup: {
		__typename?: 'ImportProcessGroupResponse';
		items: Array<{
			__typename?: 'ImportItem';
			sourceID: string;
			groupID: string;
			title?: string | null;
			date?: Date | null;
			type: string;
			url: string;
			pageURL?: string | null;
			description?: string | null;
			boardID: string;
			private: boolean;
		}>;
	};
};

export type ImportProcessItemMutationMutationVariables = Exact<{
	input: ImportProcessItemInput;
}>;

export type ImportProcessItemMutationMutation = {
	__typename?: 'Mutation';
	importProcessItem: boolean;
};

export type ImportStartQueryQueryVariables = Exact<{
	provider: ImportProvider;
}>;

export type ImportStartQueryQuery = {
	__typename?: 'Query';
	importStart: {
		__typename?: 'ImportStartResponse';
		count: number;
		groups: Array<{
			__typename?: 'ImportGroup';
			sourceID: string;
			name: string;
			count: number;
			private: boolean;
			thumbnailURL?: string | null;
		}>;
	};
};

export type InternalLoginAsMutationMutationVariables = Exact<{
	input: InternalLoginAsInput;
}>;

export type InternalLoginAsMutationMutation = {
	__typename?: 'Mutation';
	internalLoginAs: {
		__typename?: 'Auth';
		token: string;
		user: {
			__typename?: 'User';
			_id: string;
			username: string;
			name: string;
			role?: string | null;
			website?: string | null;
			instagram?: string | null;
			twitter?: string | null;
			url: string;
			avatarURL: string;
			isPro: boolean;
			itemsCount: number;
			boardsCount: number;
			followingCount: number;
			followersCount: number;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
			boards: Array<{
				__typename?: 'Board';
				_id: string;
				name: string;
				slug: string;
				url: string;
				isPrivate: boolean;
				itemsCount: number;
				lastItemAddedOrder?: number | null;
				role?: BoardUserRole | null;
				ownershipType: BoardOwnershipType;
				acceptURL?: string | null;
				rejectURL?: string | null;
				publicURL?: string | null;
				isTeamEditorAnEditor?: boolean | null;
				thumbnails: Array<{
					__typename?: 'Asset';
					_id: string;
					image: { __typename?: 'AssetImage'; thumbnail: string };
				}>;
				ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
				collaborators: Array<{
					__typename?: 'BoardCollaborator';
					_id: string;
					role: BoardUserRole;
					user: {
						__typename?: 'User';
						_id: string;
						username: string;
						name: string;
						avatarURL: string;
						url: string;
						isPro: boolean;
					};
				}>;
				invites: Array<{
					__typename?: 'Invite';
					_id: string;
					email?: string | null;
					user?: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					} | null;
				}>;
			}>;
		};
		settings: {
			__typename?: 'AuthSettings';
			_id: string;
			email: string;
			workEmail?: string | null;
			isBeta: boolean;
			isModerator: boolean;
			gridSpacingRatio: number;
			gridColumnsRatio: number;
			onboard: boolean;
			dropboxLinked: boolean;
			darkMode: boolean;
			emailNotifications: boolean;
			emailNewsletter: boolean;
			itemsSortMethod?: ItemsSortMethod | null;
			autoPlayGIFs: boolean;
			fitToScreen: boolean;
			pinterestUsername?: string | null;
			needsUpgradeReason?: NeedsUpgradeReason | null;
			unreadNotificationsCount: number;
			featureFlags: Array<string>;
			maxBoardCollaborators: number;
			maxTeamUsers: number;
			feedBannerExpanded: boolean;
			megaphones: Array<{
				__typename?: 'Megaphone';
				_id: string;
				title: string;
				body: string;
				ctaLabel?: string | null;
			}>;
			subscriptions: Array<{
				__typename?: 'UserSubscription';
				type: SubscriptionType;
				status: SubscriptionStatus;
				canManage: boolean;
				provider?: PaymentProvider | null;
				planID?: string | null;
				subscriptionEndDate?: Date | null;
				needsRenewSince?: Date | null;
				autoRenew: boolean;
				cardLast4?: string | null;
				cardBrandID?: CardBrand | null;
				isCardExpired: boolean;
				maxSeats: number;
			}>;
		};
		team?: {
			__typename?: 'Team';
			_id: string;
			role: TeamUserRole;
			name: string;
			email: string;
			usersCount: number;
			feedBoardID?: string | null;
			notifyUsersOnComment: boolean;
			autoFollowTeamOwners: boolean;
			teamFeedAsHomepage: boolean;
		} | null;
	};
};

export type InternalLoginFromScriptsMutationMutationVariables = Exact<{
	input: InternalLoginFromScriptsInput;
}>;

export type InternalLoginFromScriptsMutationMutation = {
	__typename?: 'Mutation';
	internalLoginFromScripts: {
		__typename?: 'Auth';
		token: string;
		user: {
			__typename?: 'User';
			_id: string;
			username: string;
			name: string;
			role?: string | null;
			website?: string | null;
			instagram?: string | null;
			twitter?: string | null;
			url: string;
			avatarURL: string;
			isPro: boolean;
			itemsCount: number;
			boardsCount: number;
			followingCount: number;
			followersCount: number;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
			boards: Array<{
				__typename?: 'Board';
				_id: string;
				name: string;
				slug: string;
				url: string;
				isPrivate: boolean;
				itemsCount: number;
				lastItemAddedOrder?: number | null;
				role?: BoardUserRole | null;
				ownershipType: BoardOwnershipType;
				acceptURL?: string | null;
				rejectURL?: string | null;
				publicURL?: string | null;
				isTeamEditorAnEditor?: boolean | null;
				thumbnails: Array<{
					__typename?: 'Asset';
					_id: string;
					image: { __typename?: 'AssetImage'; thumbnail: string };
				}>;
				ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
				collaborators: Array<{
					__typename?: 'BoardCollaborator';
					_id: string;
					role: BoardUserRole;
					user: {
						__typename?: 'User';
						_id: string;
						username: string;
						name: string;
						avatarURL: string;
						url: string;
						isPro: boolean;
					};
				}>;
				invites: Array<{
					__typename?: 'Invite';
					_id: string;
					email?: string | null;
					user?: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					} | null;
				}>;
			}>;
		};
		settings: {
			__typename?: 'AuthSettings';
			_id: string;
			email: string;
			workEmail?: string | null;
			isBeta: boolean;
			isModerator: boolean;
			gridSpacingRatio: number;
			gridColumnsRatio: number;
			onboard: boolean;
			dropboxLinked: boolean;
			darkMode: boolean;
			emailNotifications: boolean;
			emailNewsletter: boolean;
			itemsSortMethod?: ItemsSortMethod | null;
			autoPlayGIFs: boolean;
			fitToScreen: boolean;
			pinterestUsername?: string | null;
			needsUpgradeReason?: NeedsUpgradeReason | null;
			unreadNotificationsCount: number;
			featureFlags: Array<string>;
			maxBoardCollaborators: number;
			maxTeamUsers: number;
			feedBannerExpanded: boolean;
			megaphones: Array<{
				__typename?: 'Megaphone';
				_id: string;
				title: string;
				body: string;
				ctaLabel?: string | null;
			}>;
			subscriptions: Array<{
				__typename?: 'UserSubscription';
				type: SubscriptionType;
				status: SubscriptionStatus;
				canManage: boolean;
				provider?: PaymentProvider | null;
				planID?: string | null;
				subscriptionEndDate?: Date | null;
				needsRenewSince?: Date | null;
				autoRenew: boolean;
				cardLast4?: string | null;
				cardBrandID?: CardBrand | null;
				isCardExpired: boolean;
				maxSeats: number;
			}>;
		};
		team?: {
			__typename?: 'Team';
			_id: string;
			role: TeamUserRole;
			name: string;
			email: string;
			usersCount: number;
			feedBoardID?: string | null;
			notifyUsersOnComment: boolean;
			autoFollowTeamOwners: boolean;
			teamFeedAsHomepage: boolean;
		} | null;
	};
};

export type InternalLoginFromTestsMutationMutationVariables = Exact<{
	input: InternalLoginFromTestsInput;
}>;

export type InternalLoginFromTestsMutationMutation = {
	__typename?: 'Mutation';
	internalLoginFromTests: {
		__typename?: 'Auth';
		token: string;
		user: {
			__typename?: 'User';
			_id: string;
			username: string;
			name: string;
			role?: string | null;
			website?: string | null;
			instagram?: string | null;
			twitter?: string | null;
			url: string;
			avatarURL: string;
			isPro: boolean;
			itemsCount: number;
			boardsCount: number;
			followingCount: number;
			followersCount: number;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
			boards: Array<{
				__typename?: 'Board';
				_id: string;
				name: string;
				slug: string;
				url: string;
				isPrivate: boolean;
				itemsCount: number;
				lastItemAddedOrder?: number | null;
				role?: BoardUserRole | null;
				ownershipType: BoardOwnershipType;
				acceptURL?: string | null;
				rejectURL?: string | null;
				publicURL?: string | null;
				isTeamEditorAnEditor?: boolean | null;
				thumbnails: Array<{
					__typename?: 'Asset';
					_id: string;
					image: { __typename?: 'AssetImage'; thumbnail: string };
				}>;
				ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
				collaborators: Array<{
					__typename?: 'BoardCollaborator';
					_id: string;
					role: BoardUserRole;
					user: {
						__typename?: 'User';
						_id: string;
						username: string;
						name: string;
						avatarURL: string;
						url: string;
						isPro: boolean;
					};
				}>;
				invites: Array<{
					__typename?: 'Invite';
					_id: string;
					email?: string | null;
					user?: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					} | null;
				}>;
			}>;
		};
		settings: {
			__typename?: 'AuthSettings';
			_id: string;
			email: string;
			workEmail?: string | null;
			isBeta: boolean;
			isModerator: boolean;
			gridSpacingRatio: number;
			gridColumnsRatio: number;
			onboard: boolean;
			dropboxLinked: boolean;
			darkMode: boolean;
			emailNotifications: boolean;
			emailNewsletter: boolean;
			itemsSortMethod?: ItemsSortMethod | null;
			autoPlayGIFs: boolean;
			fitToScreen: boolean;
			pinterestUsername?: string | null;
			needsUpgradeReason?: NeedsUpgradeReason | null;
			unreadNotificationsCount: number;
			featureFlags: Array<string>;
			maxBoardCollaborators: number;
			maxTeamUsers: number;
			feedBannerExpanded: boolean;
			megaphones: Array<{
				__typename?: 'Megaphone';
				_id: string;
				title: string;
				body: string;
				ctaLabel?: string | null;
			}>;
			subscriptions: Array<{
				__typename?: 'UserSubscription';
				type: SubscriptionType;
				status: SubscriptionStatus;
				canManage: boolean;
				provider?: PaymentProvider | null;
				planID?: string | null;
				subscriptionEndDate?: Date | null;
				needsRenewSince?: Date | null;
				autoRenew: boolean;
				cardLast4?: string | null;
				cardBrandID?: CardBrand | null;
				isCardExpired: boolean;
				maxSeats: number;
			}>;
		};
		team?: {
			__typename?: 'Team';
			_id: string;
			role: TeamUserRole;
			name: string;
			email: string;
			usersCount: number;
			feedBoardID?: string | null;
			notifyUsersOnComment: boolean;
			autoFollowTeamOwners: boolean;
			teamFeedAsHomepage: boolean;
		} | null;
	};
};

export type InviteUsersToBoardMutationMutationVariables = Exact<{
	input: InviteUsersToBoardInput;
}>;

export type InviteUsersToBoardMutationMutation = {
	__typename?: 'Mutation';
	inviteUsersToBoard: {
		__typename?: 'Board';
		_id: string;
		collaborators: Array<{
			__typename?: 'BoardCollaborator';
			_id: string;
			role: BoardUserRole;
			user: {
				__typename?: 'User';
				_id: string;
				username: string;
				name: string;
				avatarURL: string;
				url: string;
				isPro: boolean;
			};
		}>;
		invites: Array<{
			__typename?: 'Invite';
			_id: string;
			email?: string | null;
			user?: {
				__typename?: 'User';
				_id: string;
				name: string;
				username: string;
				url: string;
				avatarURL: string;
			} | null;
		}>;
	};
};

export type InviteUsersToTeamMutationMutationVariables = Exact<{
	input: InviteUsersToTeamInput;
}>;

export type InviteUsersToTeamMutationMutation = {
	__typename?: 'Mutation';
	inviteUsersToTeam: {
		__typename?: 'Team';
		_id: string;
		invites: Array<{
			__typename?: 'Invite';
			_id: string;
			email?: string | null;
			user?: {
				__typename?: 'User';
				_id: string;
				name: string;
				username: string;
				url: string;
				avatarURL: string;
			} | null;
		}>;
	};
};

export type ItemDetailsQueryVariables = Exact<{
	shortID: Scalars['NonEmptyString'];
}>;

export type ItemDetailsQuery = {
	__typename?: 'Query';
	itemByShortID: {
		__typename?: 'Item';
		_id: string;
		asset: {
			__typename?: 'Asset';
			_id: string;
			hasRelated: boolean;
			ogImage?: { __typename?: 'OGImage'; width: number; height: number; url: string } | null;
			users: Array<{
				__typename?: 'User';
				_id: string;
				name: string;
				username: string;
				url: string;
				avatarURL: string;
				isPro: boolean;
				canFollow: boolean;
				isFollowing: boolean;
				isFollowingBack: boolean;
			}>;
			tags: Array<{ __typename?: 'Tag'; name: string }>;
			comments: Array<{
				__typename?: 'Comment';
				_id: string;
				createdAt: Date;
				content: string;
				isTeamOnly: boolean;
				isLiked: boolean;
				likesCount: number;
				user: {
					__typename?: 'User';
					_id: string;
					name: string;
					username: string;
					url: string;
					avatarURL: string;
				};
				parent?: { __typename?: 'Comment'; _id: string } | null;
				position?: { __typename?: 'Position'; x: number; y: number } | null;
				userMentions?: Array<{
					__typename?: 'UserMention';
					indices: Array<number>;
					user: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					};
				}> | null;
			}>;
		};
	};
};

export type ItemQueryVariables = Exact<{
	shortID: Scalars['NonEmptyString'];
}>;

export type ItemQuery = {
	__typename?: 'Query';
	itemByShortID: {
		__typename?: 'Item';
		_id: string;
		shortID: string;
		url: string;
		createdAt?: Date | null;
		name?: string | null;
		pageURL?: string | null;
		sourceDisplayURL?: string | null;
		sourceURL?: string | null;
		isPrivate: boolean;
		isOwner: boolean;
		user: { __typename?: 'User'; _id: string; name: string; url: string };
		asset: {
			__typename?: 'Asset';
			_id: string;
			isSaved: boolean;
			savedCount: number;
			viewsCount: number;
			type: AssetType;
			image: {
				__typename?: 'AssetImage';
				thumbnail: string;
				original: string;
				ratio: number;
				width: number;
				height: number;
			};
			colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
			ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
		};
	};
};

export type JoinMutationMutationVariables = Exact<{
	input: JoinInput;
}>;

export type JoinMutationMutation = {
	__typename?: 'Mutation';
	join: {
		__typename?: 'Auth';
		token: string;
		user: {
			__typename?: 'User';
			_id: string;
			username: string;
			name: string;
			role?: string | null;
			website?: string | null;
			instagram?: string | null;
			twitter?: string | null;
			url: string;
			avatarURL: string;
			isPro: boolean;
			itemsCount: number;
			boardsCount: number;
			followingCount: number;
			followersCount: number;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
			boards: Array<{
				__typename?: 'Board';
				_id: string;
				name: string;
				slug: string;
				url: string;
				isPrivate: boolean;
				itemsCount: number;
				lastItemAddedOrder?: number | null;
				role?: BoardUserRole | null;
				ownershipType: BoardOwnershipType;
				acceptURL?: string | null;
				rejectURL?: string | null;
				publicURL?: string | null;
				isTeamEditorAnEditor?: boolean | null;
				thumbnails: Array<{
					__typename?: 'Asset';
					_id: string;
					image: { __typename?: 'AssetImage'; thumbnail: string };
				}>;
				ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
				collaborators: Array<{
					__typename?: 'BoardCollaborator';
					_id: string;
					role: BoardUserRole;
					user: {
						__typename?: 'User';
						_id: string;
						username: string;
						name: string;
						avatarURL: string;
						url: string;
						isPro: boolean;
					};
				}>;
				invites: Array<{
					__typename?: 'Invite';
					_id: string;
					email?: string | null;
					user?: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					} | null;
				}>;
			}>;
		};
		settings: {
			__typename?: 'AuthSettings';
			_id: string;
			email: string;
			workEmail?: string | null;
			isBeta: boolean;
			isModerator: boolean;
			gridSpacingRatio: number;
			gridColumnsRatio: number;
			onboard: boolean;
			dropboxLinked: boolean;
			darkMode: boolean;
			emailNotifications: boolean;
			emailNewsletter: boolean;
			itemsSortMethod?: ItemsSortMethod | null;
			autoPlayGIFs: boolean;
			fitToScreen: boolean;
			pinterestUsername?: string | null;
			needsUpgradeReason?: NeedsUpgradeReason | null;
			unreadNotificationsCount: number;
			featureFlags: Array<string>;
			maxBoardCollaborators: number;
			maxTeamUsers: number;
			feedBannerExpanded: boolean;
			megaphones: Array<{
				__typename?: 'Megaphone';
				_id: string;
				title: string;
				body: string;
				ctaLabel?: string | null;
			}>;
			subscriptions: Array<{
				__typename?: 'UserSubscription';
				type: SubscriptionType;
				status: SubscriptionStatus;
				canManage: boolean;
				provider?: PaymentProvider | null;
				planID?: string | null;
				subscriptionEndDate?: Date | null;
				needsRenewSince?: Date | null;
				autoRenew: boolean;
				cardLast4?: string | null;
				cardBrandID?: CardBrand | null;
				isCardExpired: boolean;
				maxSeats: number;
			}>;
		};
		team?: {
			__typename?: 'Team';
			_id: string;
			role: TeamUserRole;
			name: string;
			email: string;
			usersCount: number;
			feedBoardID?: string | null;
			notifyUsersOnComment: boolean;
			autoFollowTeamOwners: boolean;
			teamFeedAsHomepage: boolean;
		} | null;
	};
};

export type LandingQueryQueryVariables = Exact<{ [key: string]: never }>;

export type LandingQueryQuery = {
	__typename?: 'Query';
	landing: {
		__typename?: 'Landing';
		items: Array<{
			__typename?: 'Item';
			_id: string;
			asset: {
				__typename?: 'Asset';
				_id: string;
				image: { __typename?: 'AssetImage'; thumbnail: string; ratio: number };
				colors: Array<{ __typename?: 'AssetColor'; color: string }>;
			};
		}>;
	};
};

export type ListItemsQueryQueryVariables = Exact<{
	shortID: Scalars['NonEmptyString'];
	limit?: InputMaybe<Scalars['Int']>;
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type ListItemsQueryQuery = {
	__typename?: 'Query';
	listByShortID: {
		__typename?: 'List';
		items: {
			__typename?: 'PaginatedResultItems';
			items: Array<{
				__typename?: 'Item';
				_id: string;
				shortID: string;
				url: string;
				createdAt?: Date | null;
				name?: string | null;
				pageURL?: string | null;
				sourceDisplayURL?: string | null;
				sourceURL?: string | null;
				isPrivate: boolean;
				isOwner: boolean;
				user: { __typename?: 'User'; _id: string; name: string; url: string };
				asset: {
					__typename?: 'Asset';
					_id: string;
					isSaved: boolean;
					savedCount: number;
					viewsCount: number;
					type: AssetType;
					image: {
						__typename?: 'AssetImage';
						thumbnail: string;
						original: string;
						ratio: number;
						width: number;
						height: number;
					};
					colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
					ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
				};
			}>;
			pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
		};
	};
};

export type LoginFromTokenMutationMutationVariables = Exact<{
	input: LoginFromTokenInput;
}>;

export type LoginFromTokenMutationMutation = {
	__typename?: 'Mutation';
	loginFromToken: {
		__typename?: 'Auth';
		token: string;
		user: {
			__typename?: 'User';
			_id: string;
			username: string;
			name: string;
			role?: string | null;
			website?: string | null;
			instagram?: string | null;
			twitter?: string | null;
			url: string;
			avatarURL: string;
			isPro: boolean;
			itemsCount: number;
			boardsCount: number;
			followingCount: number;
			followersCount: number;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
			boards: Array<{
				__typename?: 'Board';
				_id: string;
				name: string;
				slug: string;
				url: string;
				isPrivate: boolean;
				itemsCount: number;
				lastItemAddedOrder?: number | null;
				role?: BoardUserRole | null;
				ownershipType: BoardOwnershipType;
				acceptURL?: string | null;
				rejectURL?: string | null;
				publicURL?: string | null;
				isTeamEditorAnEditor?: boolean | null;
				thumbnails: Array<{
					__typename?: 'Asset';
					_id: string;
					image: { __typename?: 'AssetImage'; thumbnail: string };
				}>;
				ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
				collaborators: Array<{
					__typename?: 'BoardCollaborator';
					_id: string;
					role: BoardUserRole;
					user: {
						__typename?: 'User';
						_id: string;
						username: string;
						name: string;
						avatarURL: string;
						url: string;
						isPro: boolean;
					};
				}>;
				invites: Array<{
					__typename?: 'Invite';
					_id: string;
					email?: string | null;
					user?: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					} | null;
				}>;
			}>;
		};
		settings: {
			__typename?: 'AuthSettings';
			_id: string;
			email: string;
			workEmail?: string | null;
			isBeta: boolean;
			isModerator: boolean;
			gridSpacingRatio: number;
			gridColumnsRatio: number;
			onboard: boolean;
			dropboxLinked: boolean;
			darkMode: boolean;
			emailNotifications: boolean;
			emailNewsletter: boolean;
			itemsSortMethod?: ItemsSortMethod | null;
			autoPlayGIFs: boolean;
			fitToScreen: boolean;
			pinterestUsername?: string | null;
			needsUpgradeReason?: NeedsUpgradeReason | null;
			unreadNotificationsCount: number;
			featureFlags: Array<string>;
			maxBoardCollaborators: number;
			maxTeamUsers: number;
			feedBannerExpanded: boolean;
			megaphones: Array<{
				__typename?: 'Megaphone';
				_id: string;
				title: string;
				body: string;
				ctaLabel?: string | null;
			}>;
			subscriptions: Array<{
				__typename?: 'UserSubscription';
				type: SubscriptionType;
				status: SubscriptionStatus;
				canManage: boolean;
				provider?: PaymentProvider | null;
				planID?: string | null;
				subscriptionEndDate?: Date | null;
				needsRenewSince?: Date | null;
				autoRenew: boolean;
				cardLast4?: string | null;
				cardBrandID?: CardBrand | null;
				isCardExpired: boolean;
				maxSeats: number;
			}>;
		};
		team?: {
			__typename?: 'Team';
			_id: string;
			role: TeamUserRole;
			name: string;
			email: string;
			usersCount: number;
			feedBoardID?: string | null;
			notifyUsersOnComment: boolean;
			autoFollowTeamOwners: boolean;
			teamFeedAsHomepage: boolean;
		} | null;
	};
};

export type LoginMutationMutationVariables = Exact<{
	input: LoginInput;
}>;

export type LoginMutationMutation = {
	__typename?: 'Mutation';
	login: {
		__typename?: 'Auth';
		token: string;
		user: {
			__typename?: 'User';
			_id: string;
			username: string;
			name: string;
			role?: string | null;
			website?: string | null;
			instagram?: string | null;
			twitter?: string | null;
			url: string;
			avatarURL: string;
			isPro: boolean;
			itemsCount: number;
			boardsCount: number;
			followingCount: number;
			followersCount: number;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
			boards: Array<{
				__typename?: 'Board';
				_id: string;
				name: string;
				slug: string;
				url: string;
				isPrivate: boolean;
				itemsCount: number;
				lastItemAddedOrder?: number | null;
				role?: BoardUserRole | null;
				ownershipType: BoardOwnershipType;
				acceptURL?: string | null;
				rejectURL?: string | null;
				publicURL?: string | null;
				isTeamEditorAnEditor?: boolean | null;
				thumbnails: Array<{
					__typename?: 'Asset';
					_id: string;
					image: { __typename?: 'AssetImage'; thumbnail: string };
				}>;
				ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
				collaborators: Array<{
					__typename?: 'BoardCollaborator';
					_id: string;
					role: BoardUserRole;
					user: {
						__typename?: 'User';
						_id: string;
						username: string;
						name: string;
						avatarURL: string;
						url: string;
						isPro: boolean;
					};
				}>;
				invites: Array<{
					__typename?: 'Invite';
					_id: string;
					email?: string | null;
					user?: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					} | null;
				}>;
			}>;
		};
		settings: {
			__typename?: 'AuthSettings';
			_id: string;
			email: string;
			workEmail?: string | null;
			isBeta: boolean;
			isModerator: boolean;
			gridSpacingRatio: number;
			gridColumnsRatio: number;
			onboard: boolean;
			dropboxLinked: boolean;
			darkMode: boolean;
			emailNotifications: boolean;
			emailNewsletter: boolean;
			itemsSortMethod?: ItemsSortMethod | null;
			autoPlayGIFs: boolean;
			fitToScreen: boolean;
			pinterestUsername?: string | null;
			needsUpgradeReason?: NeedsUpgradeReason | null;
			unreadNotificationsCount: number;
			featureFlags: Array<string>;
			maxBoardCollaborators: number;
			maxTeamUsers: number;
			feedBannerExpanded: boolean;
			megaphones: Array<{
				__typename?: 'Megaphone';
				_id: string;
				title: string;
				body: string;
				ctaLabel?: string | null;
			}>;
			subscriptions: Array<{
				__typename?: 'UserSubscription';
				type: SubscriptionType;
				status: SubscriptionStatus;
				canManage: boolean;
				provider?: PaymentProvider | null;
				planID?: string | null;
				subscriptionEndDate?: Date | null;
				needsRenewSince?: Date | null;
				autoRenew: boolean;
				cardLast4?: string | null;
				cardBrandID?: CardBrand | null;
				isCardExpired: boolean;
				maxSeats: number;
			}>;
		};
		team?: {
			__typename?: 'Team';
			_id: string;
			role: TeamUserRole;
			name: string;
			email: string;
			usersCount: number;
			feedBoardID?: string | null;
			notifyUsersOnComment: boolean;
			autoFollowTeamOwners: boolean;
			teamFeedAsHomepage: boolean;
		} | null;
	};
};

export type LoginSocialMutationMutationVariables = Exact<{
	input: LoginSocialInput;
}>;

export type LoginSocialMutationMutation = {
	__typename?: 'Mutation';
	loginSocial: {
		__typename?: 'LoginSocialResponse';
		needsCompletion: boolean;
		partialInput?: {
			__typename?: 'LoginSocialPartialInput';
			provider: SocialLoginProvider;
			accessToken?: string | null;
			id?: string | null;
			name?: string | null;
			email?: string | null;
			username?: string | null;
			pictureURL?: string | null;
		} | null;
		auth?: {
			__typename?: 'Auth';
			token: string;
			user: {
				__typename?: 'User';
				_id: string;
				username: string;
				name: string;
				role?: string | null;
				website?: string | null;
				instagram?: string | null;
				twitter?: string | null;
				url: string;
				avatarURL: string;
				isPro: boolean;
				itemsCount: number;
				boardsCount: number;
				followingCount: number;
				followersCount: number;
				canFollow: boolean;
				isFollowing: boolean;
				isFollowingBack: boolean;
				company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
				ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
				boards: Array<{
					__typename?: 'Board';
					_id: string;
					name: string;
					slug: string;
					url: string;
					isPrivate: boolean;
					itemsCount: number;
					lastItemAddedOrder?: number | null;
					role?: BoardUserRole | null;
					ownershipType: BoardOwnershipType;
					acceptURL?: string | null;
					rejectURL?: string | null;
					publicURL?: string | null;
					isTeamEditorAnEditor?: boolean | null;
					thumbnails: Array<{
						__typename?: 'Asset';
						_id: string;
						image: { __typename?: 'AssetImage'; thumbnail: string };
					}>;
					ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
					collaborators: Array<{
						__typename?: 'BoardCollaborator';
						_id: string;
						role: BoardUserRole;
						user: {
							__typename?: 'User';
							_id: string;
							username: string;
							name: string;
							avatarURL: string;
							url: string;
							isPro: boolean;
						};
					}>;
					invites: Array<{
						__typename?: 'Invite';
						_id: string;
						email?: string | null;
						user?: {
							__typename?: 'User';
							_id: string;
							name: string;
							username: string;
							url: string;
							avatarURL: string;
						} | null;
					}>;
				}>;
			};
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				email: string;
				workEmail?: string | null;
				isBeta: boolean;
				isModerator: boolean;
				gridSpacingRatio: number;
				gridColumnsRatio: number;
				onboard: boolean;
				dropboxLinked: boolean;
				darkMode: boolean;
				emailNotifications: boolean;
				emailNewsletter: boolean;
				itemsSortMethod?: ItemsSortMethod | null;
				autoPlayGIFs: boolean;
				fitToScreen: boolean;
				pinterestUsername?: string | null;
				needsUpgradeReason?: NeedsUpgradeReason | null;
				unreadNotificationsCount: number;
				featureFlags: Array<string>;
				maxBoardCollaborators: number;
				maxTeamUsers: number;
				feedBannerExpanded: boolean;
				megaphones: Array<{
					__typename?: 'Megaphone';
					_id: string;
					title: string;
					body: string;
					ctaLabel?: string | null;
				}>;
				subscriptions: Array<{
					__typename?: 'UserSubscription';
					type: SubscriptionType;
					status: SubscriptionStatus;
					canManage: boolean;
					provider?: PaymentProvider | null;
					planID?: string | null;
					subscriptionEndDate?: Date | null;
					needsRenewSince?: Date | null;
					autoRenew: boolean;
					cardLast4?: string | null;
					cardBrandID?: CardBrand | null;
					isCardExpired: boolean;
					maxSeats: number;
				}>;
			};
			team?: {
				__typename?: 'Team';
				_id: string;
				role: TeamUserRole;
				name: string;
				email: string;
				usersCount: number;
				feedBoardID?: string | null;
				notifyUsersOnComment: boolean;
				autoFollowTeamOwners: boolean;
				teamFeedAsHomepage: boolean;
			} | null;
		} | null;
	};
};

export type LogoutMutationMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutationMutation = { __typename?: 'Mutation'; logout: boolean };

export type NewBoardMutationMutationVariables = Exact<{
	input: NewBoardInput;
}>;

export type NewBoardMutationMutation = {
	__typename?: 'Mutation';
	newBoard: {
		__typename?: 'NewBoardResponse';
		auth: {
			__typename?: 'Auth';
			user: {
				__typename?: 'User';
				_id: string;
				boardsCount: number;
				itemsCount: number;
				boards: Array<{
					__typename?: 'Board';
					_id: string;
					name: string;
					slug: string;
					url: string;
					isPrivate: boolean;
					itemsCount: number;
					lastItemAddedOrder?: number | null;
					role?: BoardUserRole | null;
					ownershipType: BoardOwnershipType;
					acceptURL?: string | null;
					rejectURL?: string | null;
					publicURL?: string | null;
					isTeamEditorAnEditor?: boolean | null;
					thumbnails: Array<{
						__typename?: 'Asset';
						_id: string;
						image: { __typename?: 'AssetImage'; thumbnail: string };
					}>;
					ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
					collaborators: Array<{
						__typename?: 'BoardCollaborator';
						_id: string;
						role: BoardUserRole;
						user: {
							__typename?: 'User';
							_id: string;
							username: string;
							name: string;
							avatarURL: string;
							url: string;
							isPro: boolean;
						};
					}>;
					invites: Array<{
						__typename?: 'Invite';
						_id: string;
						email?: string | null;
						user?: {
							__typename?: 'User';
							_id: string;
							name: string;
							username: string;
							url: string;
							avatarURL: string;
						} | null;
					}>;
				}>;
			};
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				needsUpgradeReason?: NeedsUpgradeReason | null;
			};
		};
		board: { __typename?: 'Board'; _id: string; url: string };
		items?: Array<{
			__typename?: 'Item';
			_id: string;
			shortID: string;
			url: string;
			createdAt?: Date | null;
			name?: string | null;
			pageURL?: string | null;
			sourceDisplayURL?: string | null;
			sourceURL?: string | null;
			isPrivate: boolean;
			isOwner: boolean;
			user: { __typename?: 'User'; _id: string; name: string; url: string };
			asset: {
				__typename?: 'Asset';
				_id: string;
				isSaved: boolean;
				savedCount: number;
				viewsCount: number;
				type: AssetType;
				image: {
					__typename?: 'AssetImage';
					thumbnail: string;
					original: string;
					ratio: number;
					width: number;
					height: number;
				};
				colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
				ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
			};
		}> | null;
	};
};

export type NewCommentMutationMutationVariables = Exact<{
	input: NewCommentInput;
}>;

export type NewCommentMutationMutation = {
	__typename?: 'Mutation';
	newComment: {
		__typename?: 'NewCommentResponse';
		asset: {
			__typename?: 'Asset';
			_id: string;
			comments: Array<{
				__typename?: 'Comment';
				_id: string;
				createdAt: Date;
				content: string;
				isTeamOnly: boolean;
				isLiked: boolean;
				likesCount: number;
				user: {
					__typename?: 'User';
					_id: string;
					name: string;
					username: string;
					url: string;
					avatarURL: string;
				};
				parent?: { __typename?: 'Comment'; _id: string } | null;
				position?: { __typename?: 'Position'; x: number; y: number } | null;
				userMentions?: Array<{
					__typename?: 'UserMention';
					indices: Array<number>;
					user: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					};
				}> | null;
			}>;
		};
	};
};

export type NewListMutationMutationVariables = Exact<{
	input: NewListInput;
}>;

export type NewListMutationMutation = {
	__typename?: 'Mutation';
	newList: { __typename?: 'List'; url: string };
};

export type NewTeamMutationMutationVariables = Exact<{
	input: NewTeamInput;
}>;

export type NewTeamMutationMutation = {
	__typename?: 'Mutation';
	newTeam: {
		__typename?: 'NewTeamResponse';
		team: { __typename?: 'Team'; _id: string };
		auth: {
			__typename?: 'Auth';
			team?: {
				__typename?: 'Team';
				_id: string;
				role: TeamUserRole;
				name: string;
				email: string;
				usersCount: number;
				feedBoardID?: string | null;
				notifyUsersOnComment: boolean;
				autoFollowTeamOwners: boolean;
				teamFeedAsHomepage: boolean;
			} | null;
		};
	};
};

export type NotificationsQueryQueryVariables = Exact<{
	limit?: InputMaybe<Scalars['Int']>;
}>;

export type NotificationsQueryQuery = {
	__typename?: 'Query';
	notifications: Array<{
		__typename?: 'Notification';
		_id: string;
		createdAt: Date;
		type: string;
		relatedUser?: {
			__typename?: 'User';
			_id: string;
			username: string;
			name: string;
			avatarURL: string;
			url: string;
			isPro: boolean;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
		} | null;
		relatedItem?: {
			__typename?: 'Item';
			_id: string;
			url: string;
			shortID: string;
			asset: {
				__typename?: 'Asset';
				image: { __typename?: 'AssetImage'; thumbnail: string };
				colors: Array<{ __typename?: 'AssetColor'; color: string }>;
			};
		} | null;
		relatedBoard?: {
			__typename?: 'Board';
			_id: string;
			name: string;
			slug: string;
			url: string;
			acceptURL?: string | null;
			rejectURL?: string | null;
		} | null;
		relatedTeam?: {
			__typename?: 'Team';
			_id: string;
			name: string;
			acceptURL?: string | null;
			rejectURL?: string | null;
		} | null;
		relatedComment?: {
			__typename?: 'Comment';
			_id: string;
			content: string;
			isTeamOnly: boolean;
			user: {
				__typename?: 'User';
				_id: string;
				username: string;
				name: string;
				avatarURL: string;
				url: string;
			};
		} | null;
	}>;
};

export type PopularItemsQueryQueryVariables = Exact<{
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type PopularItemsQueryQuery = {
	__typename?: 'Query';
	popularItems: {
		__typename?: 'PaginatedResultItems';
		items: Array<{
			__typename?: 'Item';
			_id: string;
			shortID: string;
			url: string;
			createdAt?: Date | null;
			name?: string | null;
			pageURL?: string | null;
			sourceDisplayURL?: string | null;
			sourceURL?: string | null;
			isPrivate: boolean;
			isOwner: boolean;
			user: { __typename?: 'User'; _id: string; name: string; url: string };
			asset: {
				__typename?: 'Asset';
				_id: string;
				isSaved: boolean;
				savedCount: number;
				viewsCount: number;
				type: AssetType;
				image: {
					__typename?: 'AssetImage';
					thumbnail: string;
					original: string;
					ratio: number;
					width: number;
					height: number;
				};
				colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
				ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
			};
		}>;
		pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
	};
};

export type PrivateBoardItemsQueryQueryVariables = Exact<{
	userID: Scalars['ID'];
	privateKey: Scalars['NonEmptyString'];
	limit?: InputMaybe<Scalars['Int']>;
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type PrivateBoardItemsQueryQuery = {
	__typename?: 'Query';
	boardByPrivateKey: {
		__typename?: 'Board';
		items: {
			__typename?: 'PaginatedResultItems';
			items: Array<{
				__typename?: 'Item';
				_id: string;
				shortID: string;
				url: string;
				createdAt?: Date | null;
				name?: string | null;
				pageURL?: string | null;
				sourceDisplayURL?: string | null;
				sourceURL?: string | null;
				isPrivate: boolean;
				isOwner: boolean;
				user: { __typename?: 'User'; _id: string; name: string; url: string };
				asset: {
					__typename?: 'Asset';
					_id: string;
					isSaved: boolean;
					savedCount: number;
					viewsCount: number;
					type: AssetType;
					image: {
						__typename?: 'AssetImage';
						thumbnail: string;
						original: string;
						ratio: number;
						width: number;
						height: number;
					};
					colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
					ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
				};
			}>;
			pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
		};
	};
};

export type PrivateBoardQueryQueryVariables = Exact<{
	userID: Scalars['ID'];
	privateKey: Scalars['NonEmptyString'];
}>;

export type PrivateBoardQueryQuery = {
	__typename?: 'Query';
	boardByPrivateKey: {
		__typename?: 'Board';
		_id: string;
		name: string;
		slug: string;
		url: string;
		isPrivate: boolean;
		itemsCount: number;
		lastItemAddedOrder?: number | null;
		role?: BoardUserRole | null;
		ownershipType: BoardOwnershipType;
		acceptURL?: string | null;
		rejectURL?: string | null;
		publicURL?: string | null;
		isTeamEditorAnEditor?: boolean | null;
		thumbnails: Array<{
			__typename?: 'Asset';
			_id: string;
			image: { __typename?: 'AssetImage'; thumbnail: string };
		}>;
		ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
		collaborators: Array<{
			__typename?: 'BoardCollaborator';
			_id: string;
			role: BoardUserRole;
			user: {
				__typename?: 'User';
				_id: string;
				username: string;
				name: string;
				avatarURL: string;
				url: string;
				isPro: boolean;
			};
		}>;
		invites: Array<{
			__typename?: 'Invite';
			_id: string;
			email?: string | null;
			user?: {
				__typename?: 'User';
				_id: string;
				name: string;
				username: string;
				url: string;
				avatarURL: string;
			} | null;
		}>;
	};
};

export type RegenerateBoardPublicUrlMutationMutationVariables = Exact<{
	input: RegenerateBoardPublicUrlInput;
}>;

export type RegenerateBoardPublicUrlMutationMutation = {
	__typename?: 'Mutation';
	regenerateBoardPublicURL: { __typename?: 'Board'; _id: string; publicURL?: string | null };
};

export type RelatedItemsQueryQueryVariables = Exact<{
	shortID: Scalars['NonEmptyString'];
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type RelatedItemsQueryQuery = {
	__typename?: 'Query';
	relatedItems: {
		__typename?: 'PaginatedResultItems';
		items: Array<{
			__typename?: 'Item';
			_id: string;
			shortID: string;
			url: string;
			createdAt?: Date | null;
			name?: string | null;
			pageURL?: string | null;
			sourceDisplayURL?: string | null;
			sourceURL?: string | null;
			isPrivate: boolean;
			isOwner: boolean;
			user: { __typename?: 'User'; _id: string; name: string; url: string };
			asset: {
				__typename?: 'Asset';
				_id: string;
				isSaved: boolean;
				savedCount: number;
				viewsCount: number;
				type: AssetType;
				image: {
					__typename?: 'AssetImage';
					thumbnail: string;
					original: string;
					ratio: number;
					width: number;
					height: number;
				};
				colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
				ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
			};
		}>;
		pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
	};
};

export type RemoveItemsFromBoardMutationMutationVariables = Exact<{
	input: RemoveItemsFromBoardInput;
}>;

export type RemoveItemsFromBoardMutationMutation = {
	__typename?: 'Mutation';
	removeItemsFromBoard: {
		__typename?: 'AddItemsToBoardResponse';
		auth: {
			__typename?: 'Auth';
			user: { __typename?: 'User'; _id: string; itemsCount: number };
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				needsUpgradeReason?: NeedsUpgradeReason | null;
			};
		};
		board: {
			__typename?: 'Board';
			_id: string;
			itemsCount: number;
			lastItemAddedOrder?: number | null;
			thumbnails: Array<{
				__typename?: 'Asset';
				image: { __typename?: 'AssetImage'; thumbnail: string };
			}>;
		};
		assets: Array<{
			__typename?: 'Asset';
			_id: string;
			ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
		}>;
	};
};

export type RemoveUserFromBoardMutationMutationVariables = Exact<{
	input: RemoveUserFromBoardInput;
}>;

export type RemoveUserFromBoardMutationMutation = {
	__typename?: 'Mutation';
	removeUserFromBoard: {
		__typename?: 'Board';
		_id: string;
		name: string;
		slug: string;
		url: string;
		isPrivate: boolean;
		itemsCount: number;
		lastItemAddedOrder?: number | null;
		role?: BoardUserRole | null;
		ownershipType: BoardOwnershipType;
		acceptURL?: string | null;
		rejectURL?: string | null;
		publicURL?: string | null;
		isTeamEditorAnEditor?: boolean | null;
		thumbnails: Array<{
			__typename?: 'Asset';
			_id: string;
			image: { __typename?: 'AssetImage'; thumbnail: string };
		}>;
		ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
		collaborators: Array<{
			__typename?: 'BoardCollaborator';
			_id: string;
			role: BoardUserRole;
			user: {
				__typename?: 'User';
				_id: string;
				username: string;
				name: string;
				avatarURL: string;
				url: string;
				isPro: boolean;
			};
		}>;
		invites: Array<{
			__typename?: 'Invite';
			_id: string;
			email?: string | null;
			user?: {
				__typename?: 'User';
				_id: string;
				name: string;
				username: string;
				url: string;
				avatarURL: string;
			} | null;
		}>;
	};
};

export type RemoveUserFromTeamMutationMutationVariables = Exact<{
	input: RemoveUserFromTeamInput;
}>;

export type RemoveUserFromTeamMutationMutation = {
	__typename?: 'Mutation';
	removeUserFromTeam: {
		__typename?: 'Team';
		_id: string;
		usersCount: number;
		users: Array<{
			__typename?: 'TeamUser';
			_id: string;
			email: string;
			workEmail?: string | null;
			lastActivityAt: Date;
			role: TeamUserRole;
			user: {
				__typename?: 'User';
				_id: string;
				name: string;
				avatarURL: string;
				username: string;
				url: string;
				isPro: boolean;
				canFollow: boolean;
				isFollowing: boolean;
				isFollowingBack: boolean;
				boardsCount: number;
				itemsCount: number;
			};
		}>;
		invites: Array<{
			__typename?: 'Invite';
			_id: string;
			email?: string | null;
			user?: {
				__typename?: 'User';
				_id: string;
				name: string;
				username: string;
				url: string;
				avatarURL: string;
			} | null;
		}>;
	};
};

export type ReorderItemsMutationMutationVariables = Exact<{
	input: ReorderItemsInput;
}>;

export type ReorderItemsMutationMutation = { __typename?: 'Mutation'; reorderItems: boolean };

export type ReportItemsMutationMutationVariables = Exact<{
	input: ReportItemsInput;
}>;

export type ReportItemsMutationMutation = {
	__typename?: 'Mutation';
	reportItems: { __typename?: 'ReportItemsResponse'; reportedItemIDs: Array<string> };
};

export type ResetPasswordMutationMutationVariables = Exact<{
	input: ResetPasswordInput;
}>;

export type ResetPasswordMutationMutation = {
	__typename?: 'Mutation';
	resetPassword: {
		__typename?: 'Auth';
		token: string;
		user: {
			__typename?: 'User';
			_id: string;
			username: string;
			name: string;
			role?: string | null;
			website?: string | null;
			instagram?: string | null;
			twitter?: string | null;
			url: string;
			avatarURL: string;
			isPro: boolean;
			itemsCount: number;
			boardsCount: number;
			followingCount: number;
			followersCount: number;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
			boards: Array<{
				__typename?: 'Board';
				_id: string;
				name: string;
				slug: string;
				url: string;
				isPrivate: boolean;
				itemsCount: number;
				lastItemAddedOrder?: number | null;
				role?: BoardUserRole | null;
				ownershipType: BoardOwnershipType;
				acceptURL?: string | null;
				rejectURL?: string | null;
				publicURL?: string | null;
				isTeamEditorAnEditor?: boolean | null;
				thumbnails: Array<{
					__typename?: 'Asset';
					_id: string;
					image: { __typename?: 'AssetImage'; thumbnail: string };
				}>;
				ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
				collaborators: Array<{
					__typename?: 'BoardCollaborator';
					_id: string;
					role: BoardUserRole;
					user: {
						__typename?: 'User';
						_id: string;
						username: string;
						name: string;
						avatarURL: string;
						url: string;
						isPro: boolean;
					};
				}>;
				invites: Array<{
					__typename?: 'Invite';
					_id: string;
					email?: string | null;
					user?: {
						__typename?: 'User';
						_id: string;
						name: string;
						username: string;
						url: string;
						avatarURL: string;
					} | null;
				}>;
			}>;
		};
		settings: {
			__typename?: 'AuthSettings';
			_id: string;
			email: string;
			workEmail?: string | null;
			isBeta: boolean;
			isModerator: boolean;
			gridSpacingRatio: number;
			gridColumnsRatio: number;
			onboard: boolean;
			dropboxLinked: boolean;
			darkMode: boolean;
			emailNotifications: boolean;
			emailNewsletter: boolean;
			itemsSortMethod?: ItemsSortMethod | null;
			autoPlayGIFs: boolean;
			fitToScreen: boolean;
			pinterestUsername?: string | null;
			needsUpgradeReason?: NeedsUpgradeReason | null;
			unreadNotificationsCount: number;
			featureFlags: Array<string>;
			maxBoardCollaborators: number;
			maxTeamUsers: number;
			feedBannerExpanded: boolean;
			megaphones: Array<{
				__typename?: 'Megaphone';
				_id: string;
				title: string;
				body: string;
				ctaLabel?: string | null;
			}>;
			subscriptions: Array<{
				__typename?: 'UserSubscription';
				type: SubscriptionType;
				status: SubscriptionStatus;
				canManage: boolean;
				provider?: PaymentProvider | null;
				planID?: string | null;
				subscriptionEndDate?: Date | null;
				needsRenewSince?: Date | null;
				autoRenew: boolean;
				cardLast4?: string | null;
				cardBrandID?: CardBrand | null;
				isCardExpired: boolean;
				maxSeats: number;
			}>;
		};
		team?: {
			__typename?: 'Team';
			_id: string;
			role: TeamUserRole;
			name: string;
			email: string;
			usersCount: number;
			feedBoardID?: string | null;
			notifyUsersOnComment: boolean;
			autoFollowTeamOwners: boolean;
			teamFeedAsHomepage: boolean;
		} | null;
	};
};

export type SaveItemsMutationMutationVariables = Exact<{
	input: SaveItemsInput;
}>;

export type SaveItemsMutationMutation = {
	__typename?: 'Mutation';
	saveItems: {
		__typename?: 'SaveItemsResponse';
		auth: {
			__typename?: 'Auth';
			user: { __typename?: 'User'; _id: string; itemsCount: number };
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				needsUpgradeReason?: NeedsUpgradeReason | null;
			};
		};
		board?: {
			__typename?: 'Board';
			_id: string;
			itemsCount: number;
			lastItemAddedOrder?: number | null;
			thumbnails: Array<{
				__typename?: 'Asset';
				image: { __typename?: 'AssetImage'; thumbnail: string };
			}>;
		} | null;
		assets: Array<{
			__typename?: 'Asset';
			_id: string;
			isSaved: boolean;
			savedCount: number;
			ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
		}>;
	};
};

export type SearchItemsQueryQueryVariables = Exact<{
	query: Scalars['NonEmptyString'];
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type SearchItemsQueryQuery = {
	__typename?: 'Query';
	searchItems: {
		__typename?: 'PaginatedResultItems';
		items: Array<{
			__typename?: 'Item';
			_id: string;
			shortID: string;
			url: string;
			createdAt?: Date | null;
			name?: string | null;
			pageURL?: string | null;
			sourceDisplayURL?: string | null;
			sourceURL?: string | null;
			isPrivate: boolean;
			isOwner: boolean;
			user: { __typename?: 'User'; _id: string; name: string; url: string };
			asset: {
				__typename?: 'Asset';
				_id: string;
				isSaved: boolean;
				savedCount: number;
				viewsCount: number;
				type: AssetType;
				image: {
					__typename?: 'AssetImage';
					thumbnail: string;
					original: string;
					ratio: number;
					width: number;
					height: number;
				};
				colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
				ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
			};
		}>;
		pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
	};
};

export type SearchUsersQueryQueryVariables = Exact<{
	query: Scalars['NonEmptyString'];
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type SearchUsersQueryQuery = {
	__typename?: 'Query';
	searchUsers: {
		__typename?: 'PaginatedResultUsers';
		items: Array<{
			__typename?: 'User';
			_id: string;
			name: string;
			username: string;
			url: string;
			role?: string | null;
			avatarURL: string;
			isPro: boolean;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			items: {
				__typename?: 'PaginatedResultItems';
				items: Array<{
					__typename?: 'Item';
					_id: string;
					shortID: string;
					url: string;
					asset: {
						__typename?: 'Asset';
						_id: string;
						image: { __typename?: 'AssetImage'; thumbnail: string };
						colors: Array<{ __typename?: 'AssetColor'; color: string }>;
					};
				}>;
			};
		}>;
		pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
	};
};

export type SelectSiteTemplateMutationMutationVariables = Exact<{
	input: SelectSiteTemplateInput;
}>;

export type SelectSiteTemplateMutationMutation = {
	__typename?: 'Mutation';
	selectSiteTemplate: { __typename?: 'Site'; _id: string };
};

export type SideItemQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SideItemQueryQuery = {
	__typename?: 'Query';
	sideItem?: {
		__typename?: 'Item';
		_id: string;
		asset: {
			__typename?: 'Asset';
			_id: string;
			colors: Array<{ __typename?: 'AssetColor'; color: string }>;
			image: { __typename?: 'AssetImage'; original: string; ratio: number };
		};
		user: {
			__typename?: 'User';
			username: string;
			name: string;
			avatarURL: string;
			url: string;
		};
	} | null;
};

export type SiteQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SiteQueryQuery = {
	__typename?: 'Query';
	site?: {
		__typename?: 'Site';
		_id: string;
		url: string;
		template: SiteTemplate;
		sourceID?: string | null;
		title?: string | null;
		fontFamily?: string | null;
		content1?: string | null;
		content2?: string | null;
		backgroundColor?: string | null;
		textColor?: string | null;
		settings?: any | null;
		templateData?: any | null;
		domains?: Array<{
			__typename?: 'SiteDomain';
			domain: string;
			status: DomainVerificationStatus;
		}> | null;
	} | null;
};

export type StartStripeCheckoutMutationMutationVariables = Exact<{
	input: StartStripeCheckoutInput;
}>;

export type StartStripeCheckoutMutationMutation = {
	__typename?: 'Mutation';
	startStripeCheckout: {
		__typename?: 'StartStripeCheckoutResponse';
		sessionID: string;
		url: string;
	};
};

export type StartStripeUpdateBillingMethodMutationMutationVariables = Exact<{
	input: StartStripeUpdateBillingMethodInput;
}>;

export type StartStripeUpdateBillingMethodMutationMutation = {
	__typename?: 'Mutation';
	startStripeUpdateBillingMethod: {
		__typename?: 'StartStripeCheckoutResponse';
		sessionID: string;
		url: string;
	};
};

export type SuggestedInvitedUsersQueryQueryVariables = Exact<{
	type: InviteType;
	resourceID?: InputMaybe<Scalars['ID']>;
	query?: InputMaybe<Scalars['String']>;
}>;

export type SuggestedInvitedUsersQueryQuery = {
	__typename?: 'Query';
	suggestedInvitedUsers: Array<{
		__typename?: 'User';
		_id: string;
		username: string;
		name: string;
		avatarURL: string;
		url: string;
		isPro: boolean;
	}>;
};

export type SuggestedMentionedUsersQueryQueryVariables = Exact<{
	type?: InputMaybe<MentionsType>;
	query?: InputMaybe<Scalars['String']>;
}>;

export type SuggestedMentionedUsersQueryQuery = {
	__typename?: 'Query';
	suggestedMentionedUsers: Array<{
		__typename?: 'User';
		_id: string;
		username: string;
		name: string;
		avatarURL: string;
	}>;
};

export type SuggestedUsersQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SuggestedUsersQueryQuery = {
	__typename?: 'Query';
	suggestedUsers: Array<{
		__typename?: 'User';
		_id: string;
		name: string;
		username: string;
		url: string;
		avatarURL: string;
		isPro: boolean;
		canFollow: boolean;
		isFollowing: boolean;
		isFollowingBack: boolean;
		items: {
			__typename?: 'PaginatedResultItems';
			items: Array<{
				__typename?: 'Item';
				_id: string;
				asset: {
					__typename?: 'Asset';
					_id: string;
					image: { __typename?: 'AssetImage'; thumbnail: string };
					colors: Array<{ __typename?: 'AssetColor'; color: string }>;
				};
			}>;
		};
	}>;
};

export type TeamFeedItemsQueryQueryVariables = Exact<{
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type TeamFeedItemsQueryQuery = {
	__typename?: 'Query';
	auth?: {
		__typename?: 'Auth';
		team?: {
			__typename?: 'Team';
			feedItems: {
				__typename?: 'PaginatedResultItems';
				items: Array<{
					__typename?: 'Item';
					_id: string;
					shortID: string;
					url: string;
					createdAt?: Date | null;
					name?: string | null;
					pageURL?: string | null;
					sourceDisplayURL?: string | null;
					sourceURL?: string | null;
					isPrivate: boolean;
					isOwner: boolean;
					user: { __typename?: 'User'; _id: string; name: string; url: string };
					asset: {
						__typename?: 'Asset';
						_id: string;
						isSaved: boolean;
						savedCount: number;
						viewsCount: number;
						type: AssetType;
						image: {
							__typename?: 'AssetImage';
							thumbnail: string;
							original: string;
							ratio: number;
							width: number;
							height: number;
						};
						colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
						ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
					};
				}>;
				pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
			};
		} | null;
	} | null;
};

export type TeamQueryQueryVariables = Exact<{ [key: string]: never }>;

export type TeamQueryQuery = {
	__typename?: 'Query';
	auth?: {
		__typename?: 'Auth';
		team?: {
			__typename?: 'Team';
			_id: string;
			role: TeamUserRole;
			name: string;
			email: string;
			usersCount: number;
			feedBoardID?: string | null;
			notifyUsersOnComment: boolean;
			autoFollowTeamOwners: boolean;
			teamFeedAsHomepage: boolean;
			users: Array<{
				__typename?: 'TeamUser';
				_id: string;
				email: string;
				workEmail?: string | null;
				lastActivityAt: Date;
				role: TeamUserRole;
				user: {
					__typename?: 'User';
					_id: string;
					name: string;
					avatarURL: string;
					username: string;
					url: string;
					isPro: boolean;
					canFollow: boolean;
					isFollowing: boolean;
					isFollowingBack: boolean;
					boardsCount: number;
					itemsCount: number;
				};
			}>;
			invites: Array<{
				__typename?: 'Invite';
				_id: string;
				email?: string | null;
				user?: {
					__typename?: 'User';
					_id: string;
					name: string;
					username: string;
					url: string;
					avatarURL: string;
				} | null;
			}>;
		} | null;
	} | null;
};

export type ToggleFollowUserMutationMutationVariables = Exact<{
	input: ToggleFollowUserInput;
}>;

export type ToggleFollowUserMutationMutation = {
	__typename?: 'Mutation';
	toggleFollowUser: {
		__typename?: 'ToggleFollowUserResponse';
		user: { __typename?: 'User'; _id: string; isFollowing: boolean };
		auth: {
			__typename?: 'Auth';
			user: { __typename?: 'User'; _id: string; followingCount: number };
		};
	};
};

export type ToggleLikeCommentMutationMutationVariables = Exact<{
	input: ToggleLikeCommentInput;
}>;

export type ToggleLikeCommentMutationMutation = {
	__typename?: 'Mutation';
	toggleLikeComment: {
		__typename?: 'ToggleLikeCommentResponse';
		comment: { __typename?: 'Comment'; _id: string; isLiked: boolean; likesCount: number };
	};
};

export type TopSearchQueriesQueryQueryVariables = Exact<{ [key: string]: never }>;

export type TopSearchQueriesQueryQuery = {
	__typename?: 'Query';
	topSearchQueries: Array<{
		__typename?: 'TopSearchQuery';
		_id: string;
		query: string;
		asset: {
			__typename?: 'Asset';
			_id: string;
			image: { __typename?: 'AssetImage'; thumbnail: string };
			colors: Array<{ __typename?: 'AssetColor'; color: string }>;
		};
	}>;
};

export type TrackUpgradeClickMutationMutationVariables = Exact<{
	input: TrackUpgradeClickInput;
}>;

export type TrackUpgradeClickMutationMutation = {
	__typename?: 'Mutation';
	trackUpgradeClick: boolean;
};

export type UpdateAvatarMutationMutationVariables = Exact<{
	input: UpdateAvatarInput;
}>;

export type UpdateAvatarMutationMutation = {
	__typename?: 'Mutation';
	updateAvatar: {
		__typename?: 'Auth';
		_id: string;
		user: { __typename?: 'User'; _id: string; avatarURL: string };
	};
};

export type UpdateBillingAutoRenewMutationMutationVariables = Exact<{
	input: UpdateBillingAutoRenewInput;
}>;

export type UpdateBillingAutoRenewMutationMutation = {
	__typename?: 'Mutation';
	updateBillingAutoRenew: {
		__typename?: 'Auth';
		token: string;
		user: { __typename?: 'User'; _id: string; isPro: boolean };
		settings: {
			__typename?: 'AuthSettings';
			_id: string;
			needsUpgradeReason?: NeedsUpgradeReason | null;
			subscriptions: Array<{
				__typename?: 'UserSubscription';
				type: SubscriptionType;
				provider?: PaymentProvider | null;
				planID?: string | null;
				subscriptionEndDate?: Date | null;
				needsRenewSince?: Date | null;
				autoRenew: boolean;
				cardLast4?: string | null;
				cardBrandID?: CardBrand | null;
				isCardExpired: boolean;
			}>;
		};
	};
};

export type UpdateBillingPlanMutationMutationVariables = Exact<{
	input: UpdateBillingPlanInput;
}>;

export type UpdateBillingPlanMutationMutation = {
	__typename?: 'Mutation';
	updateBillingPlan: {
		__typename?: 'UpdateBillingPlanResponse';
		redirectURL?: string | null;
		auth: {
			__typename?: 'Auth';
			token: string;
			user: { __typename?: 'User'; _id: string; isPro: boolean };
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				needsUpgradeReason?: NeedsUpgradeReason | null;
				subscriptions: Array<{
					__typename?: 'UserSubscription';
					type: SubscriptionType;
					provider?: PaymentProvider | null;
					planID?: string | null;
					subscriptionEndDate?: Date | null;
					needsRenewSince?: Date | null;
					autoRenew: boolean;
					cardLast4?: string | null;
					cardBrandID?: CardBrand | null;
					isCardExpired: boolean;
				}>;
			};
		};
	};
};

export type UpdateBoardNameMutationMutationVariables = Exact<{
	input: UpdateBoardNameInput;
}>;

export type UpdateBoardNameMutationMutation = {
	__typename?: 'Mutation';
	updateBoardName: {
		__typename?: 'Board';
		_id: string;
		name: string;
		slug: string;
		url: string;
		isPrivate: boolean;
		itemsCount: number;
		lastItemAddedOrder?: number | null;
		role?: BoardUserRole | null;
		ownershipType: BoardOwnershipType;
		acceptURL?: string | null;
		rejectURL?: string | null;
		publicURL?: string | null;
		isTeamEditorAnEditor?: boolean | null;
		thumbnails: Array<{
			__typename?: 'Asset';
			_id: string;
			image: { __typename?: 'AssetImage'; thumbnail: string };
		}>;
		ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
		collaborators: Array<{
			__typename?: 'BoardCollaborator';
			_id: string;
			role: BoardUserRole;
			user: {
				__typename?: 'User';
				_id: string;
				username: string;
				name: string;
				avatarURL: string;
				url: string;
				isPro: boolean;
			};
		}>;
		invites: Array<{
			__typename?: 'Invite';
			_id: string;
			email?: string | null;
			user?: {
				__typename?: 'User';
				_id: string;
				name: string;
				username: string;
				url: string;
				avatarURL: string;
			} | null;
		}>;
	};
};

export type UpdateBoardPrivacyMutationMutationVariables = Exact<{
	input: UpdateBoardPrivacyInput;
}>;

export type UpdateBoardPrivacyMutationMutation = {
	__typename?: 'Mutation';
	updateBoardPrivacy: {
		__typename?: 'UpdateBoardPrivacyResponse';
		board: { __typename?: 'Board'; _id: string; isPrivate: boolean };
		auth: {
			__typename?: 'Auth';
			user: { __typename?: 'User'; _id: string; boardsCount: number; itemsCount: number };
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				needsUpgradeReason?: NeedsUpgradeReason | null;
			};
		};
	};
};

export type UpdateBoardUserRoleMutationMutationVariables = Exact<{
	input: UpdateBoardUserRoleInput;
}>;

export type UpdateBoardUserRoleMutationMutation = {
	__typename?: 'Mutation';
	updateBoardUserRole: {
		__typename?: 'Board';
		_id: string;
		collaborators: Array<{
			__typename?: 'BoardCollaborator';
			_id: string;
			role: BoardUserRole;
			user: {
				__typename?: 'User';
				_id: string;
				username: string;
				name: string;
				avatarURL: string;
				url: string;
				isPro: boolean;
			};
		}>;
	};
};

export type UpdateItemMutationMutationVariables = Exact<{
	input: UpdateItemInput;
}>;

export type UpdateItemMutationMutation = {
	__typename?: 'Mutation';
	updateItem: {
		__typename?: 'Item';
		_id: string;
		name?: string | null;
		pageURL?: string | null;
		sourceDisplayURL?: string | null;
		sourceURL?: string | null;
	};
};

export type UpdateItemsPrivacyMutationMutationVariables = Exact<{
	input: UpdateItemsPrivacyInput;
}>;

export type UpdateItemsPrivacyMutationMutation = {
	__typename?: 'Mutation';
	updateItemsPrivacy: {
		__typename?: 'UpdateItemsPrivacyResponse';
		items: Array<{ __typename?: 'Item'; _id: string; isPrivate: boolean }>;
		auth: {
			__typename?: 'Auth';
			user: { __typename?: 'User'; _id: string; itemsCount: number };
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				needsUpgradeReason?: NeedsUpgradeReason | null;
			};
		};
	};
};

export type UpdateNotifyTeamUsersOnCommentMutationMutationVariables = Exact<{
	input: Scalars['Boolean'];
}>;

export type UpdateNotifyTeamUsersOnCommentMutationMutation = {
	__typename?: 'Mutation';
	updateNotifyTeamUsersOnComment: {
		__typename?: 'Team';
		_id: string;
		notifyUsersOnComment: boolean;
	};
};

export type UpdateProfileMutationMutationVariables = Exact<{
	input: UpdateProfileInput;
}>;

export type UpdateProfileMutationMutation = {
	__typename?: 'Mutation';
	updateProfile: {
		__typename?: 'Auth';
		_id: string;
		token: string;
		user: {
			__typename?: 'User';
			_id: string;
			name: string;
			username: string;
			role?: string | null;
			website?: string | null;
			instagram?: string | null;
			twitter?: string | null;
			url: string;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
		};
		settings: {
			__typename?: 'AuthSettings';
			_id: string;
			email: string;
			workEmail?: string | null;
		};
	};
};

export type UpdateSiteMutationMutationVariables = Exact<{
	input: UpdateSiteInput;
}>;

export type UpdateSiteMutationMutation = {
	__typename?: 'Mutation';
	updateSite: {
		__typename?: 'Site';
		_id: string;
		url: string;
		template: SiteTemplate;
		sourceID?: string | null;
		title?: string | null;
		fontFamily?: string | null;
		content1?: string | null;
		content2?: string | null;
		backgroundColor?: string | null;
		textColor?: string | null;
		settings?: any | null;
		templateData?: any | null;
		domains?: Array<{
			__typename?: 'SiteDomain';
			domain: string;
			status: DomainVerificationStatus;
		}> | null;
	};
};

export type UpdateTeamFeedBoardMutationMutationVariables = Exact<{
	input: UpdateTeamFeedBoardInput;
}>;

export type UpdateTeamFeedBoardMutationMutation = {
	__typename?: 'Mutation';
	updateTeamFeedBoard: { __typename?: 'Team'; _id: string; feedBoardID?: string | null };
};

export type UpdateTeamMutationMutationVariables = Exact<{
	input: UpdateTeamInput;
}>;

export type UpdateTeamMutationMutation = {
	__typename?: 'Mutation';
	updateTeam: { __typename?: 'Team'; _id: string; name: string };
};

export type UpdateTeamUserRoleMutationMutationVariables = Exact<{
	input: UpdateTeamUserRoleInput;
}>;

export type UpdateTeamUserRoleMutationMutation = {
	__typename?: 'Mutation';
	updateTeamUserRole: { __typename?: 'TeamUser'; _id: string; role: TeamUserRole };
};

export type UpdateUsernameMutationMutationVariables = Exact<{
	input: UpdateUsernameInput;
}>;

export type UpdateUsernameMutationMutation = {
	__typename?: 'Mutation';
	updateUsername: {
		__typename?: 'UpdateUsernameResponse';
		auth: { __typename?: 'Auth'; user: { __typename?: 'User'; _id: string; username: string } };
		site: { __typename?: 'Site'; _id: string; url: string };
	};
};

export type UpgradeStatsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type UpgradeStatsQueryQuery = {
	__typename?: 'Query';
	upgradeStats: { __typename?: 'UpgradeStatsResponse'; imagesCount: number };
};

export type UploadItemFromRemoteMutationMutationVariables = Exact<{
	input: UploadItemFromRemoteInput;
}>;

export type UploadItemFromRemoteMutationMutation = {
	__typename?: 'Mutation';
	uploadItemFromRemote: {
		__typename?: 'UploadItemFromRemoteResponse';
		auth: {
			__typename?: 'Auth';
			user: { __typename?: 'User'; _id: string; itemsCount: number };
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				needsUpgradeReason?: NeedsUpgradeReason | null;
			};
		};
		item: {
			__typename?: 'Item';
			_id: string;
			shortID: string;
			url: string;
			createdAt?: Date | null;
			name?: string | null;
			pageURL?: string | null;
			sourceDisplayURL?: string | null;
			sourceURL?: string | null;
			isPrivate: boolean;
			isOwner: boolean;
			user: { __typename?: 'User'; _id: string; name: string; url: string };
			asset: {
				__typename?: 'Asset';
				_id: string;
				isSaved: boolean;
				savedCount: number;
				viewsCount: number;
				type: AssetType;
				image: {
					__typename?: 'AssetImage';
					thumbnail: string;
					original: string;
					ratio: number;
					width: number;
					height: number;
				};
				colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
				ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
			};
		};
	};
};

export type UploadItemMutationMutationVariables = Exact<{
	input: UploadItemInput;
}>;

export type UploadItemMutationMutation = {
	__typename?: 'Mutation';
	uploadItem: {
		__typename?: 'UploadItemResponse';
		auth: {
			__typename?: 'Auth';
			user: { __typename?: 'User'; _id: string; itemsCount: number };
			settings: {
				__typename?: 'AuthSettings';
				_id: string;
				needsUpgradeReason?: NeedsUpgradeReason | null;
			};
		};
		board?: {
			__typename?: 'Board';
			_id: string;
			itemsCount: number;
			lastItemAddedOrder?: number | null;
			thumbnails: Array<{
				__typename?: 'Asset';
				image: { __typename?: 'AssetImage'; thumbnail: string };
			}>;
		} | null;
		item: {
			__typename?: 'Item';
			_id: string;
			shortID: string;
			url: string;
			createdAt?: Date | null;
			name?: string | null;
			pageURL?: string | null;
			sourceDisplayURL?: string | null;
			sourceURL?: string | null;
			isPrivate: boolean;
			isOwner: boolean;
			user: { __typename?: 'User'; _id: string; name: string; url: string };
			asset: {
				__typename?: 'Asset';
				_id: string;
				isSaved: boolean;
				savedCount: number;
				viewsCount: number;
				type: AssetType;
				image: {
					__typename?: 'AssetImage';
					thumbnail: string;
					original: string;
					ratio: number;
					width: number;
					height: number;
				};
				colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
				ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
			};
		};
	};
};

export type UserCheckQueryVariables = Exact<{
	usernameOrEmail: Scalars['NonEmptyString'];
}>;

export type UserCheckQuery = { __typename?: 'Query'; userCheck: boolean };

export type UserFollowersQueryQueryVariables = Exact<{
	userID: Scalars['ID'];
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type UserFollowersQueryQuery = {
	__typename?: 'Query';
	userFollowers: {
		__typename?: 'PaginatedResultUsers';
		items: Array<{
			__typename?: 'User';
			_id: string;
			name: string;
			username: string;
			url: string;
			role?: string | null;
			avatarURL: string;
			isPro: boolean;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			items: {
				__typename?: 'PaginatedResultItems';
				items: Array<{
					__typename?: 'Item';
					_id: string;
					shortID: string;
					url: string;
					asset: {
						__typename?: 'Asset';
						_id: string;
						image: { __typename?: 'AssetImage'; thumbnail: string };
						colors: Array<{ __typename?: 'AssetColor'; color: string }>;
					};
				}>;
			};
		}>;
		pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
	};
};

export type UserFollowingQueryQueryVariables = Exact<{
	userID: Scalars['ID'];
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type UserFollowingQueryQuery = {
	__typename?: 'Query';
	userFollowing: {
		__typename?: 'PaginatedResultUsers';
		items: Array<{
			__typename?: 'User';
			_id: string;
			name: string;
			username: string;
			url: string;
			role?: string | null;
			avatarURL: string;
			isPro: boolean;
			canFollow: boolean;
			isFollowing: boolean;
			isFollowingBack: boolean;
			company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
			items: {
				__typename?: 'PaginatedResultItems';
				items: Array<{
					__typename?: 'Item';
					_id: string;
					shortID: string;
					url: string;
					asset: {
						__typename?: 'Asset';
						_id: string;
						image: { __typename?: 'AssetImage'; thumbnail: string };
						colors: Array<{ __typename?: 'AssetColor'; color: string }>;
					};
				}>;
			};
		}>;
		pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
	};
};

export type UserItemsQueryQueryVariables = Exact<{
	username: Scalars['NonEmptyString'];
	sortMethod?: InputMaybe<ItemsSortMethod>;
	limit?: InputMaybe<Scalars['Int']>;
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type UserItemsQueryQuery = {
	__typename?: 'Query';
	userByUsername: {
		__typename?: 'User';
		items: {
			__typename?: 'PaginatedResultItems';
			items: Array<{
				__typename?: 'Item';
				_id: string;
				shortID: string;
				url: string;
				createdAt?: Date | null;
				name?: string | null;
				pageURL?: string | null;
				sourceDisplayURL?: string | null;
				sourceURL?: string | null;
				isPrivate: boolean;
				isOwner: boolean;
				user: { __typename?: 'User'; _id: string; name: string; url: string };
				asset: {
					__typename?: 'Asset';
					_id: string;
					isSaved: boolean;
					savedCount: number;
					viewsCount: number;
					type: AssetType;
					image: {
						__typename?: 'AssetImage';
						thumbnail: string;
						original: string;
						ratio: number;
						width: number;
						height: number;
					};
					colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
					ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
				};
			}>;
			pageInfo: { __typename?: 'PaginatedResultPageInfo'; nextCursor?: string | null };
		};
	};
};

export type UserQueryQueryVariables = Exact<{
	username: Scalars['NonEmptyString'];
}>;

export type UserQueryQuery = {
	__typename?: 'Query';
	userByUsername: {
		__typename?: 'User';
		_id: string;
		username: string;
		name: string;
		role?: string | null;
		website?: string | null;
		instagram?: string | null;
		twitter?: string | null;
		url: string;
		avatarURL: string;
		isPro: boolean;
		itemsCount: number;
		boardsCount: number;
		followingCount: number;
		followersCount: number;
		canFollow: boolean;
		isFollowing: boolean;
		isFollowingBack: boolean;
		company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
		ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
		boards: Array<{
			__typename?: 'Board';
			_id: string;
			name: string;
			slug: string;
			url: string;
			isPrivate: boolean;
			itemsCount: number;
			lastItemAddedOrder?: number | null;
			role?: BoardUserRole | null;
			ownershipType: BoardOwnershipType;
			acceptURL?: string | null;
			rejectURL?: string | null;
			publicURL?: string | null;
			isTeamEditorAnEditor?: boolean | null;
			thumbnails: Array<{
				__typename?: 'Asset';
				_id: string;
				image: { __typename?: 'AssetImage'; thumbnail: string };
			}>;
			ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
			collaborators: Array<{
				__typename?: 'BoardCollaborator';
				_id: string;
				role: BoardUserRole;
				user: {
					__typename?: 'User';
					_id: string;
					username: string;
					name: string;
					avatarURL: string;
					url: string;
					isPro: boolean;
				};
			}>;
			invites: Array<{
				__typename?: 'Invite';
				_id: string;
				email?: string | null;
				user?: {
					__typename?: 'User';
					_id: string;
					name: string;
					username: string;
					url: string;
					avatarURL: string;
				} | null;
			}>;
		}>;
	};
};

export type UpdateAuthSettingsMutation_MutationVariables = Exact<{
	input: UpdateAuthSettingsInput;
}>;

export type UpdateAuthSettingsMutation_Mutation = {
	__typename?: 'Mutation';
	updateAuthSettings: {
		__typename?: 'Auth';
		_id: string;
		settings: { __typename?: 'AuthSettings'; _id: string };
	};
};

export type AuthBillingFragmentFragment = {
	__typename?: 'Auth';
	token: string;
	user: { __typename?: 'User'; _id: string; isPro: boolean };
	settings: {
		__typename?: 'AuthSettings';
		_id: string;
		needsUpgradeReason?: NeedsUpgradeReason | null;
		subscriptions: Array<{
			__typename?: 'UserSubscription';
			type: SubscriptionType;
			provider?: PaymentProvider | null;
			planID?: string | null;
			subscriptionEndDate?: Date | null;
			needsRenewSince?: Date | null;
			autoRenew: boolean;
			cardLast4?: string | null;
			cardBrandID?: CardBrand | null;
			isCardExpired: boolean;
		}>;
	};
};

export type AuthFragmentFragment = {
	__typename?: 'Auth';
	token: string;
	user: {
		__typename?: 'User';
		_id: string;
		username: string;
		name: string;
		role?: string | null;
		website?: string | null;
		instagram?: string | null;
		twitter?: string | null;
		url: string;
		avatarURL: string;
		isPro: boolean;
		itemsCount: number;
		boardsCount: number;
		followingCount: number;
		followersCount: number;
		canFollow: boolean;
		isFollowing: boolean;
		isFollowingBack: boolean;
		company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
		ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
		boards: Array<{
			__typename?: 'Board';
			_id: string;
			name: string;
			slug: string;
			url: string;
			isPrivate: boolean;
			itemsCount: number;
			lastItemAddedOrder?: number | null;
			role?: BoardUserRole | null;
			ownershipType: BoardOwnershipType;
			acceptURL?: string | null;
			rejectURL?: string | null;
			publicURL?: string | null;
			isTeamEditorAnEditor?: boolean | null;
			thumbnails: Array<{
				__typename?: 'Asset';
				_id: string;
				image: { __typename?: 'AssetImage'; thumbnail: string };
			}>;
			ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
			collaborators: Array<{
				__typename?: 'BoardCollaborator';
				_id: string;
				role: BoardUserRole;
				user: {
					__typename?: 'User';
					_id: string;
					username: string;
					name: string;
					avatarURL: string;
					url: string;
					isPro: boolean;
				};
			}>;
			invites: Array<{
				__typename?: 'Invite';
				_id: string;
				email?: string | null;
				user?: {
					__typename?: 'User';
					_id: string;
					name: string;
					username: string;
					url: string;
					avatarURL: string;
				} | null;
			}>;
		}>;
	};
	settings: {
		__typename?: 'AuthSettings';
		_id: string;
		email: string;
		workEmail?: string | null;
		isBeta: boolean;
		isModerator: boolean;
		gridSpacingRatio: number;
		gridColumnsRatio: number;
		onboard: boolean;
		dropboxLinked: boolean;
		darkMode: boolean;
		emailNotifications: boolean;
		emailNewsletter: boolean;
		itemsSortMethod?: ItemsSortMethod | null;
		autoPlayGIFs: boolean;
		fitToScreen: boolean;
		pinterestUsername?: string | null;
		needsUpgradeReason?: NeedsUpgradeReason | null;
		unreadNotificationsCount: number;
		featureFlags: Array<string>;
		maxBoardCollaborators: number;
		maxTeamUsers: number;
		feedBannerExpanded: boolean;
		megaphones: Array<{
			__typename?: 'Megaphone';
			_id: string;
			title: string;
			body: string;
			ctaLabel?: string | null;
		}>;
		subscriptions: Array<{
			__typename?: 'UserSubscription';
			type: SubscriptionType;
			status: SubscriptionStatus;
			canManage: boolean;
			provider?: PaymentProvider | null;
			planID?: string | null;
			subscriptionEndDate?: Date | null;
			needsRenewSince?: Date | null;
			autoRenew: boolean;
			cardLast4?: string | null;
			cardBrandID?: CardBrand | null;
			isCardExpired: boolean;
			maxSeats: number;
		}>;
	};
	team?: {
		__typename?: 'Team';
		_id: string;
		role: TeamUserRole;
		name: string;
		email: string;
		usersCount: number;
		feedBoardID?: string | null;
		notifyUsersOnComment: boolean;
		autoFollowTeamOwners: boolean;
		teamFeedAsHomepage: boolean;
	} | null;
};

export type BoardCollaboratorFragmentFragment = {
	__typename?: 'BoardCollaborator';
	_id: string;
	role: BoardUserRole;
	user: {
		__typename?: 'User';
		_id: string;
		username: string;
		name: string;
		avatarURL: string;
		url: string;
		isPro: boolean;
	};
};

export type BoardCollaboratorUserFragmentFragment = {
	__typename?: 'User';
	_id: string;
	username: string;
	name: string;
	avatarURL: string;
	url: string;
	isPro: boolean;
};

export type BoardFragmentFragment = {
	__typename?: 'Board';
	_id: string;
	name: string;
	slug: string;
	url: string;
	isPrivate: boolean;
	itemsCount: number;
	lastItemAddedOrder?: number | null;
	role?: BoardUserRole | null;
	ownershipType: BoardOwnershipType;
	acceptURL?: string | null;
	rejectURL?: string | null;
	publicURL?: string | null;
	isTeamEditorAnEditor?: boolean | null;
	thumbnails: Array<{
		__typename?: 'Asset';
		_id: string;
		image: { __typename?: 'AssetImage'; thumbnail: string };
	}>;
	ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
	collaborators: Array<{
		__typename?: 'BoardCollaborator';
		_id: string;
		role: BoardUserRole;
		user: {
			__typename?: 'User';
			_id: string;
			username: string;
			name: string;
			avatarURL: string;
			url: string;
			isPro: boolean;
		};
	}>;
	invites: Array<{
		__typename?: 'Invite';
		_id: string;
		email?: string | null;
		user?: {
			__typename?: 'User';
			_id: string;
			name: string;
			username: string;
			url: string;
			avatarURL: string;
		} | null;
	}>;
};

export type CommentFragmentFragment = {
	__typename?: 'Comment';
	_id: string;
	createdAt: Date;
	content: string;
	isTeamOnly: boolean;
	isLiked: boolean;
	likesCount: number;
	user: {
		__typename?: 'User';
		_id: string;
		name: string;
		username: string;
		url: string;
		avatarURL: string;
	};
	parent?: { __typename?: 'Comment'; _id: string } | null;
	position?: { __typename?: 'Position'; x: number; y: number } | null;
	userMentions?: Array<{
		__typename?: 'UserMention';
		indices: Array<number>;
		user: {
			__typename?: 'User';
			_id: string;
			name: string;
			username: string;
			url: string;
			avatarURL: string;
		};
	}> | null;
};

export type InviteFragmentFragment = {
	__typename?: 'Invite';
	_id: string;
	email?: string | null;
	user?: {
		__typename?: 'User';
		_id: string;
		name: string;
		username: string;
		url: string;
		avatarURL: string;
	} | null;
};

export type ItemFragmentFragment = {
	__typename?: 'Item';
	_id: string;
	shortID: string;
	url: string;
	createdAt?: Date | null;
	name?: string | null;
	pageURL?: string | null;
	sourceDisplayURL?: string | null;
	sourceURL?: string | null;
	isPrivate: boolean;
	isOwner: boolean;
	user: { __typename?: 'User'; _id: string; name: string; url: string };
	asset: {
		__typename?: 'Asset';
		_id: string;
		isSaved: boolean;
		savedCount: number;
		viewsCount: number;
		type: AssetType;
		image: {
			__typename?: 'AssetImage';
			thumbnail: string;
			original: string;
			ratio: number;
			width: number;
			height: number;
		};
		colors: Array<{ __typename?: 'AssetColor'; color: string; amount: number }>;
		ownBoards: Array<{ __typename?: 'Board'; _id: string }>;
	};
};

export type SiteFragmentFragment = {
	__typename?: 'Site';
	_id: string;
	url: string;
	template: SiteTemplate;
	sourceID?: string | null;
	title?: string | null;
	fontFamily?: string | null;
	content1?: string | null;
	content2?: string | null;
	backgroundColor?: string | null;
	textColor?: string | null;
	settings?: any | null;
	templateData?: any | null;
	domains?: Array<{
		__typename?: 'SiteDomain';
		domain: string;
		status: DomainVerificationStatus;
	}> | null;
};

export type TeamFragmentFragment = {
	__typename?: 'Team';
	_id: string;
	role: TeamUserRole;
	name: string;
	email: string;
	usersCount: number;
	feedBoardID?: string | null;
	notifyUsersOnComment: boolean;
	autoFollowTeamOwners: boolean;
	teamFeedAsHomepage: boolean;
};

export type TeamUserFragmentFragment = {
	__typename?: 'TeamUser';
	_id: string;
	email: string;
	workEmail?: string | null;
	lastActivityAt: Date;
	role: TeamUserRole;
	user: {
		__typename?: 'User';
		_id: string;
		name: string;
		avatarURL: string;
		username: string;
		url: string;
		isPro: boolean;
		canFollow: boolean;
		isFollowing: boolean;
		isFollowingBack: boolean;
		boardsCount: number;
		itemsCount: number;
	};
};

export type UserCompanyFragmentFragment = {
	__typename?: 'Company';
	_id: string;
	name: string;
	url: string;
};

export type UserFragmentFragment = {
	__typename?: 'User';
	_id: string;
	username: string;
	name: string;
	role?: string | null;
	website?: string | null;
	instagram?: string | null;
	twitter?: string | null;
	url: string;
	avatarURL: string;
	isPro: boolean;
	itemsCount: number;
	boardsCount: number;
	followingCount: number;
	followersCount: number;
	canFollow: boolean;
	isFollowing: boolean;
	isFollowingBack: boolean;
	company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
	ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
	boards: Array<{
		__typename?: 'Board';
		_id: string;
		name: string;
		slug: string;
		url: string;
		isPrivate: boolean;
		itemsCount: number;
		lastItemAddedOrder?: number | null;
		role?: BoardUserRole | null;
		ownershipType: BoardOwnershipType;
		acceptURL?: string | null;
		rejectURL?: string | null;
		publicURL?: string | null;
		isTeamEditorAnEditor?: boolean | null;
		thumbnails: Array<{
			__typename?: 'Asset';
			_id: string;
			image: { __typename?: 'AssetImage'; thumbnail: string };
		}>;
		ogImage: { __typename?: 'OGImage'; width: number; height: number; url: string };
		collaborators: Array<{
			__typename?: 'BoardCollaborator';
			_id: string;
			role: BoardUserRole;
			user: {
				__typename?: 'User';
				_id: string;
				username: string;
				name: string;
				avatarURL: string;
				url: string;
				isPro: boolean;
			};
		}>;
		invites: Array<{
			__typename?: 'Invite';
			_id: string;
			email?: string | null;
			user?: {
				__typename?: 'User';
				_id: string;
				name: string;
				username: string;
				url: string;
				avatarURL: string;
			} | null;
		}>;
	}>;
};

export type UserListItemFragmentFragment = {
	__typename?: 'User';
	_id: string;
	name: string;
	username: string;
	url: string;
	role?: string | null;
	avatarURL: string;
	isPro: boolean;
	canFollow: boolean;
	isFollowing: boolean;
	isFollowingBack: boolean;
	company?: { __typename?: 'Company'; _id: string; name: string; url: string } | null;
	items: {
		__typename?: 'PaginatedResultItems';
		items: Array<{
			__typename?: 'Item';
			_id: string;
			shortID: string;
			url: string;
			asset: {
				__typename?: 'Asset';
				_id: string;
				image: { __typename?: 'AssetImage'; thumbnail: string };
				colors: Array<{ __typename?: 'AssetColor'; color: string }>;
			};
		}>;
	};
};

export type NewSiteDomainMutationMutationVariables = Exact<{
	input: SiteDomainInput;
}>;

export type NewSiteDomainMutationMutation = {
	__typename?: 'Mutation';
	newSiteDomain: {
		__typename?: 'Site';
		_id: string;
		url: string;
		template: SiteTemplate;
		sourceID?: string | null;
		title?: string | null;
		fontFamily?: string | null;
		content1?: string | null;
		content2?: string | null;
		backgroundColor?: string | null;
		textColor?: string | null;
		settings?: any | null;
		templateData?: any | null;
		domains?: Array<{
			__typename?: 'SiteDomain';
			domain: string;
			status: DomainVerificationStatus;
		}> | null;
	};
};

export type UpdateAutoFollowTeamOwnersMutationMutationVariables = Exact<{
	input: Scalars['Boolean'];
}>;

export type UpdateAutoFollowTeamOwnersMutationMutation = {
	__typename?: 'Mutation';
	updateAutoFollowTeamOwners: { __typename?: 'Team'; _id: string; autoFollowTeamOwners: boolean };
};

export type UpdateTeamFeedAsHomepageMutationMutationVariables = Exact<{
	input: Scalars['Boolean'];
}>;

export type UpdateTeamFeedAsHomepageMutationMutation = {
	__typename?: 'Mutation';
	updateTeamFeedAsHomepage: { __typename?: 'Team'; _id: string; teamFeedAsHomepage: boolean };
};

export const AuthBillingFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthBillingFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AuthBillingFragmentFragment, unknown>;
export const UserCompanyFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
	],
} as unknown as DocumentNode<UserCompanyFragmentFragment, unknown>;
export const BoardCollaboratorUserFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
	],
} as unknown as DocumentNode<BoardCollaboratorUserFragmentFragment, unknown>;
export const BoardCollaboratorFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
	],
} as unknown as DocumentNode<BoardCollaboratorFragmentFragment, unknown>;
export const InviteFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
	],
} as unknown as DocumentNode<InviteFragmentFragment, unknown>;
export const BoardFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
	],
} as unknown as DocumentNode<BoardFragmentFragment, unknown>;
export const UserFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
	],
} as unknown as DocumentNode<UserFragmentFragment, unknown>;
export const TeamFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
	],
} as unknown as DocumentNode<TeamFragmentFragment, unknown>;
export const AuthFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserFragment' },
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isBeta' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isModerator' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridSpacingRatio' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridColumnsRatio' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'onboard' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'dropboxLinked' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'darkMode' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'emailNotifications' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'emailNewsletter' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsSortMethod' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'autoPlayGIFs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'fitToScreen' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pinterestUsername' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'unreadNotificationsCount' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'featureFlags' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'maxBoardCollaborators' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'maxTeamUsers' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'feedBannerExpanded' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'megaphones' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'title' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'body' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaLabel' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'status' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'canManage' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'maxSeats' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'TeamFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
	],
} as unknown as DocumentNode<AuthFragmentFragment, unknown>;
export const CommentFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CommentFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Comment' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parent' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: '_id' } }],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'content' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'position' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'x' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'y' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamOnly' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isLiked' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'userMentions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'name' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'username' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'avatarURL' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'indices' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
				],
			},
		},
	],
} as unknown as DocumentNode<CommentFragmentFragment, unknown>;
export const ItemFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ItemFragmentFragment, unknown>;
export const SiteFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SiteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Site' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'template' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'fontFamily' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'content1' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'content2' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'settings' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'templateData' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'domains' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'domain' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SiteFragmentFragment, unknown>;
export const TeamUserFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TeamUser' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastActivityAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
	],
} as unknown as DocumentNode<TeamUserFragmentFragment, unknown>;
export const UserListItemFragmentFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserListItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'items' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '10' },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'shortID' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'asset' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'thumbnail',
																		},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'colors' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'color',
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
	],
} as unknown as DocumentNode<UserListItemFragmentFragment, unknown>;
export const ExtensionAuthUserQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ExtensionAuthUserQuery' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'auth' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'boards' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'BoardFragment',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isPro' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
	],
} as unknown as DocumentNode<ExtensionAuthUserQueryQuery, ExtensionAuthUserQueryQueryVariables>;
export const UnlinkDropboxMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UnlinkDropboxMutation' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'unlinkDropbox' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'settings' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'dropboxLinked' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UnlinkDropboxMutationMutation, UnlinkDropboxMutationMutationVariables>;
export const AddItemsToBoardMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'AddItemsToBoardMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'AddItemsToBoardInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'addItemsToBoard' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'itemsCount',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'settings' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'needsUpgradeReason',
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'board' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnails' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'thumbnail',
																		},
																	},
																],
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'itemsCount' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'lastItemAddedOrder' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isSaved' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'savedCount' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ownBoards' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	AddItemsToBoardMutationMutation,
	AddItemsToBoardMutationMutationVariables
>;
export const AuthQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AuthQuery' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'auth' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'AuthFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserFragment' },
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isBeta' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isModerator' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridSpacingRatio' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridColumnsRatio' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'onboard' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'dropboxLinked' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'darkMode' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'emailNotifications' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'emailNewsletter' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsSortMethod' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'autoPlayGIFs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'fitToScreen' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pinterestUsername' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'unreadNotificationsCount' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'featureFlags' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'maxBoardCollaborators' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'maxTeamUsers' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'feedBannerExpanded' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'megaphones' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'title' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'body' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaLabel' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'status' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'canManage' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'maxSeats' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'TeamFragment' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AuthQueryQuery, AuthQueryQueryVariables>;
export const AuthUserQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AuthUserQuery' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'auth' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'UserFragment' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
	],
} as unknown as DocumentNode<AuthUserQueryQuery, AuthUserQueryQueryVariables>;
export const BillingPaymentsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BillingPaymentsQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'SubscriptionType' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'billingPayments' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'type' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'provider' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'chargeID' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'date' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'amount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'cardLast4' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'refunds' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<BillingPaymentsQueryQuery, BillingPaymentsQueryQueryVariables>;
export const BoardItemsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'BoardItemsQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'boardID' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortMethod' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemsSortMethod' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boardByID' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'boardID' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'boardID' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'sortMethod' },
											value: {
												kind: 'Variable',
												name: { kind: 'Name', value: 'sortMethod' },
											},
										},
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'limit' },
											value: {
												kind: 'Variable',
												name: { kind: 'Name', value: 'limit' },
											},
										},
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'cursor' },
											value: {
												kind: 'Variable',
												name: { kind: 'Name', value: 'cursor' },
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'items' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'ItemFragment',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'pageInfo' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'nextCursor',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<BoardItemsQueryQuery, BoardItemsQueryQueryVariables>;
export const CheckPaymentQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'CheckPaymentQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'SubscriptionType' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'checkPayment' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'type' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'confirmed' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'amount' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CheckPaymentQueryQuery, CheckPaymentQueryQueryVariables>;
export const CheckResetPasswordTokenQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'CheckResetPasswordTokenQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {
						kind: 'Variable',
						name: { kind: 'Name', value: 'resetPasswordToken' },
					},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'checkResetPasswordToken' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'resetPasswordToken' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'resetPasswordToken' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'resetPasswordToken' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	CheckResetPasswordTokenQueryQuery,
	CheckResetPasswordTokenQueryQueryVariables
>;
export const CheckSiteDomainMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'CheckSiteDomainMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'SiteDomainInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'checkSiteDomain' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'domains' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'domain' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'status' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	CheckSiteDomainMutationMutation,
	CheckSiteDomainMutationMutationVariables
>;
export const ClearUnreadNotificationsCountMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'ClearUnreadNotificationsCountMutation' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'clearUnreadNotificationsCount' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'settings' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'unreadNotificationsCount',
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	ClearUnreadNotificationsCountMutationMutation,
	ClearUnreadNotificationsCountMutationMutationVariables
>;
export const CompaniesQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'CompaniesQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'companies' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'cursor' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'cursor' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: {
													kind: 'Name',
													value: 'UserCompanyFragment',
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pageInfo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'nextCursor' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
	],
} as unknown as DocumentNode<CompaniesQueryQuery, CompaniesQueryQueryVariables>;
export const CompanyQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'CompanyQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'companyBySlug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'slug' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CompanyQueryQuery, CompanyQueryQueryVariables>;
export const CompanyUsersQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'CompanyUsersQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'companyBySlug' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'slug' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'users' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'cursor' },
											value: {
												kind: 'Variable',
												name: { kind: 'Name', value: 'cursor' },
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'items' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'UserListItemFragment',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'pageInfo' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'nextCursor',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserListItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'items' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '10' },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'shortID' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'asset' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'thumbnail',
																		},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'colors' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'color',
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CompanyUsersQueryQuery, CompanyUsersQueryQueryVariables>;
export const DeleteBoardMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'DeleteBoardMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'DeleteBoardInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'deleteBoard' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'boards' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: {
																			kind: 'Name',
																			value: 'BoardFragment',
																		},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'boardsCount',
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'itemsCount',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'settings' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'needsUpgradeReason',
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ownBoards' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
													],
												},
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'deletedItemIDs' } },
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
	],
} as unknown as DocumentNode<DeleteBoardMutationMutation, DeleteBoardMutationMutationVariables>;
export const DeleteCommentMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'DeleteCommentMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'DeleteCommentInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'deleteComment' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'success' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<DeleteCommentMutationMutation, DeleteCommentMutationMutationVariables>;
export const DeleteItemsMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'DeleteItemsMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'DeleteItemsInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'deleteItems' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'boards' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: {
																			kind: 'Name',
																			value: 'BoardFragment',
																		},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'itemsCount',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'settings' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'needsUpgradeReason',
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isSaved' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'savedCount' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ownBoards' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
													],
												},
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'deletedItemIDs' } },
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
	],
} as unknown as DocumentNode<DeleteItemsMutationMutation, DeleteItemsMutationMutationVariables>;
export const DeleteSiteDomainMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'DeleteSiteDomainMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'SiteDomainInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'deleteSiteDomain' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'SiteFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SiteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Site' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'template' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'fontFamily' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'content1' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'content2' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'settings' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'templateData' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'domains' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'domain' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	DeleteSiteDomainMutationMutation,
	DeleteSiteDomainMutationMutationVariables
>;
export const DeleteTeamMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'DeleteTeamMutation' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'deleteTeam' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'team' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'TeamFragment',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
	],
} as unknown as DocumentNode<DeleteTeamMutationMutation, DeleteTeamMutationMutationVariables>;
export const DeleteUserMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'DeleteUserMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'DeleteUserInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'deleteUser' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<DeleteUserMutationMutation, DeleteUserMutationMutationVariables>;
export const DirectoryUsersQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'DirectoryUsersQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstLetter' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'directoryUsers' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'firstLetter' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'firstLetter' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<DirectoryUsersQueryQuery, DirectoryUsersQueryQueryVariables>;
export const DismissMegaphoneMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'DismissMegaphoneMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'DismissMegaphoneInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'dismissMegaphone' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: '_id' } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	DismissMegaphoneMutationMutation,
	DismissMegaphoneMutationMutationVariables
>;
export const FeedItemsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'FeedItemsQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'feedItems' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'cursor' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'cursor' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ItemFragment' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pageInfo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'nextCursor' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<FeedItemsQueryQuery, FeedItemsQueryQueryVariables>;
export const ForgotPasswordMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'ForgotPasswordMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'ForgotPasswordInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'forgotPassword' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	ForgotPasswordMutationMutation,
	ForgotPasswordMutationMutationVariables
>;
export const ImportPinterestCheckUsernameMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'ImportPinterestCheckUsernameMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'ImportPinterestCheckUsernameInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'importPinterestCheckUsername' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'settings' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'pinterestUsername' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	ImportPinterestCheckUsernameMutationMutation,
	ImportPinterestCheckUsernameMutationMutationVariables
>;
export const ImportProcessGroupMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'ImportProcessGroupMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'ImportProcessGroupInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'importProcessGroup' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sourceID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'groupID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'title' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'date' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'pageURL' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'description' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'boardID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'private' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	ImportProcessGroupMutationMutation,
	ImportProcessGroupMutationMutationVariables
>;
export const ImportProcessItemMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'ImportProcessItemMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'ImportProcessItemInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'importProcessItem' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	ImportProcessItemMutationMutation,
	ImportProcessItemMutationMutationVariables
>;
export const ImportStartQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ImportStartQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'provider' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'ImportProvider' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'importStart' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'provider' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'provider' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'groups' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'sourceID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'name' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'count' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'private' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnailURL' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'count' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ImportStartQueryQuery, ImportStartQueryQueryVariables>;
export const InternalLoginAsMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'InternalLoginAsMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'InternalLoginAsInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'internalLoginAs' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'AuthFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserFragment' },
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isBeta' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isModerator' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridSpacingRatio' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridColumnsRatio' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'onboard' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'dropboxLinked' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'darkMode' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'emailNotifications' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'emailNewsletter' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsSortMethod' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'autoPlayGIFs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'fitToScreen' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pinterestUsername' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'unreadNotificationsCount' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'featureFlags' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'maxBoardCollaborators' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'maxTeamUsers' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'feedBannerExpanded' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'megaphones' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'title' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'body' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaLabel' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'status' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'canManage' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'maxSeats' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'TeamFragment' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	InternalLoginAsMutationMutation,
	InternalLoginAsMutationMutationVariables
>;
export const InternalLoginFromScriptsMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'InternalLoginFromScriptsMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'InternalLoginFromScriptsInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'internalLoginFromScripts' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'AuthFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserFragment' },
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isBeta' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isModerator' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridSpacingRatio' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridColumnsRatio' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'onboard' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'dropboxLinked' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'darkMode' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'emailNotifications' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'emailNewsletter' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsSortMethod' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'autoPlayGIFs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'fitToScreen' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pinterestUsername' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'unreadNotificationsCount' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'featureFlags' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'maxBoardCollaborators' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'maxTeamUsers' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'feedBannerExpanded' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'megaphones' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'title' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'body' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaLabel' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'status' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'canManage' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'maxSeats' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'TeamFragment' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	InternalLoginFromScriptsMutationMutation,
	InternalLoginFromScriptsMutationMutationVariables
>;
export const InternalLoginFromTestsMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'InternalLoginFromTestsMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'InternalLoginFromTestsInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'internalLoginFromTests' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'AuthFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserFragment' },
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isBeta' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isModerator' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridSpacingRatio' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridColumnsRatio' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'onboard' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'dropboxLinked' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'darkMode' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'emailNotifications' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'emailNewsletter' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsSortMethod' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'autoPlayGIFs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'fitToScreen' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pinterestUsername' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'unreadNotificationsCount' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'featureFlags' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'maxBoardCollaborators' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'maxTeamUsers' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'feedBannerExpanded' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'megaphones' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'title' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'body' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaLabel' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'status' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'canManage' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'maxSeats' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'TeamFragment' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	InternalLoginFromTestsMutationMutation,
	InternalLoginFromTestsMutationMutationVariables
>;
export const InviteUsersToBoardMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'InviteUsersToBoardMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'InviteUsersToBoardInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'inviteUsersToBoard' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'collaborators' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: {
													kind: 'Name',
													value: 'BoardCollaboratorFragment',
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'invites' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'InviteFragment' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
	],
} as unknown as DocumentNode<
	InviteUsersToBoardMutationMutation,
	InviteUsersToBoardMutationMutationVariables
>;
export const InviteUsersToTeamMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'InviteUsersToTeamMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'InviteUsersToTeamInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'inviteUsersToTeam' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'invites' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'InviteFragment' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
	],
} as unknown as DocumentNode<
	InviteUsersToTeamMutationMutation,
	InviteUsersToTeamMutationMutationVariables
>;
export const ItemDetailsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ItemDetails' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'shortID' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'itemByShortID' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'shortID' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'shortID' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'asset' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'hasRelated' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ogImage' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'width' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'height' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'url' },
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'users' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'name' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'username',
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'url' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'avatarURL',
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'isPro' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'canFollow',
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'isFollowing',
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'isFollowingBack',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'tags' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'name' },
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'comments' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'CommentFragment',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CommentFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Comment' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parent' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: '_id' } }],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'content' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'position' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'x' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'y' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamOnly' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isLiked' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'userMentions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'name' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'username' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'avatarURL' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'indices' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
				],
			},
		},
	],
} as unknown as DocumentNode<ItemDetailsQuery, ItemDetailsQueryVariables>;
export const ItemDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'Item' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'shortID' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'itemByShortID' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'shortID' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'shortID' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'ItemFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ItemQuery, ItemQueryVariables>;
export const JoinMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'JoinMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'JoinInput' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'join' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'AuthFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserFragment' },
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isBeta' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isModerator' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridSpacingRatio' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridColumnsRatio' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'onboard' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'dropboxLinked' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'darkMode' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'emailNotifications' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'emailNewsletter' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsSortMethod' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'autoPlayGIFs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'fitToScreen' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pinterestUsername' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'unreadNotificationsCount' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'featureFlags' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'maxBoardCollaborators' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'maxTeamUsers' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'feedBannerExpanded' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'megaphones' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'title' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'body' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaLabel' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'status' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'canManage' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'maxSeats' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'TeamFragment' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<JoinMutationMutation, JoinMutationMutationVariables>;
export const LandingQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'LandingQuery' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'landing' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'asset' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'thumbnail',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'ratio',
																		},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'colors' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'color',
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<LandingQueryQuery, LandingQueryQueryVariables>;
export const ListItemsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'ListItemsQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'shortID' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'listByShortID' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'shortID' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'shortID' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'limit' },
											value: {
												kind: 'Variable',
												name: { kind: 'Name', value: 'limit' },
											},
										},
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'cursor' },
											value: {
												kind: 'Variable',
												name: { kind: 'Name', value: 'cursor' },
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'items' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'ItemFragment',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'pageInfo' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'nextCursor',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ListItemsQueryQuery, ListItemsQueryQueryVariables>;
export const LoginFromTokenMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'LoginFromTokenMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'LoginFromTokenInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'loginFromToken' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'AuthFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserFragment' },
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isBeta' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isModerator' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridSpacingRatio' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridColumnsRatio' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'onboard' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'dropboxLinked' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'darkMode' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'emailNotifications' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'emailNewsletter' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsSortMethod' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'autoPlayGIFs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'fitToScreen' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pinterestUsername' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'unreadNotificationsCount' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'featureFlags' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'maxBoardCollaborators' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'maxTeamUsers' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'feedBannerExpanded' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'megaphones' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'title' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'body' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaLabel' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'status' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'canManage' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'maxSeats' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'TeamFragment' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	LoginFromTokenMutationMutation,
	LoginFromTokenMutationMutationVariables
>;
export const LoginMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'LoginMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'LoginInput' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'login' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'AuthFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserFragment' },
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isBeta' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isModerator' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridSpacingRatio' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridColumnsRatio' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'onboard' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'dropboxLinked' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'darkMode' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'emailNotifications' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'emailNewsletter' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsSortMethod' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'autoPlayGIFs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'fitToScreen' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pinterestUsername' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'unreadNotificationsCount' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'featureFlags' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'maxBoardCollaborators' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'maxTeamUsers' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'feedBannerExpanded' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'megaphones' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'title' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'body' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaLabel' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'status' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'canManage' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'maxSeats' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'TeamFragment' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<LoginMutationMutation, LoginMutationMutationVariables>;
export const LoginSocialMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'LoginSocialMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'LoginSocialInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'loginSocial' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'needsCompletion' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'partialInput' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'accessToken' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'name' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'email' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'username' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'pictureURL' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'AuthFragment' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserFragment' },
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isBeta' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isModerator' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridSpacingRatio' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridColumnsRatio' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'onboard' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'dropboxLinked' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'darkMode' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'emailNotifications' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'emailNewsletter' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsSortMethod' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'autoPlayGIFs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'fitToScreen' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pinterestUsername' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'unreadNotificationsCount' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'featureFlags' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'maxBoardCollaborators' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'maxTeamUsers' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'feedBannerExpanded' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'megaphones' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'title' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'body' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaLabel' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'status' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'canManage' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'maxSeats' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'TeamFragment' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<LoginSocialMutationMutation, LoginSocialMutationMutationVariables>;
export const LogoutMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'LogoutMutation' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [{ kind: 'Field', name: { kind: 'Name', value: 'logout' } }],
			},
		},
	],
} as unknown as DocumentNode<LogoutMutationMutation, LogoutMutationMutationVariables>;
export const NewBoardMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'NewBoardMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'NewBoardInput' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'newBoard' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'boards' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: {
																			kind: 'Name',
																			value: 'BoardFragment',
																		},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'boardsCount',
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'itemsCount',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'settings' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'needsUpgradeReason',
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'board' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ItemFragment' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<NewBoardMutationMutation, NewBoardMutationMutationVariables>;
export const NewCommentMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'NewCommentMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NewCommentInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'newComment' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'asset' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'comments' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'CommentFragment',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'CommentFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Comment' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'parent' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: '_id' } }],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'content' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'position' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'x' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'y' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamOnly' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isLiked' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'userMentions' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'name' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'username' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'avatarURL' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'indices' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'likesCount' } },
				],
			},
		},
	],
} as unknown as DocumentNode<NewCommentMutationMutation, NewCommentMutationMutationVariables>;
export const NewListMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'NewListMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'NewListInput' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'newList' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<NewListMutationMutation, NewListMutationMutationVariables>;
export const NewTeamMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'NewTeamMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'NewTeamInput' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'newTeam' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'team' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'team' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'TeamFragment',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
	],
} as unknown as DocumentNode<NewTeamMutationMutation, NewTeamMutationMutationVariables>;
export const NotificationsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'NotificationsQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'notifications' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'relatedUser' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'username' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'name' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'avatarURL' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isPro' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'canFollow' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isFollowing' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isFollowingBack' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'relatedItem' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'shortID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'asset' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'thumbnail',
																		},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'colors' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'color',
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'relatedBoard' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'name' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'slug' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'acceptURL' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'rejectURL' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'relatedTeam' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'name' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'acceptURL' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'rejectURL' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'relatedComment' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'content' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'username',
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'name' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'avatarURL',
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'url' },
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isTeamOnly' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<NotificationsQueryQuery, NotificationsQueryQueryVariables>;
export const PopularItemsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'PopularItemsQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'popularItems' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'cursor' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'cursor' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ItemFragment' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pageInfo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'nextCursor' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<PopularItemsQueryQuery, PopularItemsQueryQueryVariables>;
export const PrivateBoardItemsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'PrivateBoardItemsQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'userID' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'privateKey' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boardByPrivateKey' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'userID' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'userID' },
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'privateKey' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'privateKey' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'limit' },
											value: {
												kind: 'Variable',
												name: { kind: 'Name', value: 'limit' },
											},
										},
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'cursor' },
											value: {
												kind: 'Variable',
												name: { kind: 'Name', value: 'cursor' },
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'items' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'ItemFragment',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'pageInfo' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'nextCursor',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<PrivateBoardItemsQueryQuery, PrivateBoardItemsQueryQueryVariables>;
export const PrivateBoardQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'PrivateBoardQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'userID' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'privateKey' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boardByPrivateKey' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'userID' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'userID' },
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'privateKey' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'privateKey' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
	],
} as unknown as DocumentNode<PrivateBoardQueryQuery, PrivateBoardQueryQueryVariables>;
export const RegenerateBoardPublicUrlMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'RegenerateBoardPublicURLMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'RegenerateBoardPublicURLInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'regenerateBoardPublicURL' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	RegenerateBoardPublicUrlMutationMutation,
	RegenerateBoardPublicUrlMutationMutationVariables
>;
export const RelatedItemsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'RelatedItemsQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'shortID' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'relatedItems' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'shortID' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'shortID' },
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'cursor' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'cursor' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ItemFragment' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pageInfo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'nextCursor' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<RelatedItemsQueryQuery, RelatedItemsQueryQueryVariables>;
export const RemoveItemsFromBoardMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'RemoveItemsFromBoardMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'RemoveItemsFromBoardInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'removeItemsFromBoard' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'itemsCount',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'settings' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'needsUpgradeReason',
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'board' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnails' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'thumbnail',
																		},
																	},
																],
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'itemsCount' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'lastItemAddedOrder' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ownBoards' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	RemoveItemsFromBoardMutationMutation,
	RemoveItemsFromBoardMutationMutationVariables
>;
export const RemoveUserFromBoardMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'RemoveUserFromBoardMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'RemoveUserFromBoardInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'removeUserFromBoard' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
	],
} as unknown as DocumentNode<
	RemoveUserFromBoardMutationMutation,
	RemoveUserFromBoardMutationMutationVariables
>;
export const RemoveUserFromTeamMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'RemoveUserFromTeamMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'RemoveUserFromTeamInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'removeUserFromTeam' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'users' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'TeamUserFragment' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'invites' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'InviteFragment' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TeamUser' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastActivityAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
	],
} as unknown as DocumentNode<
	RemoveUserFromTeamMutationMutation,
	RemoveUserFromTeamMutationMutationVariables
>;
export const ReorderItemsMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'ReorderItemsMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'ReorderItemsInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'reorderItems' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ReorderItemsMutationMutation, ReorderItemsMutationMutationVariables>;
export const ReportItemsMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'ReportItemsMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'ReportItemsInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'reportItems' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'reportedItemIDs' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ReportItemsMutationMutation, ReportItemsMutationMutationVariables>;
export const ResetPasswordMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'ResetPasswordMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'ResetPasswordInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'resetPassword' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'AuthFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserFragment' },
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isBeta' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isModerator' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridSpacingRatio' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'gridColumnsRatio' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'onboard' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'dropboxLinked' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'darkMode' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'emailNotifications' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'emailNewsletter' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsSortMethod' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'autoPlayGIFs' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'fitToScreen' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pinterestUsername' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'unreadNotificationsCount' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'featureFlags' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'maxBoardCollaborators' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'maxTeamUsers' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'feedBannerExpanded' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'megaphones' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'title' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'body' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ctaLabel' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'status' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'canManage' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'maxSeats' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'team' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'TeamFragment' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<ResetPasswordMutationMutation, ResetPasswordMutationMutationVariables>;
export const SaveItemsMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'SaveItemsMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'SaveItemsInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'saveItems' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'itemsCount',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'settings' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'needsUpgradeReason',
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'board' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnails' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'thumbnail',
																		},
																	},
																],
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'itemsCount' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'lastItemAddedOrder' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'assets' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isSaved' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'savedCount' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ownBoards' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SaveItemsMutationMutation, SaveItemsMutationMutationVariables>;
export const SearchItemsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'SearchItemsQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'searchItems' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'query' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'cursor' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'cursor' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ItemFragment' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pageInfo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'nextCursor' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SearchItemsQueryQuery, SearchItemsQueryQueryVariables>;
export const SearchUsersQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'SearchUsersQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'searchUsers' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'query' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'cursor' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'cursor' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: {
													kind: 'Name',
													value: 'UserListItemFragment',
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pageInfo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'nextCursor' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserListItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'items' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '10' },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'shortID' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'asset' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'thumbnail',
																		},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'colors' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'color',
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SearchUsersQueryQuery, SearchUsersQueryQueryVariables>;
export const SelectSiteTemplateMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'SelectSiteTemplateMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'SelectSiteTemplateInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'selectSiteTemplate' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'Field', name: { kind: 'Name', value: '_id' } }],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	SelectSiteTemplateMutationMutation,
	SelectSiteTemplateMutationMutationVariables
>;
export const SideItemQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'SideItemQuery' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'sideItem' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'asset' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'colors' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'color' },
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'original',
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'ratio' },
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'username' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'name' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'avatarURL' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SideItemQueryQuery, SideItemQueryQueryVariables>;
export const SiteQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'SiteQuery' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'site' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'SiteFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SiteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Site' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'template' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'fontFamily' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'content1' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'content2' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'settings' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'templateData' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'domains' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'domain' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SiteQueryQuery, SiteQueryQueryVariables>;
export const StartStripeCheckoutMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'StartStripeCheckoutMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'StartStripeCheckoutInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'startStripeCheckout' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'sessionID' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	StartStripeCheckoutMutationMutation,
	StartStripeCheckoutMutationMutationVariables
>;
export const StartStripeUpdateBillingMethodMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'StartStripeUpdateBillingMethodMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'StartStripeUpdateBillingMethodInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'startStripeUpdateBillingMethod' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'sessionID' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	StartStripeUpdateBillingMethodMutationMutation,
	StartStripeUpdateBillingMethodMutationMutationVariables
>;
export const SuggestedInvitedUsersQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'SuggestedInvitedUsersQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'InviteType' } },
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceID' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'suggestedInvitedUsers' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'type' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'resourceID' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'resourceID' },
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'query' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	SuggestedInvitedUsersQueryQuery,
	SuggestedInvitedUsersQueryQueryVariables
>;
export const SuggestedMentionedUsersQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'suggestedMentionedUsersQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'MentionsType' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'suggestedMentionedUsers' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'type' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'query' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	SuggestedMentionedUsersQueryQuery,
	SuggestedMentionedUsersQueryQueryVariables
>;
export const SuggestedUsersQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'SuggestedUsersQuery' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'suggestedUsers' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'limit' },
											value: { kind: 'IntValue', value: '1' },
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'items' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'asset' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: '_id',
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'image',
																		},
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'thumbnail',
																					},
																				},
																			],
																		},
																	},
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'colors',
																		},
																		selectionSet: {
																			kind: 'SelectionSet',
																			selections: [
																				{
																					kind: 'Field',
																					name: {
																						kind: 'Name',
																						value: 'color',
																					},
																				},
																			],
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<SuggestedUsersQueryQuery, SuggestedUsersQueryQueryVariables>;
export const TeamFeedItemsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'TeamFeedItemsQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'auth' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'team' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'feedItems' },
												arguments: [
													{
														kind: 'Argument',
														name: { kind: 'Name', value: 'cursor' },
														value: {
															kind: 'Variable',
															name: { kind: 'Name', value: 'cursor' },
														},
													},
												],
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'items' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'FragmentSpread',
																		name: {
																			kind: 'Name',
																			value: 'ItemFragment',
																		},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'pageInfo',
															},
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'nextCursor',
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<TeamFeedItemsQueryQuery, TeamFeedItemsQueryQueryVariables>;
export const TeamQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'TeamQuery' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'auth' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'team' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'TeamFragment' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'users' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'TeamUserFragment',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'invites' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'InviteFragment',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Team' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'usersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'notifyUsersOnComment' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'autoFollowTeamOwners' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'teamFeedAsHomepage' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'TeamUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TeamUser' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'workEmail' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastActivityAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
	],
} as unknown as DocumentNode<TeamQueryQuery, TeamQueryQueryVariables>;
export const ToggleFollowUserMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'ToggleFollowUserMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'ToggleFollowUserInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'toggleFollowUser' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isFollowing' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'followingCount',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	ToggleFollowUserMutationMutation,
	ToggleFollowUserMutationMutationVariables
>;
export const ToggleLikeCommentMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'ToggleLikeCommentMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'ToggleLikeCommentInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'toggleLikeComment' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'comment' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isLiked' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'likesCount' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	ToggleLikeCommentMutationMutation,
	ToggleLikeCommentMutationMutationVariables
>;
export const TopSearchQueriesQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'TopSearchQueriesQuery' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'topSearchQueries' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'query' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'asset' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'image' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'thumbnail',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'colors' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'color' },
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<TopSearchQueriesQueryQuery, TopSearchQueriesQueryQueryVariables>;
export const TrackUpgradeClickMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'TrackUpgradeClickMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'TrackUpgradeClickInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'trackUpgradeClick' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	TrackUpgradeClickMutationMutation,
	TrackUpgradeClickMutationMutationVariables
>;
export const UpdateAvatarMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateAvatarMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateAvatarInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateAvatar' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'avatarURL' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UpdateAvatarMutationMutation, UpdateAvatarMutationMutationVariables>;
export const UpdateBillingAutoRenewMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateBillingAutoRenewMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateBillingAutoRenewInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateBillingAutoRenew' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'AuthBillingFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthBillingFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateBillingAutoRenewMutationMutation,
	UpdateBillingAutoRenewMutationMutationVariables
>;
export const UpdateBillingPlanMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateBillingPlanMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateBillingPlanInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateBillingPlan' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: {
													kind: 'Name',
													value: 'AuthBillingFragment',
												},
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'redirectURL' } },
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'AuthBillingFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Auth' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'settings' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'needsUpgradeReason' },
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'subscriptions' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'type' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'provider' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'planID' },
											},
											{
												kind: 'Field',
												name: {
													kind: 'Name',
													value: 'subscriptionEndDate',
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'needsRenewSince' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'autoRenew' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardLast4' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'cardBrandID' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isCardExpired' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateBillingPlanMutationMutation,
	UpdateBillingPlanMutationMutationVariables
>;
export const UpdateBoardNameMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateBoardNameMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateBoardNameInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateBoardName' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateBoardNameMutationMutation,
	UpdateBoardNameMutationMutationVariables
>;
export const UpdateBoardPrivacyMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateBoardPrivacyMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateBoardPrivacyInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateBoardPrivacy' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'board' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isPrivate' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'boardsCount',
															},
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'itemsCount',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'settings' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'needsUpgradeReason',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateBoardPrivacyMutationMutation,
	UpdateBoardPrivacyMutationMutationVariables
>;
export const UpdateBoardUserRoleMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateBoardUserRoleMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateBoardUserRoleInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateBoardUserRole' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'collaborators' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: {
													kind: 'Name',
													value: 'BoardCollaboratorFragment',
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateBoardUserRoleMutationMutation,
	UpdateBoardUserRoleMutationMutationVariables
>;
export const UpdateItemMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateItemMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateItemInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateItem' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'sourceDisplayURL' },
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UpdateItemMutationMutation, UpdateItemMutationMutationVariables>;
export const UpdateItemsPrivacyMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateItemsPrivacyMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateItemsPrivacyInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateItemsPrivacy' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'isPrivate' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'itemsCount',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'settings' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'needsUpgradeReason',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateItemsPrivacyMutationMutation,
	UpdateItemsPrivacyMutationMutationVariables
>;
export const UpdateNotifyTeamUsersOnCommentMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateNotifyTeamUsersOnCommentMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateNotifyTeamUsersOnComment' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'notifyUsersOnComment' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateNotifyTeamUsersOnCommentMutationMutation,
	UpdateNotifyTeamUsersOnCommentMutationMutationVariables
>;
export const UpdateProfileMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateProfileMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateProfileInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateProfile' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'token' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'name' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'username' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'company' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'UserCompanyFragment',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'role' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'website' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'instagram' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'twitter' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'settings' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'email' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'workEmail' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
	],
} as unknown as DocumentNode<UpdateProfileMutationMutation, UpdateProfileMutationMutationVariables>;
export const UpdateSiteMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateSiteMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateSiteInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateSite' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'SiteFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SiteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Site' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'template' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'fontFamily' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'content1' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'content2' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'settings' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'templateData' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'domains' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'domain' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UpdateSiteMutationMutation, UpdateSiteMutationMutationVariables>;
export const UpdateTeamFeedBoardMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateTeamFeedBoardMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateTeamFeedBoardInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateTeamFeedBoard' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'feedBoardID' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateTeamFeedBoardMutationMutation,
	UpdateTeamFeedBoardMutationMutationVariables
>;
export const UpdateTeamMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateTeamMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateTeamInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateTeam' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UpdateTeamMutationMutation, UpdateTeamMutationMutationVariables>;
export const UpdateTeamUserRoleMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateTeamUserRoleMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateTeamUserRoleInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateTeamUserRole' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateTeamUserRoleMutationMutation,
	UpdateTeamUserRoleMutationMutationVariables
>;
export const UpdateUsernameMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateUsernameMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateUsernameInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateUsername' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'username',
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'site' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateUsernameMutationMutation,
	UpdateUsernameMutationMutationVariables
>;
export const UpgradeStatsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'UpgradeStatsQuery' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'upgradeStats' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'imagesCount' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UpgradeStatsQueryQuery, UpgradeStatsQueryQueryVariables>;
export const UploadItemFromRemoteMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UploadItemFromRemoteMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UploadItemFromRemoteInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'uploadItemFromRemote' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'itemsCount',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'settings' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'needsUpgradeReason',
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'item' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ItemFragment' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UploadItemFromRemoteMutationMutation,
	UploadItemFromRemoteMutationMutationVariables
>;
export const UploadItemMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UploadItemMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UploadItemInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'uploadItem' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'auth' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'user' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'itemsCount',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'settings' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'needsUpgradeReason',
															},
														},
													],
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'board' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnails' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'thumbnail',
																		},
																	},
																],
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'itemsCount' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'lastItemAddedOrder' },
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'item' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: { kind: 'Name', value: 'ItemFragment' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UploadItemMutationMutation, UploadItemMutationMutationVariables>;
export const UserCheckDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'UserCheck' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: {
						kind: 'Variable',
						name: { kind: 'Name', value: 'usernameOrEmail' },
					},
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'userCheck' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'usernameOrEmail' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'usernameOrEmail' },
								},
							},
						],
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UserCheckQuery, UserCheckQueryVariables>;
export const UserFollowersQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'UserFollowersQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'userID' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'userFollowers' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'userID' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'userID' },
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'cursor' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'cursor' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: {
													kind: 'Name',
													value: 'UserListItemFragment',
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pageInfo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'nextCursor' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserListItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'items' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '10' },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'shortID' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'asset' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'thumbnail',
																		},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'colors' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'color',
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UserFollowersQueryQuery, UserFollowersQueryQueryVariables>;
export const UserFollowingQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'UserFollowingQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'userID' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'userFollowing' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'userID' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'userID' },
								},
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'cursor' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'cursor' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'FragmentSpread',
												name: {
													kind: 'Name',
													value: 'UserListItemFragment',
												},
											},
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'pageInfo' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'nextCursor' },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserListItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'items' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'IntValue', value: '10' },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'shortID' },
											},
											{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'asset' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: { kind: 'Name', value: '_id' },
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'image' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'thumbnail',
																		},
																	},
																],
															},
														},
														{
															kind: 'Field',
															name: { kind: 'Name', value: 'colors' },
															selectionSet: {
																kind: 'SelectionSet',
																selections: [
																	{
																		kind: 'Field',
																		name: {
																			kind: 'Name',
																			value: 'color',
																		},
																	},
																],
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UserFollowingQueryQuery, UserFollowingQueryQueryVariables>;
export const UserItemsQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'UserItemsQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortMethod' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemsSortMethod' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'userByUsername' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'username' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'username' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'items' },
									arguments: [
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'sortMethod' },
											value: {
												kind: 'Variable',
												name: { kind: 'Name', value: 'sortMethod' },
											},
										},
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'limit' },
											value: {
												kind: 'Variable',
												name: { kind: 'Name', value: 'limit' },
											},
										},
										{
											kind: 'Argument',
											name: { kind: 'Name', value: 'cursor' },
											value: {
												kind: 'Variable',
												name: { kind: 'Name', value: 'cursor' },
											},
										},
									],
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'items' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'FragmentSpread',
															name: {
																kind: 'Name',
																value: 'ItemFragment',
															},
														},
													],
												},
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'pageInfo' },
												selectionSet: {
													kind: 'SelectionSet',
													selections: [
														{
															kind: 'Field',
															name: {
																kind: 'Name',
																value: 'nextCursor',
															},
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ItemFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Item' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'shortID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'pageURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceDisplayURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'asset' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'original' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'ratio' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'width' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'height' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'savedCount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'viewsCount' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'colors' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'color' },
											},
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'amount' },
											},
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'type' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'ownBoards' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<UserItemsQueryQuery, UserItemsQueryQueryVariables>;
export const UserQueryDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'UserQuery' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'NonEmptyString' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'userByUsername' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'username' },
								value: {
									kind: 'Variable',
									name: { kind: 'Name', value: 'username' },
								},
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserCompanyFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
			typeCondition: {
				kind: 'NamedType',
				name: { kind: 'Name', value: 'BoardCollaborator' },
			},
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorUserFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'InviteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Invite' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'BoardFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Board' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'slug' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isPrivate' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'lastItemAddedOrder' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'thumbnails' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'image' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{
												kind: 'Field',
												name: { kind: 'Name', value: 'thumbnail' },
											},
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'collaborators' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardCollaboratorFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'ownershipType' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'invites' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'InviteFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'acceptURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'rejectURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'publicURL' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isTeamEditorAnEditor' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'UserFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'username' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'role' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'company' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'UserCompanyFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'website' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'avatarURL' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'ogImage' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'width' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'height' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'boards' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'BoardFragment' },
								},
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'isPro' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'boardsCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followingCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'canFollow' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowing' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'isFollowingBack' } },
				],
			},
		},
	],
} as unknown as DocumentNode<UserQueryQuery, UserQueryQueryVariables>;
export const UpdateAuthSettingsMutation_Document = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'UpdateAuthSettingsMutation_' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'UpdateAuthSettingsInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateAuthSettings' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'settings' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
										],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateAuthSettingsMutation_Mutation,
	UpdateAuthSettingsMutation_MutationVariables
>;
export const NewSiteDomainMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'NewSiteDomainMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: {
							kind: 'NamedType',
							name: { kind: 'Name', value: 'SiteDomainInput' },
						},
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'newSiteDomain' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'FragmentSpread',
									name: { kind: 'Name', value: 'SiteFragment' },
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'SiteFragment' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Site' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'url' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'template' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'sourceID' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'title' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'fontFamily' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'content1' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'content2' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'textColor' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'settings' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'templateData' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'domains' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'domain' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<NewSiteDomainMutationMutation, NewSiteDomainMutationMutationVariables>;
export const UpdateAutoFollowTeamOwnersMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'updateAutoFollowTeamOwnersMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateAutoFollowTeamOwners' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'autoFollowTeamOwners' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateAutoFollowTeamOwnersMutationMutation,
	UpdateAutoFollowTeamOwnersMutationMutationVariables
>;
export const UpdateTeamFeedAsHomepageMutationDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'updateTeamFeedAsHomepageMutation' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'updateTeamFeedAsHomepage' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: '_id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'teamFeedAsHomepage' },
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateTeamFeedAsHomepageMutationMutation,
	UpdateTeamFeedAsHomepageMutationMutationVariables
>;
