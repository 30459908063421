import { type Board, type BoardFragmentFragment } from '@apps/www/src/__generated__/graphql';
import IconCaretSVG from '@pkgs/shared-client/img/icon-caret-inlined.svg';
import IconCloseSmallSVG from '@pkgs/shared-client/img/icon-close-small-inlined.svg';
import IconLockSVG from '@pkgs/shared-client/img/icon-lock-inlined.svg';
import React from 'react';
import { createSelector } from 'reselect';
import SVA from './SVA';
import SVDropdown from './SVDropdown';
import SVDropdownContent from './SVDropdownContent';
import SVFlexSpacer from './SVFlexSpacer';
import { type LikeItem } from './SVGrid';
import SVIconButton from './SVIconButton';
import SVImage from './SVImage';
import SVLink from './SVLink';

const shownBoardsSelector = createSelector(
	(props: Props) => props.boards || [],
	(props: Props) => props.selectedBoardIDs,
	(boards, selectedBoardIDs) =>
		boards.filter((board) => {
			return !!selectedBoardIDs.find((selectedBoardID) => {
				return selectedBoardID == board._id;
			});
		}),
);

const availableBoardsSelector = createSelector(
	(props: Props) => props.boards || [],
	(props: Props) => props.selectedBoardIDs,
	(boards, selectedBoardIDs) =>
		boards.filter((board) => {
			return !selectedBoardIDs.find((selectedBoardID) => {
				return selectedBoardID == board._id;
			});
		}),
);

const _ItemButton = React.memo(
	({
		board,
		onRemove,
		canRemove,
	}: Pick<Props, 'onRemove'> & { board: BoardFragmentFragment; canRemove: boolean }) => (
		<li
			className="group relative box-border flex h-11 w-full items-center space-x-2 rounded p-1 hover:bg-gray-800"
			title={board.name}
		>
			<SVA
				Component={SVLink}
				to={board.url}
				className="flex min-w-0 flex-auto items-center space-x-2"
			>
				<SVImage
					className="ml-3 h-9 w-9 flex-shrink-0"
					src={board.thumbnails?.[0]?.image?.thumbnail}
					cover={true}
					rounded={true}
				/>
				<span className="type-small truncate">{board.name}</span>
				<SVFlexSpacer />
				{board.isPrivate && (
					<span className="text-primary duration-over relative ml-2 flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full bg-gray-700 transition-opacity">
						<IconLockSVG className="h-2 w-2" />
					</span>
				)}
			</SVA>
			<div className="type-small group-hover:text-muted pr-4 text-xs text-gray-600 group-hover:pr-0">
				{board.itemsCount}
			</div>
			{canRemove && (
				<span className="hidden group-hover:block">
					<SVIconButton
						className="ml-1 mr-3"
						iconClassName="w-[12px] h-[12px] min-h-[12px] min-w-[12px]"
						onClick={() => onRemove(board)}
						src={IconCloseSmallSVG}
						label="Remove"
					/>
				</span>
			)}
		</li>
	),
);

const _AddButton = React.memo(
	React.forwardRef<
		SVDropdown | null,
		Pick<Props, 'onAdd' | 'onNew'> & {
			availableBoards: BoardFragmentFragment[];
		}
	>(({ availableBoards, onAdd, onNew }, ref) => (
		<SVDropdown
			ref={ref}
			key="add"
			triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			renderTrigger={({ isOpen: _, ...props }) => (
				<button
					className="border-separator text-primary relative mb-2 box-border flex h-14 w-full cursor-pointer items-center space-x-4 rounded-lg border-2 p-4 text-left"
					{...props}
				>
					<span className="flex-grow">Add to board</span>
					<IconCaretSVG />
				</button>
			)}
			renderContent={() => (
				<SVDropdownContent.Boards
					boards={availableBoards}
					onBoardClick={onAdd}
					onNewBoard={onNew}
					showTitle={false}
				/>
			)}
			positionStrategy={SVDropdown.POSITION_STRATEGIES.FIXED}
			maxHeight={520}
		/>
	)),
);

type Props = {
	items: LikeItem[];
	boards: BoardFragmentFragment[];
	onRemove: (board: BoardFragmentFragment) => void;
	onAdd: (board: BoardFragmentFragment) => void;
	onNew: React.MouseEventHandler;
	canRemove?: (board: BoardFragmentFragment) => boolean;
	selectedBoardIDs: Board['_id'][];
};

class SVItemBoardsList extends React.Component<Props> {
	dropdownRef = React.createRef<SVDropdown>();

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.items != nextProps.items && this.dropdownRef.current) {
			this.dropdownRef.current.close();
		}
	}

	render() {
		const { onRemove, onAdd, onNew, canRemove } = this.props;

		const shownBoards = shownBoardsSelector(this.props);
		const availableBoards = availableBoardsSelector(this.props);

		return (
			<ul className="m-auto flex w-full flex-col">
				<_AddButton
					ref={this.dropdownRef}
					availableBoards={availableBoards}
					onAdd={onAdd}
					onNew={onNew}
				/>
				{shownBoards.map((board) => (
					<_ItemButton
						key={board._id}
						board={board}
						onRemove={onRemove}
						canRemove={!canRemove || canRemove(board)}
					/>
				))}
			</ul>
		);
	}
}

export default SVItemBoardsList;
