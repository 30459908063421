import { type NotificationsQueryQuery } from '@apps/www/src/__generated__/graphql';
import SVFollowButtonContainer from '@apps/www/src/www/containers/SVFollowButtonContainer';
import useFeatureFlag from '@apps/www/src/www/hooks/useFeatureFlag';
import { formatRelativeDate } from '@pkgs/shared-client/helpers/format';
import NotificationType from '@pkgs/shared/enums/NotificationType';
import { useRouter } from 'next/router';
import { memo } from 'react';
import SVA from './SVA';
import SVAvatar from './SVAvatar';
import SVButton, { SVButtonSIZES, SVButtonUSES } from './SVButton';
import SVFlexSpacer from './SVFlexSpacer';
import SVImage from './SVImage';
import SVLink from './SVLink';

type Notification = ArrayElement<NotificationsQueryQuery['notifications']>;

const _NotificationsItem = ({ children }: OnlyChildrenProps) => (
	<div className="type-small flex items-center space-x-2">{children}</div>
);

const _NotificationItemHighlight = ({ children }: OnlyChildrenProps) => (
	<span className="text-primary duration-over group-hover:text-muted font-semibold transition-colors ease-out">
		{children}
	</span>
);

const _NotificationItemDate = ({ children }: OnlyChildrenProps) => (
	<span className="type-label text-muted duration-over transition-colors ease-out group-hover:text-gray-600">
		{children}
	</span>
);

const NotificationItemNewFollower = memo(({ item }: { item: Notification }) => {
	if (!item.relatedUser) {
		return null;
	}
	return (
		<_NotificationsItem>
			<SVA
				className="text-secondary flex items-center space-x-2"
				Component={SVLink}
				to={item.relatedUser.url}
				title={item.relatedUser.name}
			>
				<SVAvatar className="h-8 w-8" src={item.relatedUser.avatarURL} />
				<span>
					<_NotificationItemHighlight>{`@${item.relatedUser.username}`}</_NotificationItemHighlight>{' '}
					is now following you{' '}
					<_NotificationItemDate>
						{formatRelativeDate(item.createdAt)}
					</_NotificationItemDate>
				</span>
			</SVA>
			<SVFlexSpacer />
			<SVFollowButtonContainer
				userID={item.relatedUser._id}
				canFollow={item.relatedUser.canFollow}
				isFollowing={item.relatedUser.isFollowing}
				isFollowingBack={item.relatedUser.isFollowingBack}
				size={SVButtonSIZES.TINY}
			/>
		</_NotificationsItem>
	);
});

const NotificationItemComment = ({ item }: { item: Notification }) => {
	const canSeeComments = useFeatureFlag('comments');

	if (!canSeeComments) {
		return null;
	}

	const router = useRouter();

	if (!item.relatedUser || !item.relatedItem || !item.relatedComment) {
		return null;
	}

	const USE_STRING = {
		[NotificationType.NEW_COMMENT]: item.relatedComment.isTeamOnly
			? 'commented on your team'
			: 'commented on your image',
		[NotificationType.REPLY_TO_COMMENT]: item.relatedComment.isTeamOnly
			? 'replied to your commented on your team'
			: 'replied to your comment',
		[NotificationType.MENTION_IN_COMMENT]: item.relatedComment.isTeamOnly
			? 'tagged you on a comment on your team'
			: 'tagged you on a comment',
	};

	return (
		<_NotificationsItem>
			<SVA
				className="text-secondary flex items-center space-x-2"
				Component={SVLink}
				to={{
					pathname: router.pathname,
					query: {
						...router.query,
						itemShortID: item.relatedItem.shortID,
						commentID: item.relatedComment._id,
					},
				}}
				as={item.relatedItem.url}
			>
				<SVLink
					to={item.relatedUser.url}
					title={item.relatedUser.name}
					as={item.relatedUser.url}
				>
					<SVAvatar className="h-8 w-8" src={item.relatedUser.avatarURL} />
				</SVLink>
				<span>
					<_NotificationItemHighlight>{`@${item.relatedUser.username}`}</_NotificationItemHighlight>{' '}
					{USE_STRING[item.type]}{' '}
					<_NotificationItemDate>
						{formatRelativeDate(item.createdAt)}
					</_NotificationItemDate>
				</span>
			</SVA>
			<SVFlexSpacer />
			<SVA
				Component={SVLink}
				to={{
					pathname: router.pathname,
					query: {
						...router.query,
						itemShortID: item.relatedItem.shortID,
						commentID: item.relatedComment._id,
					},
				}}
				as={item.relatedItem.url}
			>
				<SVImage
					className="duration-over h-12 w-12 transition-opacity ease-out group-hover:opacity-80"
					cover={true}
					src={item.relatedItem.asset.image.thumbnail}
				/>
			</SVA>
		</_NotificationsItem>
	);
};

const NotificationItemNewSave = ({ item }: { item: Notification }) => {
	const router = useRouter();

	if (!item.relatedUser || !item.relatedItem) {
		return null;
	}

	return (
		<_NotificationsItem>
			<SVA
				className="text-secondary flex items-center space-x-2"
				Component={SVLink}
				to={item.relatedUser.url}
				title={item.relatedUser.name}
			>
				<SVAvatar className="h-8 w-8" src={item.relatedUser.avatarURL} />
				<span>
					<_NotificationItemHighlight>{`@${item.relatedUser.username}`}</_NotificationItemHighlight>{' '}
					saved your image{' '}
					<_NotificationItemDate>
						{formatRelativeDate(item.createdAt)}
					</_NotificationItemDate>
				</span>
			</SVA>
			<SVFlexSpacer />
			<SVA
				Component={SVLink}
				to={{
					pathname: router.pathname,
					query: { ...router.query, itemShortID: item.relatedItem.shortID },
				}}
				as={item.relatedItem.url}
			>
				<SVImage
					className="duration-over h-12 w-12 transition-opacity ease-out group-hover:opacity-80"
					cover={true}
					src={item.relatedItem.asset.image.thumbnail}
				/>
			</SVA>
		</_NotificationsItem>
	);
};

const NotificationItemInviteCollaborator = ({ item }: { item: Notification }) => {
	if (!item.relatedUser || !item.relatedBoard) {
		return null;
	}

	return (
		<_NotificationsItem>
			<SVA
				className="text-secondary flex items-center space-x-2"
				Component={SVLink}
				to={item.relatedBoard.url}
				title={item.relatedBoard.name}
			>
				<SVAvatar className="h-8 w-8" src={item.relatedUser.avatarURL} />
				<span>
					<_NotificationItemHighlight>{`@${item.relatedUser.username}`}</_NotificationItemHighlight>{' '}
					invited you to collaborate on the board{' '}
					<_NotificationItemHighlight>{`${item.relatedBoard.name}`}</_NotificationItemHighlight>{' '}
					<_NotificationItemDate>
						{formatRelativeDate(item.createdAt)}
					</_NotificationItemDate>
				</span>
			</SVA>
			{item.relatedBoard.rejectURL && item.relatedBoard.acceptURL && (
				<>
					<SVFlexSpacer />
					<div className="flex flex-col space-y-2">
						<SVButton
							Component={SVLink}
							to={item.relatedBoard.acceptURL}
							size={SVButtonSIZES.TINY}
							use={SVButtonUSES.PRIMARY}
						>
							Accept
						</SVButton>
						<SVButton
							Component={SVLink}
							to={item.relatedBoard.rejectURL}
							size={SVButtonSIZES.TINY}
						>
							Reject
						</SVButton>
					</div>
				</>
			)}
		</_NotificationsItem>
	);
};

const NotificationItemInviteCollaboratorAnswer = ({ item }: { item: Notification }) => {
	if (!item.relatedUser || !item.relatedBoard) {
		return null;
	}

	return (
		<_NotificationsItem>
			<SVA
				className="text-secondary flex items-center space-x-2"
				Component={SVLink}
				to={item.relatedBoard.url}
				title={item.relatedBoard.name}
			>
				<SVAvatar className="h-8 w-8" src={item.relatedUser.avatarURL} />
				<span>
					<_NotificationItemHighlight>{`@${item.relatedUser.username}`}</_NotificationItemHighlight>{' '}
					{item.type === NotificationType.INVITE_COLLABORATOR_ACCEPTED
						? 'accepted'
						: 'rejected'}{' '}
					your invitation to collaborate on the board{' '}
					<_NotificationItemHighlight>{`${item.relatedBoard.name}`}</_NotificationItemHighlight>{' '}
					<_NotificationItemDate>
						{formatRelativeDate(item.createdAt)}
					</_NotificationItemDate>
				</span>
			</SVA>
		</_NotificationsItem>
	);
};

const NotificationItemInviteTeamUser = ({ item }: { item: Notification }) => {
	if (!item.relatedUser || !item.relatedTeam) {
		return null;
	}

	return (
		<_NotificationsItem>
			<span className="text-secondary flex items-center space-x-2">
				<SVAvatar className="h-8 w-8" src={item.relatedUser.avatarURL} />
				<span>
					<_NotificationItemHighlight>{`@${item.relatedUser.username}`}</_NotificationItemHighlight>{' '}
					invited you to join the{' '}
					<_NotificationItemHighlight>{`${item.relatedTeam.name}`}</_NotificationItemHighlight>{' '}
					team{' '}
					<_NotificationItemDate>
						{formatRelativeDate(item.createdAt)}
					</_NotificationItemDate>
				</span>
			</span>
			{item.relatedTeam.rejectURL && item.relatedTeam.acceptURL && (
				<>
					<SVFlexSpacer />
					<div className="flex flex-col space-y-2">
						<SVButton
							Component={SVLink}
							to={item.relatedTeam.acceptURL}
							size={SVButtonSIZES.TINY}
							use={SVButtonUSES.PRIMARY}
						>
							Accept
						</SVButton>
						<SVButton
							Component={SVLink}
							to={item.relatedTeam.rejectURL}
							size={SVButtonSIZES.TINY}
						>
							Reject
						</SVButton>
					</div>
				</>
			)}
		</_NotificationsItem>
	);
};

const NotificationItemInviteTeamUserAnswer = ({ item }: { item: Notification }) => {
	if (!item.relatedUser || !item.relatedTeam) {
		return null;
	}

	return (
		<_NotificationsItem>
			<span className="text-secondary flex items-center space-x-2">
				<SVAvatar className="h-8 w-8" src={item.relatedUser.avatarURL} />
				<span>
					<_NotificationItemHighlight>{`@${item.relatedUser.username}`}</_NotificationItemHighlight>{' '}
					{item.type === NotificationType.INVITE_TEAM_USER_ACCEPTED
						? 'accepted'
						: 'rejected'}{' '}
					your invitation to join the{' '}
					<_NotificationItemHighlight>{`${item.relatedTeam.name}`}</_NotificationItemHighlight>{' '}
					team{' '}
					<_NotificationItemDate>
						{formatRelativeDate(item.createdAt)}
					</_NotificationItemDate>
				</span>
			</span>
		</_NotificationsItem>
	);
};

function SVNotifications({ children }: OnlyChildrenProps) {
	return (
		<div className="font-base text-secondary flex flex-col items-stretch space-y-4 p-8">
			{children}
		</div>
	);
}

export default SVNotifications;

SVNotifications.ItemNewFollower = NotificationItemNewFollower;
SVNotifications.ItemNewSave = NotificationItemNewSave;
SVNotifications.ItemInviteCollaborator = NotificationItemInviteCollaborator;
SVNotifications.ItemInviteCollaboratorAnswer = NotificationItemInviteCollaboratorAnswer;
SVNotifications.ItemInviteTeamUser = NotificationItemInviteTeamUser;
SVNotifications.ItemInviteTeamUserAnswer = NotificationItemInviteTeamUserAnswer;
SVNotifications.ItemComment = NotificationItemComment;
