import { gql } from '@apollo/client';

const SiteFragment = gql`
	fragment SiteFragment on Site {
		_id
		url
		template
		sourceID
		title
		fontFamily
		content1
		content2
		backgroundColor
		textColor
		settings
		templateData
		domains {
			domain
			status
		}
	}
`;

export default SiteFragment;
