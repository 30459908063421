import { isApolloError } from '@apollo/client';
import config from '@pkgs/shared-client/config';
import noop from '@pkgs/shared-client/helpers/noop';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import clsx from 'clsx';
import {
	Formik,
	Form as FormikForm,
	useFormikContext,
	type FormikErrors,
	type FormikFormProps,
	type FormikHelpers,
	type FormikTouched,
	type FormikValues,
} from 'formik';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import SVButton, { SVButtonSIZES, SVButtonUSES } from './SVButton';
import SVFormLayout from './SVFormLayout';
import SVMessage from './SVMessage';
import SVFlexSpacer from '@pkgs/shared-client/components/SVFlexSpacer';

const getStatusFromError = (error: any) => {
	if (!error || Object.keys(getFieldErrors(error)).length > 0) {
		return {};
	}

	let message = null;

	// @ts-ignore ...
	if (config.env !== 'production' && isApolloError(error) && error.networkError?.result?.errors) {
		// @ts-ignore ...
		message = error.networkError.result.errors.map((error) => error.message).join('\n');
	} else {
		message = error.message.replace(/^GraphQL error: /, '');
	}

	return {
		error: message,
	};
};

const getFieldErrors = (error: any): { [fieldName: string]: Error } => {
	if (error && isApolloError(error) && error.graphQLErrors?.length > 0) {
		const firstError = error.graphQLErrors[0];

		// Error from mongoose validation
		// @ts-ignore ...
		const fieldErrors = firstError.extensions?.exception?.errors;

		if (fieldErrors) {
			return Object.keys(fieldErrors).reduce(
				(errors: { [fieldName: string]: Error }, key) => {
					errors[key] = new Error(fieldErrors[key].message);

					return errors;
				},
				{},
			);
		}

		// Error from InvalidInputError
		if (firstError?.message && firstError?.extensions?.field) {
			// @ts-ignore ...
			return { [firstError?.extensions?.field]: new Error(firstError?.message) };
		}

		// Error from error.create
		const exception = error.graphQLErrors[0].extensions?.exception;
		// @ts-ignore ...
		if (exception?.message && exception?._responseParams?.field) {
			// @ts-ignore ...
			return { [exception?._responseParams?.field]: new Error(exception?.message) };
		}
	}

	return {};
};

const _StatusMessage = () => {
	const form = useFormikContext();

	return form.status && form.status.error ? (
		<SVMessage use={SVMessage.USES.ERROR}>{form.status.error}</SVMessage>
	) : null;
};

const _SubmitButton = ({
	children,
	size,
	use,
	submitDisabled,
}: Partial<OnlyChildrenProps> & {
	size: ValueOf<typeof SVButtonSIZES>;
	use: ValueOf<typeof SVButtonUSES>;
	submitDisabled: boolean;
}) => {
	const form = useFormikContext();

	return (
		<SVButton
			type="submit"
			use={use}
			size={size}
			isLoading={form.isSubmitting}
			isDisabled={submitDisabled}
		>
			{children}
		</SVButton>
	);
};

type DefaultProps<T extends FormikValues, R = void> = {
	onSuccess: (result: Awaited<R>, values: T, form: FormikHelpers<T>) => Promise<void>;
	initialValues: T;
	initialErrors: FormikErrors<unknown>;
	initialTouched: FormikTouched<unknown>;
	submitLabel: string;
	enableReinitialize: boolean;
	submitAlignment: 'left' | 'center' | 'right';
	submitSize: ValueOf<typeof SVButtonSIZES>;
	submitUse: ValueOf<typeof SVButtonUSES>;
	submitDisabled: boolean;
};

const defaultProps = {
	onSuccess: noop,
	initialValues: {},
	initialErrors: {},
	initialTouched: {},
	submitLabel: 'Submit',
	enableReinitialize: false,
	submitAlignment: 'left',
	submitSize: SVButtonSIZES.DEFAULT,
	submitUse: SVButtonUSES.PRIMARY,
	submitDisabled: false,
};

type Props<T extends FormikValues, R = void> = React.PropsWithChildren<
	Partial<DefaultProps<T, R>> &
		FormikFormProps & {
			onSubmit: (values: T) => Promise<R>;
			noPadding?: boolean;
			childrenAfter?: JSX.Element;
			message?: string;
			submitLabel: string | boolean;
			isDelete?: boolean;
			size?: ValueOf<typeof SVFormLayout.SIZES>;
			submitSize?: ValueOf<typeof SVButtonSIZES>;
			submitUse?: ValueOf<typeof SVButtonUSES>;
			display?: 'flex';
			submitDisabled?: boolean;
			fullHeight?: boolean;
		}
>;

const SVForm = <T extends FormikValues, R = void>({
	onSubmit,
	onSuccess = (defaultProps as DefaultProps<T, R>).onSuccess,
	initialValues = (defaultProps as DefaultProps<T, R>).initialValues,
	initialErrors = (defaultProps as DefaultProps<T, R>).initialErrors,
	initialTouched = (defaultProps as DefaultProps<T, R>).initialTouched,
	noPadding,
	children,
	childrenAfter,
	message,
	submitLabel,
	submitSize = (defaultProps as DefaultProps<T, R>).submitSize,
	submitUse = (defaultProps as DefaultProps<T, R>).submitUse,
	submitAlignment = (defaultProps as DefaultProps<T, R>).submitAlignment,
	enableReinitialize = (defaultProps as DefaultProps<T, R>).enableReinitialize,
	display,
	submitDisabled,
	isDelete: _,
	size,
	fullHeight,
	...formikFormProps
}: Props<T, R>) => {
	const handleSubmit = useEventCallback(async (values: T, form: FormikHelpers<T>) => {
		form.setStatus({});
		form.setErrors({});
		form.setSubmitting(true);

		try {
			const result = await onSubmit(values);
			form.setSubmitting(false);
			await onSuccess(result, values, form);
			return result;
		} catch (e) {
			form.setSubmitting(false);

			const error = e instanceof Error ? e : new Error('Unknown error');

			if (isApolloError(error)) {
				// console.log(JSON.stringify(error, null, 2));
				form.setStatus(getStatusFromError(error));
				// @ts-ignore fix
				form.setErrors(getFieldErrors(error));
			} else {
				form.setStatus(getStatusFromError(error));
				throw error;
			}
		}
	});

	return (
		<Formik
			initialValues={initialValues}
			initialErrors={initialErrors}
			initialTouched={initialTouched}
			enableReinitialize={enableReinitialize}
			onSubmit={handleSubmit}
		>
			{(_props) => (
				<SVFormLayout className={clsx(noPadding && 'w-full p-0', fullHeight && 'h-full')} size={size}>
					<FormikForm
						{...formikFormProps}
						className={clsx(fullHeight && 'h-full flex flex-col', display === 'flex' && 'flex-center flex')}
					>
						{/* {otherProps.autoComplete === 'off' && (
						<>
							<input
								type="text"
								name="prevent_autofill"
								id="prevent_autofill"
								value=""
								className="hidden"
								readOnly
							/>
							<input
								type="password"
								name="password_fake"
								id="password_fake"
								value=""
								className="hidden"
								readOnly
							/>
						</>
					)} */}
						{message && <SVMessage>{message}</SVMessage>}
						<_StatusMessage />
						{children}
						{fullHeight && <SVFlexSpacer />}
						{submitLabel ? (
							<SVFormLayout.Block
								className={twMerge(
									'pt-1 pb-9 last:mb-0',
									submitAlignment === 'center' && 'text-center',
									submitAlignment === 'right' && 'text-right',
									submitDisabled ? 'text-gray-300' : 'text-primary',
									fullHeight && 'flex-auto',
								)}
							>
								<_SubmitButton
									use={submitUse}
									size={submitSize}
									submitDisabled={submitDisabled || false}
								>
									{submitLabel}
								</_SubmitButton>
							</SVFormLayout.Block>
						) : null}
						{childrenAfter}
					</FormikForm>
				</SVFormLayout>
			)}
		</Formik>
	);
};

SVForm.defaultProps = defaultProps;

export default SVForm;
